import { FC, memo, useCallback } from 'react'

import { useChatThreadListHeader } from './useChatThreadListHeader'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import SharpSearch from '~icons/ic/sharp-search'
import { useAppSelector } from 'states/hooks'
import { Chat } from '@crew/states'
import { CrewRelatedItemLink } from 'components/elements/crewRelatedItemLink/crewRelatedItemLink'

/**
 * ThreadList形式のヘッダ
 */
export const ChatThreadListHeader: FC = memo(() => {
  const { changeToSearchMode } = useChatThreadListHeader()

  // ViewModelに格納されているCurrentの表示Style(2ペイン表示orコンパクト表示)を取得する
  const currentStyle = useAppSelector(
    (state) => state.message.chat.current.style
  )

  // 表示対象のチャットルームを取得する
  const currentChatRoom = useAppSelector(
    (state) => state.message.chat.current.chatRoom
  )

  // 検索ボタンクリック時
  const handleSearchButtonClick = useCallback(() => {
    // 検索機能に切り替える
    changeToSearchMode()
  }, [changeToSearchMode])

  // currentStyleに応じてヘッダ部に描画するコンテンツを切り替える
  const renderHeaderContent = useCallback(() => {
    // 表示に必要なデータが指定されていない場合、表示することができないので、何も表示しない
    if (!currentChatRoom) {
      return null
    }

    switch (currentStyle) {
      case Chat.Style.Full:
        // Chat.Style.Full（プロジェクト詳細時）のときは、検索アイコンも表示しない
        // プロジェクト内検索に統合されるため
        return null
      case Chat.Style.Compact:
        return (
          <>
            {/* 関連先リンク 
                - 親の関連先がある場合は、親の関連先名も合わせて表示する */}
            <span className="grow line-clamp-1 break-all">
              {currentChatRoom.entityRecordId !==
                currentChatRoom.rootEntityRecordId && (
                <>
                  <CrewRelatedItemLink
                    entityType={currentChatRoom.rootEntityType}
                    id={currentChatRoom.rootEntityRecordId}
                  />
                  &nbsp;/&nbsp;
                </>
              )}
              <CrewRelatedItemLink
                entityType={currentChatRoom.entityType}
                id={currentChatRoom.entityRecordId}
              />
            </span>
            {/* 検索アイコンボタン */}
            <CrewButton
              className="flex-grow-0 flex-shrink-0"
              icon={<SharpSearch width={24} height={24} />}
              type="normal"
              stylingMode="text"
              size="sm"
              onClick={handleSearchButtonClick}
            />
          </>
        )
      default:
        return null
    }
  }, [currentChatRoom, currentStyle, handleSearchButtonClick])

  return (
    <div className="flex px-2 py-1 gap-2 items-center">
      {renderHeaderContent()}
    </div>
  )
})
