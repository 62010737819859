import { FC, memo } from 'react'
import { Modal } from 'components/layouts/modal/modal'
import { EventScheduleEntryForm } from './components/eventScheduleEntryForm/eventScheduleEntryForm'
import { EventType } from '@crew/enums/domain'

type EventScheduleEntryDialogProps = {
  isEditMode: boolean
  title: string
  isOpen: boolean
  onClose: () => void
  eventId?: string
  onSubmit: (
    eventId: string,
    eventType: EventType,
    isNavigate?: boolean,
    deleteFlg?: boolean
  ) => void
  startDatetime?: Date
  endDatetime?: Date
  attendeeId?: string
}

export const EventScheduleEntryDialog: FC<EventScheduleEntryDialogProps> = memo(
  (props) => {
    return (
      <Modal title={props.title} isOpen={props.isOpen} onClose={props.onClose}>
        <div className="flex flex-col gap-y-5 h-full">
          <EventScheduleEntryForm
            isEditMode={props.isEditMode}
            eventId={props.eventId}
            onCancel={props.onClose}
            onSubmit={props.onSubmit}
            startDatetime={props.startDatetime}
            endDatetime={props.endDatetime}
            attendeeId={props.attendeeId}
          />
        </div>
      </Modal>
    )
  }
)
