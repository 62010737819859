import { memo, FC, useCallback, useMemo, useState } from 'react'
import { DirectMessageTab } from './components/directMessageTab/directMessageTab'
import { Route, Routes, useParams } from 'react-router-dom'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { DirectMessageTabs, SearchTabs } from 'enums/app'
import { DirectMessageChatPanel } from './components/directMessageChatPanel/directMessageChatPanel'
import { DirectMessageHeadPanel } from './components/directMessageHeadPanel/directMessageHeadPanel'
import { DirectMessageFileList } from './components/directMessageFileList/directMessageFileList'
import { CrewTextBox } from 'components/devextreme/crewTextBox'
import { TextBoxInstance } from 'devextreme/ui/text_box'
import { NativeEventInfo } from 'devextreme/events'
import { OPERATION_KEY } from 'configs/constants'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { useTranslation } from '@crew/modules/i18n'
import { Button as TextBoxButton } from 'devextreme-react/text-box'
import { ButtonTypes } from 'devextreme-react/cjs/button'
import { KeywordFilterCondition } from '@crew/enums/domain'
import { getDefaultTabValue } from '@crew/utils/dist/enum'

export const DirectMessagePanel: FC = memo(() => {
  const { t } = useTranslation()
  const { navigate } = useCrewNavigate()
  const { projectId } = useParams()

  const [searchConditionMode, setSearchConditionMode] =
    useState<KeywordFilterCondition>('and')

  // Apply search keyword to cross search
  const handleCrossSearchChanged = useCallback(
    (e: NativeEventInfo<TextBoxInstance, KeyboardEvent>) => {
      const keyword = e.component.option('text')
      keyword &&
        navigate(`/search/${getDefaultTabValue(SearchTabs)}`, {
          keyword,
          projectId,
          [OPERATION_KEY]: searchConditionMode,
        })
    },
    [navigate, projectId, searchConditionMode]
  )

  const searchConditionButton = useMemo<ButtonTypes.Properties>(
    () => ({
      stylingMode: 'text',
      onClick: () => {
        setSearchConditionMode((prevConditionMode: KeywordFilterCondition) =>
          prevConditionMode === 'and' ? 'or' : 'and'
        )
      },
      text: searchConditionMode.toLocaleUpperCase(),
    }),
    [searchConditionMode]
  )

  return (
    <div className="flex flex-1 gap-2 grow min-h-0">
      <main className="grow flex flex-col h-full w-2/3 overflow-y-auto">
        <CrewErrorBoundary>
          <div className="p-2">
            <DirectMessageHeadPanel />
          </div>
        </CrewErrorBoundary>
        <CrewErrorBoundary>
          <div className="min-h-0 flex-1 flex flex-col grow w-full">
            <div className="px-2">
              <div className="flex flex-row border-b crew-border-gray items-center">
                {/* ダイレクトメッセージタブ */}
                <DirectMessageTab />

                {/* プロジェクト内横断検索ボックス */}
                <div className="pr-0.5 ml-auto">
                  <CrewTextBox
                    id="search"
                    name="search"
                    placeholder={t('action.search')}
                    mode="search"
                    onEnterKey={handleCrossSearchChanged}
                  >
                    <TextBoxButton
                      name="searchCondition"
                      location="after"
                      options={searchConditionButton}
                    />
                  </CrewTextBox>
                </div>
              </div>
            </div>
            <div className="grow min-h-0 w-full flex flex-col">
              {/* URLに応じて表示するコンポーネントを切り替える */}
              <Routes>
                {/* 
                  TODO: 一時的にデフォルトルート（index）を除去している
                  以下タスク対応時にデフォルトルートを設定する予定
                  https://break-tmc.atlassian.net/browse/CREW-9163
                */}
                <Route
                  path={DirectMessageTabs.Chat.value}
                  element={<DirectMessageChatPanel />}
                />

                <Route
                  path={DirectMessageTabs.File.value}
                  element={<DirectMessageFileList />}
                />
              </Routes>
            </div>
          </div>
        </CrewErrorBoundary>
      </main>
    </div>
  )
})
