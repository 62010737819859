import { FC, memo } from 'react'
import { ChatMessage } from '@crew/models/domain'
import {
  AvatarPosition,
  CrewChatMessageItemAvatar,
} from 'components/elements/crewChatMessageItem/components/crewChatMessageItemAvatar/crewChatMessageItemAvatar'
import { CrewChatMessageItemHeader } from 'components/elements/crewChatMessageItem/components/crewChatMessageItemHeader/crewChatMessageItemHeader'
import { CrewNormalChatMessage } from 'components/elements/crewChatMessageItem/components/crewNormalChatMessage/crewNormalChatMessage'
import { useTranslation } from '@crew/modules/dist/i18n'

export type TopicSummaryMessageItemProps = {
  message: ChatMessage // 投稿メッセージデータ
  highlightKeyword: string // 検索時にハイライトするキーワード
}

/**
 * Timeline形式で再掲されるトピック投稿メッセージ
 */
export const TopicSummaryMessageItem: FC<TopicSummaryMessageItemProps> = memo(
  (props) => {
    const { t } = useTranslation()

    return (
      <>
        {/* メッセージ本体 */}
        <div className="flex flex-row gap-2 p-2">
          {props.message.deleted ? (
            <>
              {/* 削除済みのトピックの場合：「削除済み」と表示する */}
              {/* アバター */}
              <CrewChatMessageItemAvatar
                isLargeAvatar={true} // 削除フラグがtrueのものは必ずトピックであるため、アバターのサイズは大きくする
                position={AvatarPosition.Center}
                omitUserAvatar={true} // 表示しない
                deletedMessage={props.message.deleted}
              />

              <div className="crew-text-gray-5">
                {t('label.deletedMessage')}
              </div>
            </>
          ) : (
            <>
              {/* アバター */}
              <CrewChatMessageItemAvatar
                isLargeAvatar={false}
                user={props.message.createdBy}
                position={AvatarPosition.Center} // 再掲されるトピックは中央に表示する
                omitUserAvatar={true} // 表示しない
              />
              <div className="flex flex-grow flex-col gap-1 min-w-0">
                {/* ユーザー名、投稿日、関連先リンク */}
                <CrewChatMessageItemHeader
                  message={props.message}
                  isShowChatRoomName={false} // 投稿にチャットルーム名は表示しない
                />
                <div>
                  <CrewNormalChatMessage
                    messageText={props.message.text}
                    messagePlainText={props.message.plainText}
                    isTopicSummary={true} // 再掲されるトピック投稿メッセージなのでtrueを設定
                    highlightKeyword={props.highlightKeyword}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </>
    )
  }
)
