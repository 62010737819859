import { FC, memo, useCallback } from 'react'

import BaselineError from '~icons/ic/baseline-error'
import BaselineNotificationsNone from '~icons/ic/baseline-notifications-none'

import { useTranslation } from '@crew/modules/i18n'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { useNotificationPermission } from 'hooks/pushNotification/useNotificationPermission'

export const AppRequestNotificationPermissionBar: FC = memo(() => {
  const { t } = useTranslation()
  const [permission, requestPermissionAsync] = useNotificationPermission()

  const handlePermissionRequestButtonClick = useCallback(() => {
    requestPermissionAsync()
  }, [requestPermissionAsync])

  // 使用ブラウザがEdgeかどうかを判定する
  const isEdge = navigator.userAgentData?.brands.some(
    (b: any) => b.brand === 'Microsoft Edge'
  )

  return permission === 'default' ? (
    <div className="flex flex-row items-center h-[3.25rem] p-2.5 gap-1.5 border-b crew-border-gray">
      <BaselineError className=" text-crew-red-500" width={24} height={24} />
      <p>{t('message.notification.requestPermission')}</p>
      <CrewButton
        type="primary"
        text={t('action.configureNotification')}
        onClick={handlePermissionRequestButtonClick}
      />
      {/* 
        Edgeのセキュリティ機能により、不明なサイトと判定されると通知プロンプトが抑制される。  
        判定基準は非公開で、アプリ側から制御や検出は不可。  
        対策として、Edgeを利用した場合のみ「通知が出ない場合はベルマークをクリック」と案内する
        - https://blogs.windows.com/msedgedev/2023/07/06/fighting-notification-spam-microsoft-edge/  
        - https://stackoverflow.com/questions/76090704/detect-blocked-notifications-on-edge  
       */}
      {isEdge && (
        <p className="text-crew-red-500">
          {t('message.notification.alertForEdge1')}
          <BaselineNotificationsNone
            width={20}
            height={20}
            className="inline"
          />
          {t('message.notification.alertForEdge2')}
        </p>
      )}
    </div>
  ) : null
})
