import { GetProjectResponse } from '@crew/apis/dist/project/models/getProject/response'
import { EntityType } from '@crew/enums/domain'
import { generateImageAvatarUrl } from '@crew/utils/avatar'
import {
  CrewAvatar,
  CrewAvatarShapeType,
  CrewAvatarSize,
} from 'components/elements/crewAvatar/crewAvatar'
import { ProjectScope } from 'enums/app'
import { FC, memo } from 'react'

export type SearchInProjectHeaderItemProps = {
  projectData: GetProjectResponse | undefined
}

export const SearchInProjectHeadItem: FC<SearchInProjectHeaderItemProps> = memo(
  (props) => {
    return (
      <>
        <CrewAvatar
          cacheValue={
            props.projectData?.project?.id ??
            '' + props.projectData?.project?.version ??
            0
          }
          displayName={props.projectData?.project?.subject ?? ''}
          shape={CrewAvatarShapeType.Square}
          isPrivate={
            props.projectData?.project?.scope === ProjectScope.Private.key
          }
          imageURL={generateImageAvatarUrl(
            EntityType.Project,
            props.projectData?.project?.id
          )}
          size={CrewAvatarSize.xs}
        />
        <p title={props.projectData?.project?.subject}>
          {props.projectData?.project?.subject}
        </p>
      </>
    )
  }
)
