import { FC, FormEventHandler, memo } from 'react'
import { useTwoFactorForm } from './useTwoFactorForm'
import { CrewRadioGroupField } from 'components/forms/crewRadioGroupField'
import { CrewFieldLabel } from 'components/elements/crewFieldLabel'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { useTranslation } from '@crew/modules/i18n'
import { useLoadUserSettings } from '@crew/states'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  TwoFactorAuthenticationTypeValue,
  TwoFactorContent,
} from './components/twoFactorContent/twoFactorContent'
import { useAppSelector } from 'states/hooks'
import { ContractPlan, TwoFactorAuthenticationType } from '@crew/enums/app'

// render content
// renderとして使うのでmemo不可
const SecurityAuthenticationTypeItem: FC<{
  key: string
  name: string
}> = (props) => (
  <div>
    <CrewFieldLabel
      id={`radioId-${props.key}`}
      text={props.name}
      className="crew-text-default"
    />
  </div>
)

export type TwoFactorFormProps = {
  securityAuthenticationType: string | null
  emailAddress: string | undefined
  securityTwoFactorAuthDestination: string | undefined
  securityTwoFactorAuthCountryCode: string | undefined
  onSubmit: () => void
}

export const TwoFactorForm = memo((props: TwoFactorFormProps) => {
  const { control, reset, deleteTwoFactorAuth, isLoadingDeleteTowFactorAuth } =
    useTwoFactorForm()

  const { t } = useTranslation()
  const [showApiErrors] = useShowApiErrors()
  const { success } = useToast()
  const currentPlan = useAppSelector((state) => state.app.currentPlan)

  const [
    selectedSecurityAuthenticationType,
    setSelectedSecurityAuthenticationType,
  ] = useState<TwoFactorAuthenticationTypeValue>(
    TwoFactorAuthenticationType.App.value
  )

  const [loadUserSettings] = useLoadUserSettings()

  // set default two factor authentication type by setting
  useEffect(() => {
    if (props.securityAuthenticationType) {
      reset({
        securityAuthenticationType: props.securityAuthenticationType,
      })
    }
  }, [props.securityAuthenticationType, reset])

  //get list security authentication type
  const securityAuthenticationTypeItems = useMemo(() => {
    let twoFactorAuthenticationType = Object.values(TwoFactorAuthenticationType)

    if (
      currentPlan === ContractPlan.Free ||
      currentPlan === ContractPlan.Standard
    ) {
      // standard / freeプランの場合は2要素認証のオプションを表示しない
      twoFactorAuthenticationType = twoFactorAuthenticationType.filter(
        (item) => item.value !== TwoFactorAuthenticationType.Sms.value
      )
    }

    return twoFactorAuthenticationType.map((item) => {
      return { id: item.value, name: t(item.text) }
    })
  }, [currentPlan, t])

  // Event handle when SecurityAuthenticationType is changed
  const handleSecurityAuthenticationTypeChanged = useCallback(
    (value: TwoFactorAuthenticationTypeValue) => {
      setSelectedSecurityAuthenticationType(value)
    },
    []
  )
  // Event handle when DisableTwoFactorButton is clicked
  const handleDisableTwoFactorButtonClick = useCallback(async () => {
    try {
      // Execute delete two factor authentication process
      await deleteTwoFactorAuth()

      // Reload user setting data
      loadUserSettings()

      success(t('message.personalSetting.twoFactorSettingDeleted'))
    } catch (err) {
      // show error api
      showApiErrors(err)
    }
  }, [deleteTwoFactorAuth, loadUserSettings, success, t, showApiErrors])

  // Form内Enterキー押下によるSubmit制御
  // TODO: 一時的な暫定対応。対処方法についてはCREW-3338で検討する。
  //       https://break-tmc.atlassian.net/browse/CREW-3338
  const handleFormSubmit = useCallback<FormEventHandler>((event) => {
    event.preventDefault()
  }, [])

  return (
    <form
      className="mt-2.5 flex flex-col gap-2.5"
      // Form内Enterキー押下によるSubmit制御
      // TODO: 一時的な暫定対応。対処方法についてはCREW-3338で検討する。
      // https://break-tmc.atlassian.net/browse/CREW-3338
      onSubmit={handleFormSubmit}
    >
      <CrewRadioGroupField
        // if two factor enable radio group will show two factor type setting so need disable select
        disabled={Boolean(props.securityAuthenticationType)} //二要素認証設定済みの場合は非活性
        id="securityAuthenticationType"
        name="securityAuthenticationType"
        control={control}
        layout="horizontal"
        items={securityAuthenticationTypeItems}
        valueExpr="id"
        displayExpr="name"
        // if two factor enable need hide label in form to prevent space over radio group
        showLabel={!Boolean(props.securityAuthenticationType)}
        itemRender={SecurityAuthenticationTypeItem}
        label={t('message.personalSetting.howToGetAuthorizationCode')}
        onValueChanged={(e) => handleSecurityAuthenticationTypeChanged(e.value)}
      />
      {/* 二要素認証が設定されている場合、送信先を表示 */}
      {props.securityAuthenticationType &&
        props.securityTwoFactorAuthDestination && (
          <span className="crew-text-default">
            {/* show phone number or email */}
            {props.securityTwoFactorAuthDestination}
          </span>
        )}
      {/* Disable two factor */}
      {/* 二要素認証が有効な場合：無効ボタンを表示 */}
      {props.securityAuthenticationType && (
        <div>
          <CrewButton
            text={t('action.disableTwoFactor')}
            type="primary"
            onClick={handleDisableTwoFactorButtonClick}
            disabled={isLoadingDeleteTowFactorAuth}
          />
        </div>
      )}
      {/* 二要素認証が無効な場合、2FA設定フォームを表示する */}
      {!props.securityAuthenticationType && (
        <TwoFactorContent
          selectedSecurityAuthenticationType={
            selectedSecurityAuthenticationType
          }
          emailAddress={props.emailAddress}
          securityTwoFactorAuthDestination={
            props.securityTwoFactorAuthDestination
          }
          securityTwoFactorAuthCountryCode={
            props.securityTwoFactorAuthCountryCode
          }
          onSubmit={props.onSubmit}
        />
      )}
    </form>
  )
})
