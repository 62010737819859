import { CrewAccordion } from 'components/elements/crewAccordion/crewAccordion'
import { FC, memo, useCallback, useMemo } from 'react'
import { DirectChannelEntryDialog } from 'features/chat/components/directChannelEntryDialog/directChannelEntryDialog'
import { DirectMessageUserList } from './components/directMessageUserList/directMessageUserList'
import { useAppSelector } from 'states/hooks'
import { useTranslation } from '@crew/modules/i18n'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useModal } from 'components/layouts/modal/useModal'
import { useDirectMessageUserPanel } from './useDirectMessageUserPanel'
import { ActiveChatRoomInfo } from '@crew/states'
import { EntityType, ProjectType } from '@crew/enums/domain'
import Add from '~icons/material-symbols/add'

export const DirectMessageUserPanel: FC = memo(() => {
  const {
    insertDirectChannel,
    insertFavorite,
    isLoadingInsertProjectForDirectChannelMutation,
  } = useDirectMessageUserPanel()

  const { t } = useTranslation()

  const [isDialogOpen, openDialog, closeDialog] = useModal()

  const [showApiErrors] = useShowApiErrors()

  // ダイレクトチャンネルユーザ登録ダイアログで選択したユーザを登録
  const handelUserSelected = useCallback(
    async (targetUserId: string) => {
      try {
        // Create direct channel as a project
        const result = await insertDirectChannel(targetUserId)
        if (!result.directChannel?.id) return

        // Create favorite for login user
        await insertFavorite(EntityType.Project, result.directChannel?.id)
      } catch (err) {
        showApiErrors(err)
        return
      }

      closeDialog()
    },
    [closeDialog, insertDirectChannel, insertFavorite, showApiErrors]
  )

  // ダイレクトチャンネル追加ダイアログを開くアイコン
  const renderToolIcon = useCallback(() => {
    return <Add width={20} height={20} onClick={openDialog} />
  }, [openDialog])

  // redux上のアクティブチャットルーム情報
  // 登録済みのダイレクトチャンネル抽出に使用する
  const activeChatRoomsDictionary = useAppSelector(
    (state) => state.message.activeChatRoom.chatRooms.entities
  )

  // dictionaryから配列に変換してダイレクトチャンネルのみを取得し、登録済みユーザーのユーザーIDリストを取り出す
  const alreadyRegisteredDirectChannelChatRooms = useMemo(() => {
    const registeredDirectChannelChatRooms = Object.values(
      activeChatRoomsDictionary
    ).filter((value): value is ActiveChatRoomInfo => {
      return (
        value !== undefined && value.projectType === ProjectType.DirectChannel
      )
    })
    if (registeredDirectChannelChatRooms.length === 0) {
      return []
    }
    return registeredDirectChannelChatRooms.map(
      (chatRoom) => chatRoom.projectId
    )
  }, [activeChatRoomsDictionary])

  return (
    <>
      <CrewAccordion
        title={t('label.directMessage')}
        content={<DirectMessageUserList />}
        renderToolIcon={renderToolIcon}
      />
      <DirectChannelEntryDialog
        title={t('label.addUser') + ' - ' + t('label.directMessage')}
        isOpen={isDialogOpen}
        isLoading={isLoadingInsertProjectForDirectChannelMutation}
        onClose={closeDialog}
        onUserSelected={handelUserSelected}
        alreadyRegisteredUserIds={alreadyRegisteredDirectChannelChatRooms}
      />
    </>
  )
})
