var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { apiBaseUrl, WEBSOCKET_RECONNECT_MAX_TRY_COUNT, WEBSOCKET_1ST_RECONNECT_DELAY_MSEC, WEBSOCKET_RECONNECT_JITTER_MSEC, WEBSOCKET_RECONNECT_COUNTER_RESET_TIME_MSEC, } from '@crew/configs/constants';
import { IsIncomingMessage } from './models/common/incoming';
import { refreshAccessToken } from '../apiBase/apiBase';
// WebsocketのカスタムCloseCode
const WEBSOCKET_CLOSE_INVALID_ACCESS_TOKEN = 4000; // Backendと合わせる
/**
 * websocketの接続およびメッセージ受信を行う関数
 */
export const connectWebsocket = ({ onConnected, onReconnecting, onRecvMessage, onDisconnected, }) => {
    const wsUrl = new URL('api/v1/ws-connect', apiBaseUrl());
    // APIのベースURLのプロトコルに従ってwebsocket側も切り替える
    wsUrl.protocol = wsUrl.protocol === 'http:' ? 'ws:' : 'wss:';
    // 自動再接続で新たなインスタンスを作るので代入可にしておく
    let websocket;
    // 自動再接続するかどうか。明示的にcloseした場合にfalseにする
    let doReconnect = true;
    // 自動再接続でリトライした回数
    let reconnectTryCount = 0;
    /**
     * websocket接続開始する関数。自動再接続で再入するため関数として分離している
     * @returns
     */
    const connect = () => {
        console.info(`[WebsocketApi] Websocket opening... target: ${wsUrl}`);
        // react-native環境のWebSocketはURLオブジェクトを受け取ることができないため、
        // 明示的に文字列に変換する
        const ws = new WebSocket(wsUrl.toString());
        // 自動再接続時に使うタイマー
        let reconnectTimer;
        // 自動再接続リトライ回数をリセットするためのタイマー
        let reconnectResetTimer;
        // メッセージ受信イベントハンドラ
        ws.onmessage = (e) => {
            const res = JSON.parse(e.data);
            if (IsIncomingMessage(res)) {
                console.info(`[WebsocketApi] Received message from ws. ${e.data}`);
                onRecvMessage(res);
            }
            else {
                console.warn(`[WebsocketApi] Invalid message from ws. ${e.data}`);
            }
        };
        // websocket接続完了ハンドラ
        ws.onopen = (e) => {
            console.info(`[WebsocketApi] Websocket opened to ${wsUrl}`);
            reconnectResetTimer = window.setTimeout(() => {
                console.info(`[WebsocketApi] Reset reconnect counter.`);
                reconnectResetTimer = null;
                reconnectTryCount = 0;
            }, WEBSOCKET_RECONNECT_COUNTER_RESET_TIME_MSEC);
            onConnected();
        };
        // websocket接続終了ハンドラ
        ws.onclose = (e) => {
            console.info(`[WebsocketApi] Websocket closed to ${wsUrl}.`, e);
            reconnectTimer && clearTimeout(reconnectTimer);
            reconnectTimer = null;
            reconnectResetTimer && clearTimeout(reconnectResetTimer);
            reconnectResetTimer = null;
            // 再接続する
            if (doReconnect) {
                reconnectTryCount++;
                if (reconnectTryCount > WEBSOCKET_RECONNECT_MAX_TRY_COUNT) {
                    console.error(`[WebsocketApi] Websocket reconnect failed.`);
                    doReconnect = false;
                    onDisconnected === null || onDisconnected === void 0 ? void 0 : onDisconnected(true);
                    return;
                }
                // 再接続までの遅延時間を2の指数で増加させ、そこにjitterを加える(exponential back-off and jitter)
                const exponentialBackOffedFactor = Math.pow(2, (reconnectTryCount - 1));
                const jitterFactor = (Math.random() - 0.5) * 2; // -1~1の乱数
                const reconnectWaitTime = WEBSOCKET_1ST_RECONNECT_DELAY_MSEC * exponentialBackOffedFactor +
                    WEBSOCKET_RECONNECT_JITTER_MSEC * jitterFactor;
                console.info(`[WebsocketApi] Websocket try auto reconnecting. Wait for interval ${reconnectWaitTime} msec.`);
                onReconnecting(reconnectTryCount, reconnectWaitTime);
                reconnectTimer = window.setTimeout(() => __awaiter(void 0, void 0, void 0, function* () {
                    reconnectTimer = null;
                    // 前回の接続終了コードがアクセストークンエラーだった場合、まずトークンのリフレッシュを行う
                    if (e.code === WEBSOCKET_CLOSE_INVALID_ACCESS_TOKEN) {
                        console.info('[WebsocketApi] Refreshing access token...');
                        const refreshOk = yield refreshAccessToken();
                        if (!refreshOk) {
                            console.error('[WebsocketApi] Refreshing access token failed.');
                            doReconnect = false;
                            onDisconnected === null || onDisconnected === void 0 ? void 0 : onDisconnected(true);
                            return;
                        }
                    }
                    console.info('[WebsocketApi] Websocket reconnecting...');
                    websocket = connect();
                }), reconnectWaitTime);
            }
            else {
                // 再接続しない、インスタンスを削除
                websocket = null;
                onDisconnected === null || onDisconnected === void 0 ? void 0 : onDisconnected(false);
            }
        };
        ws.onerror = (err) => {
            console.info('[WebsocketApi] Websocket error.', err);
        };
        return ws;
    };
    websocket = connect();
    return {
        close: () => {
            doReconnect = false;
            websocket === null || websocket === void 0 ? void 0 : websocket.close();
        },
        send: (...params) => {
            // websocketはインスタンスが差し替わるのでアロー関数でwrapする
            websocket === null || websocket === void 0 ? void 0 : websocket.send(...params);
        },
        isConnected: () => (websocket === null || websocket === void 0 ? void 0 : websocket.readyState) === WebSocket.OPEN,
    };
};
