import {
  EntityType,
  MessageType,
  isNotificationMessage,
} from '@crew/enums/domain'
import classNames from 'classnames'
import { CrewChatMessageActionMenu } from 'components/elements/crewChatMessageItem/components/crewChatMessageActionMenu/crewChatMessageActionMenu'
import { AvatarPosition } from 'components/elements/crewChatMessageItem/components/crewChatMessageItemAvatar/crewChatMessageItemAvatar'
import { CrewNormalMessageItem } from 'components/elements/crewMessageItem/components/crewNormalMessageItem/crewNormalMessageItem'
import { CrewNotificationMessageItem } from 'components/elements/crewMessageItem/components/crewNotificationMessageItem/crewNotificationMessageItem'
import { ShowReplyButtonType, ShowReactionType } from '@crew/utils/chat'
import { FC, memo, RefObject, useCallback, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useProjectPermissions, useValueChangeEffect } from '@crew/hooks'
import { ADDITIONAL_LOADING_TRIGGER_INTERSECTION_ROOT_MARGIN } from 'configs/constants'
import {
  useChatCurrentService,
  useChatMessage,
  useChatThreadListService,
  useChatThreadService,
  useChatTimelineService,
  useUserSetting,
} from '@crew/states'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { SettingKeyType } from '@crew/enums/app'
import { ContextMenuItems, UserChatSettingDisplayFormat } from 'enums/app'
import { useHasHover } from 'hooks/useHasHover'

export type BookmarkMessageListItemProps = {
  id: string
  chatMessageId: string
  container: RefObject<HTMLDivElement>
  onMessageInView: (inView: boolean, messageId: string) => void
  onAdditionalLoading: (() => void) | undefined // 追加読み込みの関数
  selectedItemId: string | undefined // 選択中のメッセージ。ハイライト用に使用する
  setSelectedItemId: (messageId: string) => void // 選択されたメッセージのsetter
}

export const BookmarkMessageListItem: FC<BookmarkMessageListItemProps> = memo(
  (props) => {
    const dispatch = useAppDispatch()
    const { message, isError } = useChatMessage(
      props.chatMessageId,
      dispatch,
      useAppSelector
    )

    // チャットの表示方法に関わるユーザ設定を取得する
    const chatDisplayFormat = useUserSetting(SettingKeyType.ChatDisplayFormat)

    // Sliceの操作を行うためのServiceを取得
    const chatCurrentService = useChatCurrentService(dispatch)
    const chatTimelineService = useChatTimelineService(dispatch)
    const chatThreadListService = useChatThreadListService(dispatch)
    const chatThreadService = useChatThreadService(dispatch)

    // 上端、下端、メッセージ本体の要素を表示したかどうかのフラグ
    const [isShownTopDiv, setIsShowTopDiv] = useState(false)
    const [isShownBottomDiv, setIsShownBottomDiv] = useState(false)
    const [isShownMessageDiv, setIsShownMessageDiv] = useState(false)

    const hasHover = useHasHover()
    const [isHover, setIsHover] = useState<boolean>(false)

    // ------------------------------ イベントハンドラ ------------------------------
    // propsの関数は代入して使用（useEffect内で使用時、依存配列にpropsが入ってしまうため）
    const handleMessageInView = props.onMessageInView

    // マウスホバー時にアクションメニューを表示する
    const handleMouseEnter = useCallback(() => {
      setIsHover(true)
    }, [setIsHover])
    // マウスが離れたらアクションメニューを非表示にする
    const handleMouseLeave = useCallback(() => {
      setIsHover(false)
    }, [setIsHover])
    // 返信のクリックイベントハンドラ
    const handleClick = useCallback(() => {
      const topicId = message?.parentChatMessageId ?? message?.id
      // 個人設定の表示形式によって、右パネルで使用されている情報を更新する
      // 表示方法（タイムライン表示 / スレッド表示）の切り替えはChatCompactPanel内で制御しているため、ここではstateの更新のみ
      if (
        chatDisplayFormat === UserChatSettingDisplayFormat.Timeline.value ||
        (chatDisplayFormat === UserChatSettingDisplayFormat.Thread.value &&
          !message?.parentChatMessageId)
      ) {
        // 表示形式がタイムライン表示 もしくは スレッド表示の場合でもクリックされた親メッセージのIDが存在しない場合、右パネルで使用されているチャットルーム情報を更新する
        // sliceStateのtopicIdをnullにすることで、タイムライン表示 / スレッドリスト表示になる
        chatCurrentService.setCurrentChatRoomAndResetChatThread({
          id: message?.chatRoomId ?? '',
          rootEntityType:
            message?.chatRoom.rootEntityType ?? EntityType.Project,
          rootEntityRecordId: message?.chatRoom.rootEntityRecordId ?? '',
          entityType: message?.chatRoom.entityType ?? EntityType.Project,
          entityRecordId: message?.chatRoom.entityRecordId ?? '',
        })

        // 選択したメッセージIDをsliceにセットする
        if (chatDisplayFormat === UserChatSettingDisplayFormat.Timeline.value) {
          // タイムライン
          chatTimelineService.setTimelineSelectedMessageId({
            chatMessageId: message?.id ?? '',
            chatRoomId: message?.chatRoomId ?? '',
          })
        } else {
          // スレッドリスト
          chatThreadListService.setThreadListSelectedMessageId({
            chatMessageId: topicId ?? '',
            chatRoomId: message?.chatRoomId ?? '',
          })
        }
      } else if (
        chatDisplayFormat === UserChatSettingDisplayFormat.Thread.value &&
        message?.parentChatMessageId
      ) {
        // 右パネルの戻るボタン押下時に「スレッド表示」->「チャットルームのスレッドリスト表示」に遷移させるため、currentChatRoomをセットする
        chatCurrentService.setCurrentChatRoomAndResetChatThread({
          id: message?.chatRoomId ?? '',
          rootEntityType:
            message?.chatRoom.rootEntityType ?? EntityType.Project,
          rootEntityRecordId: message?.chatRoom.rootEntityRecordId ?? '',
          entityType: message?.chatRoom.entityType ?? EntityType.Project,
          entityRecordId: message?.chatRoom.entityRecordId ?? '',
        })
        // 表示形式がスレッド表示かつ、クリックされたメッセージに親が存在する場合、チャットスレッドの情報を更新する
        // topicIdを指定した場合、Inthread表示になる
        chatCurrentService.setCurrentChatThread({
          chatRoomId: message?.chatRoomId ?? '',
          rootEntityType:
            message?.chatRoom.rootEntityType ?? EntityType.Project,
          rootEntityRecordId: message?.chatRoom.rootEntityRecordId ?? '',
          entityType: message?.chatRoom.entityType ?? EntityType.Project,
          entityRecordId: message?.chatRoom.entityRecordId ?? '',
          topicId: message?.parentChatMessageId,
        })

        // 選択したメッセージIDをsliceにセットする
        chatThreadService.setChatThreadSelectedMessageId({
          chatMessageId: message?.id ?? '',
          topicId: topicId ?? '',
        })
      }

      // ハイライト表示するため、選択中メッセージを更新する
      props.setSelectedItemId && message && props.setSelectedItemId(message?.id)
    }, [
      chatCurrentService,
      chatDisplayFormat,
      chatThreadListService,
      chatThreadService,
      chatTimelineService,
      message,
      props,
    ])

    // メッセージの要素が一部でも表示されたらメッセージの表示フラグを更新する
    const { ref: messageRef } = useInView({
      threshold: 0, // 一部でも表示したときに発火する
      root: props.container.current,
      onChange: (inView) => {
        setIsShownMessageDiv(inView)
        // メッセージの要素が非表示になった場合は、上端・下端の要素も非表示になったとみなす
        if (!inView) {
          setIsShowTopDiv(false)
          setIsShownBottomDiv(false)
        }
      },
    })

    // 上端の要素が表示されたら表示フラグを更新する
    const { ref: topRef } = useInView({
      root: props.container?.current,
      onChange: (inView) => {
        // 上端の要素が非表示になった場合でも、メッセージの要素が表示されている場合は表示したことがあるとみなす
        if (!inView && isShownMessageDiv) {
          return
        }

        setIsShowTopDiv(inView)
      },
    })

    // 下端の要素が表示されたら表示フラグを更新する
    const { ref: bottomRef } = useInView({
      root: props.container?.current,
      onChange: (inView) => {
        // 下端の要素が非表示になった場合でも、メッセージの要素が表示されている場合は表示したことがあるとみなす
        if (!inView && isShownMessageDiv) {
          return
        }
        setIsShownBottomDiv(inView)
      },
    })

    // 表示状態が更新されたらイベントハンドラを呼び、既読管理やスクロール位置の保持を行う
    useValueChangeEffect(
      () => {
        if (isShownTopDiv && isShownBottomDiv) {
          /* メッセージ表示（上端、下端どちらも表示したことがある） */
          handleMessageInView(true, props.chatMessageId)
        } else if (!isShownTopDiv && !isShownBottomDiv) {
          /* メッセージ非表示（上端、下端どちらも表示したことがない） */
          handleMessageInView(false, props.chatMessageId)
        }
      },
      [
        handleMessageInView,
        isShownBottomDiv,
        isShownTopDiv,
        props.chatMessageId,
      ],
      { isShownTopDiv, isShownBottomDiv },
      false,
      (prev, next) =>
        prev.isShownTopDiv === next.isShownTopDiv &&
        prev.isShownBottomDiv === next.isShownBottomDiv
    )

    // アイテムが表示領域に近づいたら追加ロードイベントハンドラを呼ぶ
    const { ref: loadingTriggerRef } = useInView({
      rootMargin: ADDITIONAL_LOADING_TRIGGER_INTERSECTION_ROOT_MARGIN, // 表示領域に「近づいた」をトリガーにするため、領域の外側にマージンを付与する
      root: props.container.current,
      onChange: (inView) => {
        // このイベントは、アイテムと判定領域の重なり方の割合がthreshold(デフォルト: 0)を越えた場合に発火する。
        // 近づいた場合と離れた場合のどちらも発火するが、inViewの値で方向を判定することができる。
        //   近づいた場合: true 離れた場合: false
        if (inView) {
          // アイテムが近づいてきた場合、追加読込を行う
          props.onAdditionalLoading?.()
        }
      },
    })

    const { hasPrjFileDownloadPermission } = useProjectPermissions(
      message?.fileHistories?.[0]?.file.entityType
        ? (message?.fileHistories?.[0].file.entityType as EntityType)
        : undefined,
      message?.fileHistories?.[0]?.file.entityRecordId
    )

    // メッセージの表示コンポーネントを出し分ける
    const renderChatMessageItem = useCallback(() => {
      // メッセージがない場合は何も表示しない
      if (!message) return null

      // messageTypeに応じて表示コンポーネントを出し分ける
      if (message.messageType === MessageType.MessageNormal) {
        // 通常投稿メッセージ
        return (
          <CrewNormalMessageItem
            message={message}
            canDownloadAttachment={hasPrjFileDownloadPermission}
            onReplyClick={undefined} // 返信ボタンは表示しない
            showRelatedLink={true} // 投稿に関連先リンクを表示する
            highlightKeyword=""
            isLargeAvatar={true} // 常に大きいアバターを表示する
            avatarPosition={AvatarPosition.Right} // アバターは右寄せで固定
            showReplyButtonType={ShowReplyButtonType.None} // 「返信」ボタンは表示しない
            showReactionsAndReactionButton={ShowReactionType.ShowAndButton} // リアクションボタンとリアクションを表示
            replyCount={undefined} // ブックマークでは返信件数は表示しない
            showDeleteAttachmentButton={false} // ブックマークでは添付ファイルの削除はできない（＝メッセージ編集は不可）
            onAttachmentFileDeleted={undefined} // 上記によりイベントは不要
            onClick={handleClick}
          />
        )
      } else if (isNotificationMessage(message.messageType)) {
        // 自動投稿メッセージ
        return (
          <CrewNotificationMessageItem
            message={message}
            canDownloadAttachment={hasPrjFileDownloadPermission}
            onReplyClick={undefined} // 返信ボタンは表示しない
            showRelatedLink={true} // 投稿に関連先リンクを表示する
            omitUserAvatar={false} // 常にアバターを表示する
            showReplyButtonType={ShowReplyButtonType.None} // 「返信」ボタンは表示しない
            showReactionsAndReactionButton={ShowReactionType.ShowAndButton} // リアクションボタンとリアクションを表示
            truncateMessage={false} // メッセージテキストを省略表示しない
            replyCount={undefined} // ブックマークでは返信件数は表示しない
            onClick={handleClick}
          />
        )
      } else {
        return null
      }
    }, [handleClick, hasPrjFileDownloadPermission, message])

    // -----------------------------------------------------------------------------

    // 選択中メッセージが自メッセージかどうかを判定
    const isSelected =
      props.selectedItemId !== undefined && message?.id === props.selectedItemId

    // メニューを表示するかどうか
    const visibleActionMenu = hasHover
      ? isHover //ホバー有効ならホバー状態であること
      : isSelected //ホバー無効なら選択状態であること

    // アクションメニューの表示設定
    const enabledActionMenus = useMemo(() => {
      const actionMenus: ContextMenuItems[] = []

      // 「リンクをコピー」のみ表示する
      actionMenus.push(ContextMenuItems.CopyLink)

      return actionMenus
    }, [])

    // エラーが発生している場合はエラーが発生した旨を表示する
    if (isError) {
      return null
    }

    // 表示に必要なデータがない場合は何も表示しない
    // 当初「読み込み中」を表示しようとしていたが、メッセージごとにその表示が出てしまうと見栄えが悪かったので表示しないようにした
    if (!message) {
      return null
    }

    return (
      <div
        id={props.id}
        className={classNames('flex flex-col crew-border-gray relative', {
          'bg-crew-blue-1-light dark:bg-crew-blue-3-dark': isSelected, // 対象メッセージがスレッド表示の対象として選択されている場合ハイライトする
          'bg-crew-gray-100 dark:bg-crew-gray-800': !isSelected && isHover, // ホバー表示、ただし選択されていたらそちらを優先する
        })}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {/* メッセージ上端の要素。既読処理に使用するのみで画面には何も表示しない */}
        <div ref={topRef} className="-z-10"></div>

        {props.onAdditionalLoading && (
          <div
            ref={loadingTriggerRef}
            className="absolute left-0 top-0 right-0 bottom-0 -z-10"
          />
        )}

        {/* メッセージ */}
        <div ref={messageRef}>{renderChatMessageItem()}</div>

        {/* ホバーメニュー */}
        {/* メッセージ枠右上にホバーメニューを表示するためabsoluteを使用 */}
        <div className="absolute top-0 right-0 crew-bg-default">
          <CrewChatMessageActionMenu
            visible={visibleActionMenu}
            isBookmarkedMessage={
              message.bookmarks ? message.bookmarks.length > 0 : false
            }
            bookmarkMessages={message.bookmarks}
            chatMessageId={props.chatMessageId}
            chatMessageVersion={message.version}
            enabledActionMenus={enabledActionMenus}
            onEditButtonClick={undefined} // 上記によりイベントは不要
            showArchiveCheckbox={true} // アーカイブチェックボックスを表示する
          />
        </div>

        {/* メッセージ下端の要素。既読処理に使用するのみで画面には何も表示しない */}
        <div ref={bottomRef} className="-z-10"></div>
      </div>
    )
  }
)
