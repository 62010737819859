import React, {
  ComponentProps,
  MouseEventHandler,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form'

import { genericMemo } from 'utils'

import { CrewHtmlEditor } from 'components/elements/crewHtmlEditor'
import { UploadFile } from 'models/domain/uploadFile'

import { CrewFieldLabel } from 'components/elements/crewFieldLabel'
import { CrewErrorMessages } from 'components/forms/crewErrorMessages'
import { EntityType } from '@crew/enums/domain'

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = Omit<
  ComponentProps<typeof CrewHtmlEditor>,
  | 'ref' //これらは別途提供するのでpropsから除外する
  | 'name'
  | 'value'
  | 'defaultValue'
  | 'onValueChange'
  | 'onFocusOut'
  | 'isValid'
> & {
  id: string
  required?: boolean
  label?: string
  showLabel?: boolean
  /**
   * メンションリスト作成に使用
   */
  entityType?: EntityType
  /**
   * メンションリスト作成に使用
   */
  entityRecordId?: string
  /**
   * ファイルアップローダー使用可否（true:使用不可）
   */
  fileUploaderDisabled?: boolean
  /**
   * 絵文字Picker使用可否（true:使用不可）
   */
  emojiPickerDisabled?: boolean
  /**
   * 呼び出し元から渡されるファイル情報一覧
   */
  uploadedFileList?: UploadFile[]
  /**
   * 右下に差し込みで表示するコンポーネント。送信ボタン系を想定
   */
  appendButtons?: React.ReactNode
  /**
   * 呼び出し元からの渡されるアップロードイベントハンドラ
   */
  onUploaded?: (file: UploadFile) => void
  /**
   * 呼び出し元からの渡される削除イベントハンドラ
   */
  onDeleteUploadedFile?: (file: UploadFile) => void
  disabledMention?: boolean

  reset?: () => void
} & UseControllerProps<TFieldValues, TName>

export const CrewHtmlEditorField = genericMemo(
  <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
    // useController用
    name,
    rules,
    control,
    reset,
    shouldUnregister,
    defaultValue,
    // ラベル用
    id,
    required = false,
    label,
    showLabel = true,
    // ファイルアップローダ用
    uploadedFileList,
    fileUploaderDisabled,
    emojiPickerDisabled,
    onUploaded,
    onDeleteUploadedFile,
    // メンション用
    entityType,
    entityRecordId,
    disabledMention,
    // その他
    ...rest
  }: Props<TFieldValues, TName>) => {
    const { field, fieldState, formState } = useController({
      name,
      control,
      rules,
      shouldUnregister,
      defaultValue,
    })

    const crewHtmlEditorRef = useRef<CrewHtmlEditor>(null)

    // field.refにリダイレクトしつつrefを取得する
    // https://qiita.com/costeka/items/a2722350c5d1b995f3c3
    useImperativeHandle<CrewHtmlEditor | null, CrewHtmlEditor | null>(
      field.ref,
      () => crewHtmlEditorRef.current
    )

    const handleLabelClick: MouseEventHandler<HTMLLabelElement> = useCallback(
      (e) => {
        crewHtmlEditorRef.current?.focus()
      },
      []
    )

    const [preValue, setPreValue] = useState(field.value)
    // valueの更新をトリガーにしてclear()する
    if (preValue !== field.value) {
      setPreValue(field.value)
      // 新しいvalueが空を示す値の場合、clear()する
      if ([null, undefined, ''].some((t) => t === field.value)) {
        crewHtmlEditorRef.current?.clear()

        // clear error after crewHtmlEditorRef clear value
        reset?.()
      }
    }

    return (
      <div className="flex flex-col gap-1 h-full">
        {showLabel && (
          <CrewFieldLabel
            text={label}
            required={required}
            htmlFor={id}
            onClick={handleLabelClick}
          />
        )}

        <CrewHtmlEditor
          {...rest}
          data-testid={field.name}
          ref={crewHtmlEditorRef}
          // lexical内部でstateを持つためvalueはない。そのため初期値はdefaultValueで与える必要がある
          defaultValue={formState.defaultValues?.[name]}
          // lexical内部でstateを持つためvalueはなく、一般的なReact componentのようにvalueを明示的に更新しない。
          // そのためfield.valueはreset()のトリガーのみに使い、他の値は無視する。
          // field.onChangeはreact-hook-formに値の変更を伝えるために利用する。
          onValueChange={field.onChange}
          isValid={!fieldState.error}
          fileUploaderDisabled={fileUploaderDisabled}
          emojiPickerDisabled={emojiPickerDisabled}
          entityType={entityType}
          entityRecordId={entityRecordId}
          disabledMention={disabledMention}
          uploadedFileList={uploadedFileList}
          onDeleteUploadedFile={onDeleteUploadedFile}
          onUploaded={onUploaded}
        />

        <CrewErrorMessages
          isValid={!fieldState.error}
          errors={formState.errors}
          field={field}
        />
      </div>
    )
  }
)
