import { useInsertProjectForDirectChannelMutation } from '@crew/apis/project/projectApis'
import { useInsertFavoriteMutation } from '@crew/apis/favorite/favoriteApis'
import { useCallback } from 'react'
import { EntityType } from '@crew/enums/domain'
import { useAppDispatch } from 'states/hooks'
import {
  notifyFavoriteEvent,
  ObjectEventMessage,
} from 'features/app/states/appSlice'
import { Favorite } from '@crew/models/domain'
import { NotifyEventType } from 'enums/app'

export const useDirectMessageUserPanel = () => {
  const dispatch = useAppDispatch()

  // Get function for insert direct chanel
  const [
    insertProjectForDirectChannelMutation,
    { isLoading: isLoadingInsertProjectForDirectChannelMutation },
  ] = useInsertProjectForDirectChannelMutation()
  const [insertFavoriteMutation] = useInsertFavoriteMutation()

  // Insert direct chanel process
  const insertDirectChannel = useCallback(
    async (targetUserId: string) => {
      const result = await insertProjectForDirectChannelMutation({
        targetUserId,
      }).unwrap()

      return result
    },
    [insertProjectForDirectChannelMutation]
  )

  // Insert favorite process
  const insertFavorite = useCallback(
    async (entityType: EntityType, entityRecordId: string) => {
      const response = await insertFavoriteMutation({
        entityType,
        entityRecordId,
      }).unwrap()

      if (!response.favorite) return

      const objectEventMessage: ObjectEventMessage<Favorite> = {
        eventType: NotifyEventType.Inserted,
        id: response.favorite.id,
        object: undefined,
      }

      dispatch(notifyFavoriteEvent(objectEventMessage))
    },
    [dispatch, insertFavoriteMutation]
  )

  return {
    insertDirectChannel,
    isLoadingInsertProjectForDirectChannelMutation,
    insertFavorite,
  }
}
