import { memo } from 'react'
import { CommentButtonGroup, SortCommentButtonGroup } from 'enums/app'
import {
  commentButtonGroupIndexUpdated,
  sortCommentButtonGroupIndexUpdated,
} from 'features/task/components/taskDetailPage/states/taskDetailSlice'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { SettingKeyType, Sort } from '@crew/enums/app'
import { useUserSetting } from '@crew/states'
import { CrewButtonGroup } from 'components/elements/crewButtonGroup/crewButtonGroup'

type ButtonGroupInfo = {
  index: string
  text: string | undefined
  icon: JSX.Element | undefined
}

export const TaskDetailCommentListToolbar = memo(() => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const defaultAppearanceTheme = useUserSetting(
    SettingKeyType.TaskHistoryDisplayOrder,
    Sort.Ascending.value
  )

  useEffect(() => {
    //set task history display order to redux by user settings
    dispatch(sortCommentButtonGroupIndexUpdated(String(defaultAppearanceTheme)))
  }, [defaultAppearanceTheme, dispatch])

  const selectedCommentDisplayType = useAppSelector(
    (state) => state.taskDetail.selectedCommentButtonGroupItem
  )

  const selectedSortCommentDisplayType = useAppSelector(
    (state) => state.taskDetail.selectedSortCommentButtonGroupItem
  )

  //get list comment button group
  const commentButtonGroup: ButtonGroupInfo[] = useMemo(() => {
    const items = Object.keys(CommentButtonGroup).map((key) => {
      const item = CommentButtonGroup[key as keyof typeof CommentButtonGroup]
      return { index: item.value, text: t(item.text), icon: undefined }
    })

    return items
  }, [t])

  //get list sort button group
  const sortCommentButtonGroup: ButtonGroupInfo[] = useMemo(() => {
    const items = Object.keys(SortCommentButtonGroup).map((key) => {
      const item =
        SortCommentButtonGroup[key as keyof typeof SortCommentButtonGroup]

      const SvgIcon = item.icon
      return {
        index: item.value,
        text: undefined,
        icon: <SvgIcon width={24} height={24} />,
      }
    })

    return items
  }, [])

  //Action change button group item
  const handleCommentButtonGroupItemClick = useCallback(
    (itemData: ButtonGroupInfo) => {
      dispatch(commentButtonGroupIndexUpdated(itemData.index))
    },
    [dispatch]
  )

  //Action change sort button group item
  const handleSortButtonGroupItemClick = useCallback(
    (itemData: ButtonGroupInfo) => {
      dispatch(sortCommentButtonGroupIndexUpdated(itemData.index))
    },
    [dispatch]
  )

  return (
    <div className=" flex flex-col gap-y-5 p-2">
      <div className="flex flex-row justify-between">
        <CrewButtonGroup
          items={commentButtonGroup}
          keyExpr="index"
          textExpr="text"
          iconExpr="icon"
          stylingMode="text"
          selectedItemKey={selectedCommentDisplayType}
          onItemClick={handleCommentButtonGroupItemClick}
          size="md"
        />

        <div className="flex gap-x-1">
          <CrewButtonGroup
            items={sortCommentButtonGroup}
            keyExpr="index"
            textExpr="text"
            iconExpr="icon"
            stylingMode="text"
            selectedItemKey={selectedSortCommentDisplayType}
            onItemClick={handleSortButtonGroupItemClick}
          />
        </div>
      </div>
    </div>
  )
})
