import { skipToken } from '@reduxjs/toolkit/query'
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Route, Routes, useParams } from 'react-router-dom'

import { useGetChatRoomByEntityQuery } from '@crew/apis/chat/chatApis'
import { GetProjectRequest } from '@crew/apis/project/models/getProject/request'
import { useGetProjectQuery } from '@crew/apis/project/projectApis'
import {
  EntityType,
  KeywordFilterCondition,
  ProjectType,
} from '@crew/enums/domain'
import {
  useProjectAdvancedSettings,
  useSystemPermissions,
  useValueChangeEffect,
} from '@crew/hooks'
import { useTranslation } from '@crew/modules/i18n'
import { useChatCurrentService } from '@crew/states'
import { useUnmount } from '@dx-system/react-use'
import { Button as TextBoxButton } from 'devextreme-react/text-box'

import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { ProjectListTabs, SearchTabs } from 'enums/app'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { useAppDispatch, useAppSelector } from 'states/hooks'

import { ProjectDetailEventList } from './components/projectDetailEventList/projectDetailEventList'
import { ProjectDetailChatPanel } from './components/projectDetailChatPanel/projectDetailChatPanel'
import { ProjectDetailFileList } from './components/projectDetailFileList/projectDetailFileList'
import { ProjectDetailHeadPanel } from './components/projectDetailHeadPanel/projectDetailHeadPanel'
import { ProjectDetailMemberList } from './components/projectDetailMemberList/projectDetailMemberList'
import { ProjectDetailTab } from './components/projectDetailTab/projectDetailTab'
import { ProjectDetailTaskList } from './components/projectDetailTaskList/projectDetailTaskList'
import { setProjectSubject } from './states/projectDetailSlice'
import { CrewTextBox } from 'components/devextreme/crewTextBox'
import { TextBoxInstance } from 'devextreme/ui/text_box'
import { OPERATION_KEY } from 'configs/constants'
import { NativeEventInfo } from 'devextreme/events'
import { ButtonTypes } from 'devextreme-react/cjs/button'
import { getDefaultTabValue } from '@crew/utils/dist/enum'

export const ProjectDetailPage: FC = memo(() => {
  const { t } = useTranslation()

  const { projectId } = useParams()

  const { navigate } = useCrewNavigate()

  const dispatch = useAppDispatch()

  // Sliceの操作を行うためのServiceを取得
  const chatCurrentService = useChatCurrentService(dispatch)

  const projectEventMessage = useAppSelector(
    (state) => state.app.projectEventMessage
  )

  const projectTaskEventMessage = useAppSelector(
    (state) => state.app.projectTaskEventMessage
  )

  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)

  const favoriteEventMessage = useAppSelector(
    (state) => state.app.favoriteEventMessage
  )

  const { doTaskManagement, doEventManagement } =
    useProjectAdvancedSettings(projectId)

  // プロジェクト詳細を取得する
  // 三項演算子になっていて少し見づらいが、内部のパラメータがundefinedを受け付けないため三項演算子を使用している
  const getProjectParam: GetProjectRequest | undefined = projectId
    ? {
        projectId,
      }
    : undefined
  const {
    data: getProjectResult,
    refetch: getProjectRefetch,
    isLoading: isLoadingProjectDetail,
    isError: isErrorProjectDetail,
  } = useGetProjectQuery(getProjectParam ?? skipToken)

  const isJoinedProject = useMemo(
    () =>
      loggedInUser && getProjectResult?.project
        ? // プロジェクトのメンバーなら参加済として扱う
          getProjectResult.project.projectMembers.some(
            (projectMember) => loggedInUser.id === projectMember.id
          ) || // プロジェクトのオーナーなら参加済として扱う
          loggedInUser.id === getProjectResult.project.ownerUser.id
        : false, // 判定するための情報が足りない場合は未参加として扱う
    [getProjectResult?.project, loggedInUser]
  )

  // Call API get Project Detail
  useEffect(() => {
    if (projectId) {
      getProjectRefetch()
    }
  }, [
    projectId,
    projectEventMessage,
    projectTaskEventMessage,
    getProjectRefetch,
    favoriteEventMessage,
  ])

  // Update project subject
  useValueChangeEffect(
    () => {
      if (getProjectResult?.project) {
        dispatch(setProjectSubject(getProjectResult.project.subject))
      }
    },
    [dispatch, getProjectResult?.project],
    getProjectResult?.project?.subject
  )

  // プロジェクトに紐づくチャットルームIDを取得する
  const { data: getChatRoomResponse } = useGetChatRoomByEntityQuery(
    {
      entityType: EntityType.Project,
      entityRecordId: projectId as string,
    },
    {
      // プロジェクトに参加していない場合は取得処理が走ってしまうと、プロジェクトに未参加の状態から参加した直後の
      // 初回のチャットメッセージ取得処理が正しく動作しないため、チャットルームを取得しないようにする
      skip: !isJoinedProject,
    }
  )

  const [searchConditionMode, setSearchConditionMode] =
    useState<KeywordFilterCondition>('and')

  // Apply search keyword to cross search
  const handleCrossSearchChanged = useCallback(
    (e: NativeEventInfo<TextBoxInstance, KeyboardEvent>) => {
      const keyword = e.component.option('text')
      keyword &&
        navigate(`/search/${getDefaultTabValue(SearchTabs)}`, {
          keyword,
          projectId,
          [OPERATION_KEY]: searchConditionMode,
        })
    },
    [navigate, projectId, searchConditionMode]
  )

  const searchConditionButton = useMemo<ButtonTypes.Properties>(
    () => ({
      stylingMode: 'text',
      onClick: () => {
        setSearchConditionMode((prevConditionMode: KeywordFilterCondition) =>
          prevConditionMode === 'and' ? 'or' : 'and'
        )
      },
      text: searchConditionMode.toLocaleUpperCase(),
    }),
    [searchConditionMode]
  )

  // 取得したチャットルームIDに紐づくチャットメッセージを取得する
  useEffect(
    () => {
      if (!isJoinedProject) {
        // 未参加のプロジェクトの場合、チャットルーム情報をリセットする
        chatCurrentService.resetCurrentChatRoom()
      } else {
        if (getChatRoomResponse?.chatRoom && projectId) {
          // 表示するチャットルームを設定する
          chatCurrentService.setCurrentChatRoomAndRestoreChatThread({
            id: getChatRoomResponse.chatRoom.id,
            entityType: EntityType.Project,
            entityRecordId: projectId,
            rootEntityType: getChatRoomResponse.chatRoom.rootEntityType,
            rootEntityRecordId: getChatRoomResponse.chatRoom.rootEntityRecordId,
          })
        }
      }
    },
    // マウント時のみ値を格納とするので依存は不要
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, getChatRoomResponse?.chatRoom?.id, isJoinedProject]
  )

  // Go project list when delete success
  const handleProjectDeleted = useCallback(() => {
    navigate('/projects')
  }, [navigate])

  // アンマウント時、現在のチャットルーム情報をリセット
  useUnmount(() => {
    chatCurrentService.resetCurrentChatRoom()
  })

  const { hasSysPublicProjectViewJoinPermission } = useSystemPermissions()

  // パブリックプロジェクトの表示/参加権限がない かつ プロジェクトメンバーではない場合は表示しない
  if (!hasSysPublicProjectViewJoinPermission && !isJoinedProject) {
    return null
  }

  // インスタントチャンネルの場合は表示しない
  if (
    getProjectResult?.project?.projectType === ProjectType.InstantChannel ||
    getProjectResult?.project?.projectType === ProjectType.DirectChannel
  ) {
    return null
  }

  return (
    <div className="flex flex-1 gap-2 grow min-h-0">
      <main
        className="grow flex flex-col h-full w-2/3 overflow-y-auto"
        id="pjDetail"
      >
        <CrewErrorBoundary>
          <ProjectDetailHeadPanel
            projectDetail={getProjectResult?.project}
            isLoadingProjectDetail={isLoadingProjectDetail}
            isErrorProjectDetail={isErrorProjectDetail}
            isJoinedProject={isJoinedProject}
            onProjectDeleted={handleProjectDeleted}
          />
        </CrewErrorBoundary>
        <CrewErrorBoundary>
          {!isErrorProjectDetail &&
            !isLoadingProjectDetail &&
            // Display project after api loading complete and no error
            (isJoinedProject ? (
              <div className="min-h-0 flex-1 flex flex-col grow w-full">
                {/* プロジェクト詳細タブ + プロジェクト内横断検索ボックス*/}
                <div className="px-2">
                  <div className="flex flex-row border-b crew-border-gray items-center">
                    {/* プロジェクト詳細タブ */}
                    <ProjectDetailTab
                      doEventManagement={doEventManagement}
                      doTaskManagement={doTaskManagement}
                    />
                    {/* プロジェクト内横断検索ボックス */}
                    <div className="pr-0.5 ml-auto">
                      <CrewTextBox
                        id="search"
                        name="search"
                        placeholder={t('action.search')}
                        mode="search"
                        onEnterKey={handleCrossSearchChanged}
                      >
                        <TextBoxButton
                          name="searchCondition"
                          location="after"
                          options={searchConditionButton}
                        />
                      </CrewTextBox>
                    </div>
                  </div>
                </div>
                <div className="grow min-h-0 w-full flex flex-col">
                  {/* URLに応じて表示するコンポーネントを切り替える */}
                  <Routes>
                    {/* 
                          TODO: 一時的にデフォルトルート（index）を除去している
                          以下タスク対応時にデフォルトルートを設定する予定
                          https://break-tmc.atlassian.net/browse/CREW-9163
                        */}
                    <Route
                      path={ProjectListTabs.Chat.value}
                      element={<ProjectDetailChatPanel />}
                    />

                    {doEventManagement && (
                      <Route
                        path={ProjectListTabs.Meeting.value}
                        element={<ProjectDetailEventList />}
                      />
                    )}

                    {doTaskManagement && (
                      <Route
                        path={ProjectListTabs.Task.value}
                        element={<ProjectDetailTaskList />}
                      />
                    )}

                    <Route
                      path={ProjectListTabs.File.value}
                      element={<ProjectDetailFileList />}
                    />
                    <Route
                      path={ProjectListTabs.Member.value}
                      element={<ProjectDetailMemberList />}
                    />
                    {/** プロジェクト内検索 */}
                  </Routes>
                </div>
              </div>
            ) : (
              <div className="flex flex-1 justify-center items-center">
                {t('label.clickToJoinProject')}
              </div>
            ))}
        </CrewErrorBoundary>
      </main>
    </div>
  )
})
