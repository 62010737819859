import { FC, memo, useCallback, useMemo } from 'react'
import classNames from 'classnames'
import { motion, useAnimationControls } from 'framer-motion'
import MaterialSymbolsQuickReferenceOutline from '~icons/material-symbols/quick-reference-outline'
import { EntityType, ReferenceType } from '@crew/enums/domain'
import { ChatMessage } from '@crew/models/domain'
import { useTranslation } from '@crew/modules/i18n'
import { useToggle } from '@dx-system/react-use'
import { RagResponseReferenceEvent } from './components/ragResponseReferenceEvent/ragResponseReferenceEvent'
import { RagResponseReferenceTask } from './components/ragResponseReferenceTask/ragResponseReferenceTask'
import { RagResponseReferenceFile } from './components/ragResponseReferenceFile/ragResponseReferenceFile'
import { RagResponseReferenceChatMessage } from './components/ragResponseReferenceChatMessage/ragResponseReferenceChatMessage'
import { RagResponseReferenceTaskComment } from './components/ragResponseReferenceTaskComment/ragResponseReferenceTaskComment'

export type CrewRagResponseMessageProps = {
  message: ChatMessage // メッセージデータ
}

/**
 * RAG応答参照
 */
export const RagResponseReference: FC<CrewRagResponseMessageProps> = memo(
  (props) => {
    const { t } = useTranslation()

    // 折り畳み表示用のcontrolとローカルステートを定義
    const controls = useAnimationControls()
    const [expanded, toggleExpanded] = useToggle(true)

    // 折り畳み切り替え時のイベントハンドラ
    const handleToggleButtonClick = useCallback(() => {
      if (expanded) {
        controls.start('collapsed')
      } else {
        controls.start('expanded')
      }
      toggleExpanded()
    }, [controls, expanded, toggleExpanded])

    // 折り畳み・展開時の表示設定
    const variants = useMemo(
      () => ({
        expanded: { height: 'auto' },
        collapsed: { height: '0px' },
      }),
      []
    )

    // 参照情報を表示
    const renderResponseReferenceItems = useCallback(() => {
      const renderItems = props.message.references.map((reference) => {
        // RAG応答参照のみ表示するため、それ以外はnullを返し何も表示しない
        if (reference.referenceType !== ReferenceType.RagResponseReference) {
          return null
        }

        // entity_typeに応じてコンポーネントを出しわける
        switch (reference.entityType) {
          // イベント
          case EntityType.Event:
            return (
              <RagResponseReferenceEvent
                key={reference.id}
                reference={reference}
              />
            )
          // タスク
          case EntityType.Task:
            return (
              <RagResponseReferenceTask
                key={reference.id}
                reference={reference}
              />
            )
          // ファイル
          case EntityType.File:
            return (
              <RagResponseReferenceFile
                key={reference.id}
                reference={reference}
              />
            )
          // チャットメッセージ
          case EntityType.ChatMessage:
            return (
              <RagResponseReferenceChatMessage
                key={reference.id}
                reference={reference}
              />
            )
          // タスクコメント
          case EntityType.TaskComment:
            return (
              <RagResponseReferenceTaskComment
                key={reference.id}
                reference={reference}
              />
            )
          default:
            return null
        }
      })

      return <div className="flex flex-col py-2 gap-y-2">{renderItems}</div>
    }, [props.message.references])

    return (
      <>
        <div
          className="flex flex-row gap-x-1 items-center cursor-pointer"
          onClick={handleToggleButtonClick}
        >
          <MaterialSymbolsQuickReferenceOutline width={24} height={24} />

          {/* 参照情報 */}
          <div>{t('label.referenceInformation')}</div>

          <div>
            <i
              className={classNames(
                'bi',
                expanded ? 'bi-chevron-down' : 'bi-chevron-right'
              )}
            />
          </div>
        </div>

        {/* 参照情報の折り畳み表示部分 */}
        <motion.div
          animate={controls}
          initial="expanded"
          variants={variants}
          transition={{ duration: 0.3 }}
          className={classNames(
            'flex flex-row relative',
            !expanded && 'overflow-hidden'
          )}
        >
          {renderResponseReferenceItems()}
        </motion.div>
      </>
    )
  }
)
