import { ComponentCallbackHandler, assertNever } from '@crew/utils'
import classNames from 'classnames'
import {
  FC,
  memo,
  useMemo,
  useCallback,
  HTMLAttributeAnchorTarget,
} from 'react'
import { Link } from 'react-router-dom'

export const LinkColor = {
  Default: 'default',
  Gray: 'gray',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LinkColor = (typeof LinkColor)[keyof typeof LinkColor]

export type CrewLinkProps = {
  to?: string
  title?: string
  className?: string
  onClick?: ComponentCallbackHandler<typeof Link, 'onClick'>
  children?: React.ReactNode
  color?: LinkColor
  target?: HTMLAttributeAnchorTarget | undefined
  draggable?: boolean
  disabled?: boolean
}

// リンク表示用コンポーネント
export const CrewLink: FC<CrewLinkProps> = memo((props) => {
  // propsのonClickを実行し、props.toがない場合は遷移の必要が無いので、preventDefaultを実行する
  const handleClick = useCallback(
    (e: any) => {
      if (props.onClick) {
        props.onClick(e)
      }

      if (!props.to) {
        e.preventDefault()
      }
    },
    [props]
  )

  const linkClassNames = useGetLinkClassNames(props.color)

  return (
    <Link
      to={props.to ?? ''}
      title={props.title ?? ''}
      className={classNames(
        linkClassNames,
        props.className ?? 'truncate',
        props.disabled && 'pointer-events-none opacity-[0.5]' // リンクが無効の場合はクリックイベントを無効化
      )}
      onClick={handleClick}
      target={props.target}
      draggable={props.draggable}
    >
      {props.children}
    </Link>
  )
})

// get Link color from argument
const useGetLinkClassNames = (color: LinkColor = LinkColor.Default) => {
  return useMemo(() => {
    if (color === LinkColor.Default) {
      return 'crew-link'
    } else if (color === LinkColor.Gray) {
      return 'crew-link-gray'
    } else {
      assertNever(color, 'Invalid LinkColor')
    }
  }, [color])
}
