import { useCallback, useMemo, useState } from 'react'

import { useAiAssistantService, useChatMessage } from '@crew/states'

import { toggleRightSideBar } from 'features/app/states/appSlice'
import { useAppDispatch, useAppSelector } from 'states/hooks'

export const useAiAssistantThreadListMessageListItem = (
  chatMessageId: string
) => {
  const dispatch = useAppDispatch()
  const { message, isError } = useChatMessage(
    chatMessageId,
    dispatch,
    useAppSelector
  )
  const aiAssistantService = useAiAssistantService(dispatch)

  // 編集モードかどうか
  const [isEditMode, setIsEditMode] = useState(false)

  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)

  // 右パネルが開いているかどうか
  // 返信ボタンクリック時に、右パネルが開いていなければ開くために使用
  const rightSideBarOpened = useAppSelector(
    (state) => state.app.rightSideBarOpened
  )

  // 自分が投稿したメッセージ判定（自分のメッセージのみ編集可能とするフラグ）
  const isMyMessage = useMemo(
    () => message?.createdById === loggedInUser?.id,
    [loggedInUser?.id, message?.createdById]
  )

  // 返信のクリック時に対象スレッドを開く
  const openTargetThread = useCallback(() => {
    // トピックが存在する場合、現在のチャットスレッドを更新する
    if (message) {
      aiAssistantService.setAiAssistantChannelCurrentChatThread({
        topicId: message.id,
        chatRoomId: message.chatRoomId,
        rootEntityType: message.chatRoom.rootEntityType,
        rootEntityRecordId: message.chatRoom.rootEntityRecordId,
        entityType: message.chatRoom.entityType,
        entityRecordId: message.chatRoom.entityRecordId,
      })
    }

    // 右サイドバーを開く
    !rightSideBarOpened && dispatch(toggleRightSideBar())
  }, [message, rightSideBarOpened, dispatch, aiAssistantService])

  // 「編集」押下時に編集モードを切り替える
  const toggleEditMode = useCallback(() => {
    setIsEditMode(!isEditMode)
  }, [isEditMode])

  // 編集モードの登録 / キャンセル時に編集モードをoffにする
  const cancelEditMode = useCallback(() => {
    setIsEditMode(false)
  }, [])

  return {
    message,
    isError,
    isMyMessage,
    isEditMode,
    toggleEditMode,
    cancelEditMode,
    openTargetThread,
  }
}
