import { memo, FC, useCallback, useState } from 'react'
import { AiAssistantThreadList } from '../aiAssistantThreadList/aiAssistantThreadList'
import { useLazyGetChatRoomByEntityQuery } from '@crew/apis/chat/chatApis'
import { useGetOrInsertProjectForAiAssistantChannelMutation } from '@crew/apis/project/projectApis'
import { EntityType } from '@crew/enums/domain'
import { Project } from '@crew/models/domain'
import { Chat, useAiAssistantService } from '@crew/states'
import { useEffectOnce } from '@dx-system/react-use'
import { useAppDispatch } from 'states/hooks'

/**
 * AIアシスタントパネル
 */
export const AiAssistantPanel: FC = memo(() => {
  // Sliceの操作を行うためのServiceを取得
  const dispatch = useAppDispatch()
  const aiAssistantService = useAiAssistantService(dispatch)

  // AIアシスタントチャンネル取得用
  const [getOrInsertProjectForAiAssistantChannel] =
    useGetOrInsertProjectForAiAssistantChannelMutation()

  // チャットルーム取得用
  const [lazyGetChatRoomByEntityQuery, lazyGetChatRoomByEntityQueryResult] =
    useLazyGetChatRoomByEntityQuery()

  const [aiAssistantChannel, setAiAssistantChannel] = useState<
    Project | undefined
  >(undefined)

  // AIアシスタントのチャットルームを取得する
  const getAiAssistantChatRoom = useCallback(async () => {
    const result = await getOrInsertProjectForAiAssistantChannel().unwrap()
    if (!result.aiAssistantChannel) {
      return
    }

    // AIアシスタントチャンネルをローカルステートに設定
    setAiAssistantChannel(result.aiAssistantChannel)

    const params = {
      entityType: EntityType.Project,
      entityRecordId: result.aiAssistantChannel.id,
    }
    await lazyGetChatRoomByEntityQuery(params)
  }, [getOrInsertProjectForAiAssistantChannel, lazyGetChatRoomByEntityQuery])

  // 画面を開いたらAIアシスタントチャンネル取得を実行する
  useEffectOnce(() => {
    getAiAssistantChatRoom()
  })

  // undefinedチェック・nullチェックを行うにあたり説明変数に格納
  const aiAssistantChatRoomEntity =
    lazyGetChatRoomByEntityQueryResult.data?.chatRoom

  // AIアシスタントのチャットルームが取得できていない場合は何も描画しない
  if (!aiAssistantChatRoomEntity || !aiAssistantChannel) {
    return null
  }

  // AIアシスタントチャンネルのチャットルーム情報をReduxに設定
  const aiAssistantChatRoom: Chat.AiAssistantChannelChatRoom = {
    id: aiAssistantChatRoomEntity.id,
    rootEntityType: aiAssistantChatRoomEntity.rootEntityType,
    rootEntityRecordId: aiAssistantChatRoomEntity.rootEntityRecordId,
    entityType: EntityType.Project,
    entityRecordId: aiAssistantChannel.id, // AIアシスタントチャンネルはプロジェクトでありentityRecordIdとrootEntityRecordIdは同じ値を取る
  }
  aiAssistantService.setAiAssistantChannelChatRoom(aiAssistantChatRoom)

  // 現在の仕様ではスレッドリスト形式のみ
  return <AiAssistantThreadList />
})
