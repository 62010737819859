import { ColumnDef, TableOptions, getCoreRowModel } from '@tanstack/react-table'
import {
  CrewTable,
  SelectCheckbox,
} from 'components/elements/crewTable/crewTable'
import { FC, memo, useCallback, useMemo, useState } from 'react'
import { CrewUserItem } from 'components/elements/crewUserItem/crewUserItem'
import { EventDetailAttendances } from 'enums/app'
import { CrewBadge } from 'components/elements/crewBadge/crewBadge'
import { useTranslation } from '@crew/modules/i18n'
import { RowSelectionState } from '@tanstack/react-table'
import { useEventAttendeesContext } from '../../../../eventDetailAttendeeList'
import { useValueChangeEffect } from '@crew/hooks'
import { UserRef } from '@crew/models/refs'
import { useEventDetailPageContext } from 'features/event/components/eventDetailPage/useEventDetailPage'

type EventAttendee = {
  eventId: string
  userId: string
  user: UserRef
  attendance: string | null
  comment: string | null
  version: number
}

export type AttendeeTableProps = {
  eventAttendees: EventAttendee[]
}
export const AttendeeTable: FC<AttendeeTableProps> = memo((props) => {
  const { setSelectedAttendeeIds } = useEventAttendeesContext()
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  // If the event list is changed, reset the selected row in the table
  useValueChangeEffect(
    () => {
      const selection = Object.keys(rowSelection).reduce((obj, key) => {
        if (props.eventAttendees.some((attendee) => attendee.userId === key)) {
          obj[key] = rowSelection[key]
        }
        return obj
      }, {} as RowSelectionState)
      setRowSelection(selection)
    },
    [props.eventAttendees, rowSelection],
    props.eventAttendees,
    true
  )

  useMemo(() => {
    const result = props.eventAttendees
      .filter((_item) => {
        return rowSelection[_item.userId]
      })
      .map((item) => {
        return item.userId
      })
    // set result to provider
    setSelectedAttendeeIds(result)
  }, [props.eventAttendees, setSelectedAttendeeIds, rowSelection])

  const { t } = useTranslation()
  const [columnVisibility, setColumnVisibility] = useState({})
  const [columnPinning] = useState({
    left: ['select'],
  })
  const { confirmAttendance } = useEventDetailPageContext()

  //render attendance badge
  const renderAttendanceBadge = useCallback(
    (attendee: EventAttendee) => {
      switch (attendee.attendance) {
        case EventDetailAttendances.Attendance.value:
          return (
            <CrewBadge
              displayColor={EventDetailAttendances.Attendance.colorClass}
            >
              {t('label.attendance')}
            </CrewBadge>
          )
        case EventDetailAttendances.Absence.value:
          return (
            <CrewBadge displayColor={EventDetailAttendances.Absence.colorClass}>
              {t('label.absence')}
            </CrewBadge>
          )
        default:
          return (
            <CrewBadge displayColor="crew-badge-ring">
              {t('label.unanswered')}
            </CrewBadge>
          )
      }
    },
    [t]
  )

  const columns = useMemo<ColumnDef<EventAttendee>[]>(() => {
    const originalColumn: ColumnDef<EventAttendee>[] = [
      {
        id: 'select',
        accessorKey: 'select',
        header: ({ table }) => (
          <SelectCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="flex flex-1 justify-center">
            <SelectCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
        size: 80,
        minSize: 50,
        enableSorting: false,
        meta: {
          align: 'center',
        },
      },
      {
        id: 'attendee',
        accessorKey: 'attendee',
        header: () => t('label.attendee'),
        cell: ({ row }) => {
          return (
            <div className="truncate">
              <CrewUserItem
                id={row.original.userId}
                displayName={row.original.user.displayName}
                version={row.original.user.version}
              />
            </div>
          )
        },
        size: confirmAttendance ? 160 : 500,
        minSize: 50,
        enableSorting: false,
      },
    ]
    const additionColumn: ColumnDef<EventAttendee>[] = [
      {
        id: 'attendance',
        accessorKey: 'attendance',
        header: () => t('label.attending'),
        cell: ({ row }) => {
          return (
            <>
              {confirmAttendance && (
                <div className="whitespace-nowrap overflow-hidden">
                  {renderAttendanceBadge(row.original)}
                </div>
              )}
            </>
          )
        },
        size: 160,
        minSize: 50,
        enableSorting: false,
      },
      {
        id: 'comment',
        accessorKey: 'comment',
        header: () => t('label.comment'),
        cell: ({ row }) => {
          return (
            <>
              {confirmAttendance && (
                <div className="truncate">{row.original.comment}</div>
              )}
            </>
          )
        },
        size: 500,
        minSize: 50,
        enableSorting: false,
      },
    ]
    // if have confirmAttendance add additionColumn
    if (confirmAttendance) {
      originalColumn.push(...additionColumn)
    }

    return originalColumn
  }, [t, confirmAttendance, renderAttendanceBadge])

  const tableOptions: TableOptions<EventAttendee> = {
    data: props.eventAttendees,
    columns,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility,
      columnPinning,
      rowSelection,
    },
    getRowId: (row) => row.userId,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    manualPagination: true,
    manualSorting: true,
    enableMultiSort: true,
    enableRowSelection: true,
    maxMultiSortColCount: 2,
    meta: {
      headerRowHeight: 40,
      dataRowHeight: 50,
    },
  }

  return (
    <CrewTable
      tableOptions={tableOptions}
      showColumnSelector={false}
      showPager={false}
    />
  )
})
