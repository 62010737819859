import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FileListDisplayMode, MinutesDisplayMode } from 'enums/app'

type EventDetailPageStateType = {
  isExpandedDescription: boolean
  showMeetingPanel: boolean
  selectedFileListDisplayMode: number
  selectedMinutesDisplayMode: string
}

const initialEventDetailPageState: EventDetailPageStateType = {
  isExpandedDescription: false,
  showMeetingPanel: false,
  selectedFileListDisplayMode: FileListDisplayMode.List.id,
  selectedMinutesDisplayMode: MinutesDisplayMode.Minutes.value,
}

export const eventDetailPageSlice = createSlice({
  name: 'eventDetailPage',
  initialState: initialEventDetailPageState,
  reducers: {
    updateIsExpandedDescription: (state, action: PayloadAction<boolean>) => {
      state.isExpandedDescription = action.payload
    },

    showMeetingPanelUpdated: (state, action: PayloadAction<boolean>) => {
      state.showMeetingPanel = action.payload
    },

    setSelectedFileListDisplayMode: (state, action: PayloadAction<number>) => {
      state.selectedFileListDisplayMode = action.payload
    },

    setSelectedMinutesDisplayMode: (state, action: PayloadAction<string>) => {
      state.selectedMinutesDisplayMode = action.payload
    },
  },
})

export const {
  updateIsExpandedDescription,
  showMeetingPanelUpdated,
  setSelectedFileListDisplayMode,
  setSelectedMinutesDisplayMode,
} = eventDetailPageSlice.actions
