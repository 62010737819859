import { CrewRadioGroup } from 'components/devextreme/crewRadioGroup'
import { PaymentMethod } from 'enums/app'
import { usePaymentMethodDataSource } from 'hooks/dataSource/useResourceDataSource'
import { FC, memo, useCallback, useMemo, useState } from 'react'
import { ContractRegisterCreditCardForm } from './components/contractRegisterCreditCardForm/contractRegisterCreditCardForm'
import { ContractRegisterBankTransferForm } from './components/contractRegisterBankTransferForm/contractRegisterBankTransferForm'
import { Elements } from '@stripe/react-stripe-js'
import {
  useCreateSetupIntentQuery,
  useGetBillingCycleQuery,
  useGetPlansQuery,
  useLazyGetPurchaseUsersCountByUserTypeQuery,
} from '@crew/apis/contract/contractApis'
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js'
import { NativeEventInfo } from 'devextreme/events'
import dxRadioGroup from 'devextreme/ui/radio_group'
import { ValueChangedInfo } from 'devextreme/ui/editor/editor'
import { useAppSelector } from 'states/hooks'
import { CrewFieldLabel } from 'components/elements/crewFieldLabel'
import { useTranslation } from '@crew/modules/i18n'
import {
  BillingCycle,
  StripeSubscriptionStatus,
  ContractPlan,
} from '@crew/enums/app'
import { ComponentCallbackHandler } from '@crew/utils'
import { useValueChangeEffect } from '@crew/hooks'
import { GetPurchaseUsersCountByUserTypeRequest } from '@crew/apis/dist/contract/models/getPurchaseUsersCountByUserType/request'

// プラン情報のラジオボタンの選択値の型
type PlanRadioGroupItem = {
  value: ContractPlan
  name: string
  description: string
}

// プラン情報のラジオボタンの選択値の型
type BillingCycleRadioGroupItem = {
  value: BillingCycle
  name: string
  description: string
}

// render content
// renderとして使うのでmemo不可
const PlanItem: FC<PlanRadioGroupItem> = (props) => (
  <div
    id={`plan-radioId-${props.value}`}
    className="w-full h-10 justify-start items-center inline-flex"
  >
    {/* プラン名 */}
    <div className="w-40 justify-start items-center gap-2.5 flex">
      <div className="text-md">{props.name}</div>
    </div>
    {/* 説明 */}
    <div className="gap-2.5 flex">
      <div className="crew-text-gray-4 text-md">{props.description}</div>
    </div>
  </div>
)

// render content
// renderとして使うのでmemo不可
const BillingCycleItem: FC<BillingCycleRadioGroupItem> = (props) => (
  <div
    id={`cycle-radioId-${props.value}`}
    className="w-full h-10 justify-start items-center inline-flex"
  >
    {/* 契約サイクル */}
    <span className="leading-5 w-20">{props.name}</span>
    {/* 説明 */}
    <div className="w-48 crew-text-gray-5 leading-tight">
      {props.description}
    </div>
  </div>
)

export const ContractRegisterPaymentMethodPage: FC = memo(() => {
  const { t } = useTranslation()
  const paymentMethodDataSource = usePaymentMethodDataSource()
  const themeMode = useAppSelector((state) => state.app.currentTheme)

  // 請求サイクルを取得する
  const { data: billingCycleData } = useGetBillingCycleQuery()

  // プラン情報一覧を取得
  const { data: plansResult, isFetching } = useGetPlansQuery()

  // 選択したプランに応じて購入対象ユーザー数を取得する関数
  const [lazyGetPurchaseUsersCountByUserType] =
    useLazyGetPurchaseUsersCountByUserTypeQuery()

  // 基本サービス利用料の購入対象となる一般ユーザー数
  const [purchaseInternalUsersCount, setPurchaseInternalUsersCount] = useState<
    number | undefined
  >(undefined)

  // オプション利用料(外部ユーザー)の購入対象となる外部ユーザー数
  const [purchaseExternalUsersCount, setPurchaseExternalUsersCount] = useState<
    number | undefined
  >(undefined)

  // 契約プランのラジオボタンに値を渡すための変数
  const [selectedPlan, setSelectedPlan] = useState<ContractPlan | undefined>(
    undefined
  )

  // 請求サイクルのラジオボタンに値を渡すための変数
  const [selectedBillingCycle, setSelectedBillingCycle] =
    useState<BillingCycle>(BillingCycle.Month)

  // プラン一覧
  const planItems = useMemo(() => {
    const items: PlanRadioGroupItem[] = []
    plansResult?.plans.map((item) =>
      items.push({
        value: item.plan,
        name: t(`label.planType.${item.plan}`),
        description: t(`label.planDescriptionForUpdate.${item.plan}`),
      })
    )

    return items
  }, [plansResult?.plans, t])

  // 契約サイクル一覧
  const billingItems = useMemo(() => {
    // 選択中のプランの契約サイクルの情報を取得
    const plan = plansResult?.plans.find((item) => item.plan === selectedPlan)
    if (!plan) {
      return []
    }

    return Object.values(BillingCycle).map((cycle) => {
      // プラン情報から各請求サイクルの情報を取得
      const billingCycle = plan.billingCycles.find(
        (item) => item.billingCycle === cycle
      )
      if (!billingCycle) {
        // undefinedになることはないが、型制約のためチェック処理を追加
        return []
      }

      const item: BillingCycleRadioGroupItem = {
        value: cycle,
        name: t(`label.cycleBilling.${cycle}.name`),
        description: t(`label.cycleBilling.${cycle}.description`),
      }

      return item
    })
  }, [plansResult?.plans, selectedPlan, t])

  // プラン一覧取得時、現在契約中のプランをラジオボタンの選択値に反映する
  useValueChangeEffect(
    () => {
      // プラン一覧から現在契約中のプラン情報を検索
      const currentPlan = plansResult?.plans.find((item) =>
        item.billingCycles.some((billingCycle) => billingCycle.isCurrentCycle)
      )
      // 現在契約中のプラン情報があれば選択状態にする
      // ただし、契約中のプランがフリープランの場合は、アップグレードの処理となるためスタンダードを選択状態にする
      if (currentPlan && currentPlan.plan !== ContractPlan.Free) {
        setSelectedPlan(currentPlan.plan)
      } else {
        setSelectedPlan(ContractPlan.Standard)
      }
    },
    [plansResult],
    plansResult
  )

  // 請求サイクルを取得できたらラジオボタンの選択値に反映する
  useValueChangeEffect(
    () => {
      if (billingCycleData) {
        billingCycleData.billingCycle.forEach((item) => {
          if (item.isCurrentCycle) {
            setSelectedBillingCycle(item.billingCycle)
          }
        })
      }
    },
    [billingCycleData],
    billingCycleData
  )

  // 契約プランのラジオボタンの選択値が変わったときのイベントハンドラ
  const handlePlanRadioGroupValueChanged = useCallback<
    ComponentCallbackHandler<typeof CrewRadioGroup, 'onValueChanged'>
  >((event) => {
    setSelectedPlan(event.value)
  }, [])

  // 契約サイクルのラジオボタンの選択値が変わったときのイベントハンドラ
  const handleBillingCycleRadioGroupValueChanged = useCallback<
    ComponentCallbackHandler<typeof CrewRadioGroup, 'onValueChanged'>
  >((event) => {
    setSelectedBillingCycle(event.value)
  }, [])

  // 支払い方法
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>(
    PaymentMethod.creditCard.value
  )

  const {
    data: createSetupIntentResult,
    isFetching: isCreateSetupIntentFetching,
  } = useCreateSetupIntentQuery()

  // 支払方法変更時のイベントハンドラ
  const handlePaymentMethodChanged = useCallback(
    (e: NativeEventInfo<dxRadioGroup, Event> & ValueChangedInfo) => {
      setSelectedPaymentMethod(e.value)
    },
    []
  )

  // Stripe要素のオプション
  const stripeElementOptions = useMemo<StripeElementsOptions>(() => {
    return {
      appearance: {
        theme: themeMode === 'light' ? 'flat' : 'night',
      },
      clientSecret: createSetupIntentResult?.clientSecret,
    }
  }, [createSetupIntentResult?.clientSecret, themeMode])

  // トライアル中の場合は契約サイクルを表示するため、サブスクリプションステータスを取得する
  const isTrialing =
    createSetupIntentResult?.subscriptionStatus ===
    StripeSubscriptionStatus.Trialing

  const isFree = createSetupIntentResult?.currentPlan === ContractPlan.Free

  const isPaid = !isTrialing && !isFree

  // 請求方法のラジオボタンのrender
  const renderPaymentRadioGroupItem = useCallback(
    (item: { id: string; name: string }) => {
      return (
        <div
          id={`plan-radioId-${item.id}`}
          className="w-full h-10 justify-start items-center inline-flex"
        >
          {/* 支払い方法 */}
          <div className="w-28 justify-start items-center gap-2.5 flex">
            <div className="text-md">{t(item.name)}</div>
          </div>
        </div>
      )
    },
    [t]
  )

  // 基本サービス利用料の単価
  const serviceUnitAmount = useMemo(() => {
    // 選択中のプランの契約サイクルの情報を取得
    const plan = plansResult?.plans.find((item) => item.plan === selectedPlan)
    if (!plan) {
      // undefinedになることはないが、undefinedになっていると正しい単価が取得できないのでundefinedを返すようにしておく
      return
    }
    // プラン情報から各請求サイクルの情報を取得
    const billingCycle = plan.billingCycles.find(
      (item) => item.billingCycle === selectedBillingCycle
    )
    if (!billingCycle) {
      // undefinedになることはないが、undefinedになっていると正しい単価が取得できないのでundefinedを返すようにしておく
      return
    }

    return billingCycle.serviceUnitAmount
  }, [plansResult?.plans, selectedBillingCycle, selectedPlan])

  // 購入対象ユーザー数を取得し、ローカルステートに反映する関数
  const getPurchaseUsersCount = useCallback(async () => {
    // 選択中のプランが取得できていない場合は、正しい表示にならない恐れがあるのでundefinedを返すようにしておく
    if (!selectedPlan) {
      return
    }

    // 選択されているプランに応じて購入対象ユーザー数を表示するため、APIを呼び出す
    const params: GetPurchaseUsersCountByUserTypeRequest = {
      targetPlan: selectedPlan,
    }
    const response = await lazyGetPurchaseUsersCountByUserType(params).unwrap()
    // 購入対象ユーザー数が取得できていない場合は、正しい表示にならない恐れがあるのでundefinedを返すようにしておく
    if (!response) {
      return
    }

    setPurchaseInternalUsersCount(response.internalUsersCount)
    setPurchaseExternalUsersCount(response.externalUsersCount)
  }, [lazyGetPurchaseUsersCountByUserType, selectedPlan])

  // プランの選択が変更されたらその都度API経由で購入対象ユーザー数を取得する
  useValueChangeEffect(
    () => {
      getPurchaseUsersCount()
    },
    [getPurchaseUsersCount],
    selectedPlan
  )

  // 基本サービス利用料の合計金額
  const serviceFeeAmount = useMemo(() => {
    // 購入対象ユーザー数や単価がundefinedの場合は、正しい表示にならない恐れがあるのでundefinedを返すようにしておく
    if (
      purchaseInternalUsersCount === undefined ||
      serviceUnitAmount === undefined
    ) {
      return
    }

    // 基本サービス利用料 = 基本サービス利用料(1ユーザーあたり) * 購入対象の一般ユーザー数
    return serviceUnitAmount * purchaseInternalUsersCount
  }, [purchaseInternalUsersCount, serviceUnitAmount])

  // 基本サービス利用料の計算式表示
  const renderServiceFeeFormula = useCallback(() => {
    // 購入対象ユーザー数や単価がundefinedの場合は、正しい表示にならない恐れがあるので何も表示しない
    if (
      purchaseInternalUsersCount === undefined ||
      serviceUnitAmount === undefined
    ) {
      return ''
    }

    return t('label.cycleBilling.priceWithUserCount', {
      price: serviceUnitAmount.toLocaleString(),
      userCount: purchaseInternalUsersCount.toLocaleString(),
    })
  }, [purchaseInternalUsersCount, serviceUnitAmount, t])

  // 基本サービス利用料の金額表示
  const renderServiceFeeAmount = useCallback(() => {
    // 金額がundefinedの場合は、正しい表示にならない恐れがあるので何も表示しない
    if (serviceFeeAmount === undefined) {
      return ''
    }

    return t('label.cycleBilling.priceAmount', {
      price: serviceFeeAmount.toLocaleString(),
    })
  }, [t, serviceFeeAmount])

  // オプション利用料(外部ユーザー)の単価
  const extUserUnitAmount = useMemo(() => {
    // 選択中のプランの契約サイクルの情報を取得
    const plan = plansResult?.plans.find((item) => item.plan === selectedPlan)
    if (!plan) {
      // undefinedになることはないが、undefinedになっていると正しい単価が取得できないのでundefinedを返すようにしておく
      return
    }
    // プラン情報から各請求サイクルの情報を取得
    const billingCycle = plan.billingCycles.find(
      (item) => item.billingCycle === selectedBillingCycle
    )
    if (!billingCycle) {
      // undefinedになることはないが、undefinedになっていると正しい単価が取得できないのでundefinedを返すようにしておく
      return
    }

    return billingCycle.extUserUnitAmount
  }, [plansResult?.plans, selectedBillingCycle, selectedPlan])

  // オプション利用料(外部ユーザー)の合計金額
  const extUserFeeAmount = useMemo(() => {
    // 購入対象ユーザー数や単価がundefinedの場合は、正しい表示にならない恐れがあるのでundefinedを返すようにしておく
    if (
      purchaseExternalUsersCount === undefined ||
      extUserUnitAmount === undefined
    ) {
      return
    }

    // オプション利用料(外部ユーザー) = オプション利用料(1ユーザーあたり) * 購入対象の外部ユーザー数(10人単位)
    return extUserUnitAmount * purchaseExternalUsersCount
  }, [extUserUnitAmount, purchaseExternalUsersCount])

  // オプション利用料(外部ユーザー)の計算式表示
  const renderExtUserFeeFormula = useCallback(() => {
    // 購入対象ユーザー数や単価がundefinedの場合は、正しい表示にならない恐れがあるので何も表示しない
    if (
      purchaseExternalUsersCount === undefined ||
      extUserUnitAmount === undefined
    ) {
      return ''
    }

    return t('label.cycleBilling.priceWithUserCount', {
      price: extUserUnitAmount.toLocaleString(),
      userCount: purchaseExternalUsersCount.toLocaleString(),
    })
  }, [extUserUnitAmount, t, purchaseExternalUsersCount])

  // オプション利用料(外部ユーザー)の金額表示
  const renderExtUserFeeAmount = useCallback(() => {
    // 金額がundefinedの場合は、正しい表示にならない恐れがあるので何も表示しない
    if (extUserFeeAmount === undefined) {
      return ''
    }

    return t('label.cycleBilling.priceAmount', {
      price: extUserFeeAmount.toLocaleString(),
    })
  }, [t, extUserFeeAmount])

  // ご請求額
  const totalAmount = useMemo(() => {
    // 基本サービス利用料とオプション利用料(外部ユーザー)の合計金額が取得できていない場合は、
    // 正しい表示にならない恐れがあるのでundefinedを返すようにしておく
    if (serviceFeeAmount === undefined || extUserFeeAmount === undefined) {
      return
    }

    return serviceFeeAmount + extUserFeeAmount
  }, [serviceFeeAmount, extUserFeeAmount])

  // 請求額の表示
  const renderTotalAmount = useCallback(() => {
    // 合計金額が取得できていない場合は、正しい表示にならない恐れがあるので何も表示しない
    if (totalAmount === undefined) {
      return null
    }

    return (
      <div className="flex flex-col gap-y-1 ml-8">
        {/* 基本サービス利用料 */}
        <div className="flex flex-row">
          <div className="w-72 crew-text-gray-5">
            {t('label.cycleBilling.serviceFeeLabel')}
          </div>
          <div className="grow text-blue-500 dark:text-blue-600">
            {renderServiceFeeFormula()}
          </div>
          <div className="text-blue-500 dark:text-blue-600">
            {renderServiceFeeAmount()}
          </div>
        </div>
        {/* オプション利用料(外部ユーザー) */}
        {purchaseExternalUsersCount !== undefined &&
          purchaseExternalUsersCount > 0 && (
            <div className="flex flex-row">
              <div className="w-72 crew-text-gray-5">
                {t('label.cycleBilling.extUserOptionFeeLabel')}
              </div>
              <div className="grow text-blue-500 dark:text-blue-600">
                {renderExtUserFeeFormula()}
              </div>
              <div className="text-blue-500 dark:text-blue-600">
                {renderExtUserFeeAmount()}
              </div>
            </div>
          )}
        {/* ご請求額 */}
        <div className="flex flex-row">
          <div className="w-72 crew-text-gray-5">
            {t('label.cycleBilling.totalAmountLabel')}
          </div>
          {/* ここに表示する内容はないが、空の要素で表示位置を調整する */}
          <div className="grow"></div>
          <div className="text-blue-500 dark:text-blue-600">
            {t('label.cycleBilling.priceAmount', {
              price: totalAmount.toLocaleString(),
            })}
          </div>
        </div>
      </div>
    )
  }, [
    totalAmount,
    t,
    renderServiceFeeFormula,
    renderServiceFeeAmount,
    purchaseExternalUsersCount,
    renderExtUserFeeFormula,
    renderExtUserFeeAmount,
  ])

  return (
    <div className="flex justify-center">
      <div className="py-2.5 flex flex-col gap-2.5 w-full max-w-2xl">
        {/* 見出し（支払方法の変更/ご契約内容） */}
        <div className="py-2.5">
          <span className="text-md font-bold">
            {isPaid
              ? t('label.changePaymentMethod')
              : t('label.contractDetails')}
          </span>
        </div>

        {/* サブスクリプションステータスがトライアル中またはフリープランを利用中の場合は契約サイクルの選択を表示する */}
        {!isPaid && (
          <>
            {/* 契約プラン変更用のラジオボタン */}
            <div>
              <CrewFieldLabel text={t('label.contractPlan')} />
              <CrewRadioGroup
                layout="vertical"
                disabled={isFetching} // フェッチ中は選択不可
                items={planItems}
                valueExpr="value"
                displayExpr="name"
                value={selectedPlan}
                itemRender={PlanItem}
                onValueChanged={handlePlanRadioGroupValueChanged}
              />
            </div>

            {/* 請求サイクル変更用のラジオボタン */}
            <div className="flex flex-col gap-y-2">
              <div>
                <CrewFieldLabel text={t('label.contractCycle')} />

                <CrewRadioGroup
                  layout="vertical"
                  disabled={isFetching} // フェッチ中は選択不可
                  items={billingItems}
                  valueExpr="value"
                  displayExpr="name"
                  value={selectedBillingCycle}
                  itemRender={BillingCycleItem}
                  onValueChanged={handleBillingCycleRadioGroupValueChanged}
                />
              </div>

              {/* 契約プラン・契約サイクルの選択状態に応じて請求額を表示 */}
              {renderTotalAmount()}
            </div>
          </>
        )}

        {/* 支払い方法変更用のラジオボタン */}
        <div>
          <CrewFieldLabel text={t('label.paymentMethod')} />
          <CrewRadioGroup
            layout="vertical"
            dataSource={paymentMethodDataSource}
            valueExpr="id"
            displayExpr="name"
            value={selectedPaymentMethod}
            itemRender={renderPaymentRadioGroupItem}
            onValueChanged={handlePaymentMethodChanged}
            disabled={isCreateSetupIntentFetching || isFetching}
          />
        </div>

        {/* 登録フォーム */}
        {!isCreateSetupIntentFetching && (
          <div>
            {/* クレジットカード */}
            {selectedPaymentMethod === PaymentMethod.creditCard.value && (
              <Elements
                stripe={loadStripe(process.env.REACT_APP_STRIPE_KEY || '')}
                options={stripeElementOptions}
              >
                <ContractRegisterCreditCardForm
                  billingCycle={!isPaid ? selectedBillingCycle : undefined}
                  plan={!isPaid ? selectedPlan : undefined}
                  showContactInformation={!isPaid}
                  // フリープランから有償プランへアップグレードする時にのみStripeの購入処理に外部ユーザーの購入数が必要
                  purchaseExternalUsersCount={
                    isFree ? purchaseExternalUsersCount : undefined
                  }
                />
              </Elements>
            )}
            {/* 銀行振込 */}
            {selectedPaymentMethod === PaymentMethod.bankTransfer.value && (
              <ContractRegisterBankTransferForm
                billingCycle={!isPaid ? selectedBillingCycle : undefined}
                plan={!isPaid ? selectedPlan : undefined}
                showContactInformation={!isPaid}
                // フリープランから有償プランへアップグレードする時にのみStripeの購入処理に外部ユーザーの購入数が必要
                purchaseExternalUsersCount={
                  isFree ? purchaseExternalUsersCount : undefined
                }
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
})
