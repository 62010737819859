import { FC, memo, useCallback, useState } from 'react'
import classNames from 'classnames'
import { ChatMessage } from '@crew/models/domain'
import {
  AvatarPosition,
  CrewChatMessageItemAvatar,
} from 'components/elements/crewChatMessageItem/components/crewChatMessageItemAvatar/crewChatMessageItemAvatar'
import { CrewChatMessageItemHeader } from 'components/elements/crewChatMessageItem/components/crewChatMessageItemHeader/crewChatMessageItemHeader'
import { ShowReplyButtonType, ShowReactionType } from '@crew/utils/chat'
import { useTranslation } from '@crew/modules/i18n'
import { MessageType } from '@crew/enums/domain'
import { CrewTaskNotificationChatMessage } from 'components/elements/crewChatMessageItem/components/crewTaskNotificationChatMessage/crewTaskNotificationChatMessage'
import { CrewFileNotificationChatMessage } from 'components/elements/crewChatMessageItem/components/crewFileNotificationChatMessage/crewFileNotificationChatMessage'
import { CrewProjectMemberNotificationChatMessage } from 'components/elements/crewChatMessageItem/components/crewProjectMemberNotificationChatMessage/crewProjectMemberNotificationChatMessage'
import { CrewEventNotificationChatMessage } from 'components/elements/crewChatMessageItem/components/crewEventNotificationChatMessage/crewEventNotificationChatMessage'
import { CrewReplyAndReactionButton } from '../crewReplyAndReactionButton/crewReplyAndReactionButton'
import { CrewCallNotificationChatMessage } from 'components/elements/crewChatMessageItem/components/crewCallNotificationChatMessage/crewCallNotificationChatMessage'
import { useLazyGetCallQuery } from '@crew/apis/call/callApis'
import { convertSecondsToMMSS } from '@crew/utils'
import dayjs from '@crew/modules'
import { useValueChangeEffect } from '@crew/hooks'
import { Call } from '@crew/apis/dist/call/models/getCall/response'

export type CrewNotificationMessageItemProps = {
  message: ChatMessage // 投稿メッセージデータ
  showRelatedLink: boolean // 投稿に関連先リンクを表示するかどうか
  canDownloadAttachment: boolean // 添付ファイルをダウンロードできるかどうか（ボタン表示制御に使用する）
  omitUserAvatar: boolean // アバターを表示しない場合はtrue
  showReplyButtonType: ShowReplyButtonType // 返信ボタンをどう表示するか
  showReactionsAndReactionButton: ShowReactionType // リアクションとリアクションボタンを表示するかどうか
  replyCount: number | undefined // 「○件の返信」と表示する場合のみ値を設定し、他はundefined
  customHeaderContent?: React.ReactNode | JSX.Element // ヘッダー部分をカスタマイズする場合は指定する
  truncateMessage: boolean // メッセージテキストを省略表示するかどうか
  onReplyClick: (() => void) | undefined // 返信ボタンを表示しない場合はundefined
  onClick: (() => void) | undefined // メッセージ全体をクリックした際のイベントコールバック
}

/**
 * 自動投稿メッセージ
 */
export const CrewNotificationMessageItem: FC<CrewNotificationMessageItemProps> =
  memo((props) => {
    const { t } = useTranslation()

    const [lazyGetCallQuery] = useLazyGetCallQuery()
    const [call, setCall] = useState<Call | null>(null)

    // 通話関連の自動投稿メッセージの場合のみ、通話情報を取得する
    useValueChangeEffect(
      () => {
        // 通話関連の自動投稿メッセージでない場合は何もしない
        if (
          props.message.messageType !== MessageType.CallEnded &&
          props.message.messageType !== MessageType.CallCancelled &&
          props.message.messageType !== MessageType.CallFailed
        ) {
          return
        }

        const getCall = async (callId: string) => {
          const result = await lazyGetCallQuery({
            callId: callId,
          }).unwrap()

          // 取得した通話情報をLocalStateにセット
          setCall(result.call)
        }

        // 通話情報を取得
        if (props.message.references.length > 0) {
          getCall(props.message.references[0].entityRecordId)
        }
      },
      [lazyGetCallQuery, props.message],
      props.message
    )

    // messageTypeに応じて自動投稿メッセージの表示を切り替える
    const renderNotificationMessage = useCallback(() => {
      switch (props.message.messageType) {
        case MessageType.TaskAdded:
        case MessageType.TaskUpdated:
        case MessageType.TaskDeleted:
        case MessageType.TaskCommentUpdated:
          return (
            <CrewTaskNotificationChatMessage
              messageType={props.message.messageType}
              chatMessageReferences={props.message.references}
              truncateMessage={props.truncateMessage}
            />
          )
        case MessageType.FileAdded:
        case MessageType.FileUpdated:
          return (
            <CrewFileNotificationChatMessage
              chatMessageReferences={props.message.references}
              canDownload={props.canDownloadAttachment}
              truncateMessage={props.truncateMessage}
            />
          )
        case MessageType.ProjectMemberJoined:
        case MessageType.ProjectMemberLeft:
        case MessageType.ProjectMemberRequested:
          return (
            <CrewProjectMemberNotificationChatMessage
              messageType={props.message.messageType}
              chatMessageReferences={props.message.references}
              truncateMessage={props.truncateMessage}
            />
          )
        case MessageType.EventAdded:
        case MessageType.EventUpdated:
        case MessageType.EventDeleted:
        case MessageType.EventJoined:
          return (
            <CrewEventNotificationChatMessage
              messageType={props.message.messageType}
              chatMessageReferences={props.message.references}
              truncateMessage={props.truncateMessage}
            />
          )
        case MessageType.CallEnded:
        case MessageType.CallCancelled:
        case MessageType.CallFailed:
          return (
            <CrewCallNotificationChatMessage
              messageType={props.message.messageType}
            />
          )
        default:
          return null
      }
    }, [
      props.canDownloadAttachment,
      props.truncateMessage,
      props.message.messageType,
      props.message.references,
    ])

    // messageTypeに応じて帯に表示するテキスト
    const renderMessageTypeText = useCallback(() => {
      if (props.message.messageType === MessageType.CallEnded) {
        /* 通話終了の場合 */

        // ビデオ通話か
        const isVideoCall = call?.isVideoEnabled

        // 通話時間を算出（終了日時ー開始日時）
        let callTime = '-:--'
        if (call?.inCallStartDatetime && call.callEndDatetime) {
          // 終了日時-開始日時の差分を秒で取得
          const callTimeSeconds = dayjs(call.callEndDatetime).diff(
            dayjs(call.inCallStartDatetime),
            'second'
          )

          // 秒を'm:ss'形式に変換
          callTime = convertSecondsToMMSS(callTimeSeconds)
        }

        return isVideoCall
          ? t(`message.chat.videoCallEnded`, {
              callTime: callTime,
            })
          : t(`message.chat.callEnded`, {
              callTime: callTime,
            })
      } else {
        return t(`message.chat.${props.message.messageType}`)
      }
    }, [call, props.message.messageType, t])

    return (
      <div className="flex flex-row gap-2 p-2" onClick={props.onClick}>
        {/* アバター */}
        <CrewChatMessageItemAvatar
          isLargeAvatar={true}
          user={props.message.createdBy}
          position={AvatarPosition.Center} // 自動投稿メッセージのため中央に表示する
          omitUserAvatar={props.omitUserAvatar}
        />

        <div className="flex flex-grow flex-col gap-1 min-w-0">
          {/* messageTypeに応じて帯にテキストを表示 */}
          <div
            className={classNames(
              'px-1 py-0.5 rounded-md text-sm font-bold',
              'text-crew-red-600 bg-crew-red-100'
            )}
          >
            {renderMessageTypeText()}
          </div>

          {/* カスタムヘッダー */}
          {props.customHeaderContent && props.customHeaderContent}

          {/* ユーザー名、投稿日、関連先リンク */}
          <CrewChatMessageItemHeader
            message={props.message}
            isShowChatRoomName={props.showRelatedLink}
          />

          {/* notification message */}
          {renderNotificationMessage()}

          {/* 返信ボタンとリアクションボタンを条件に応じて表示する */}
          {(props.showReplyButtonType !== ShowReplyButtonType.None ||
            props.showReactionsAndReactionButton !== ShowReactionType.None) && (
            <CrewReplyAndReactionButton
              message={props.message}
              onReplyClick={props.onReplyClick}
              showReplyButtonType={props.showReplyButtonType}
              showReactionsAndReactionButton={
                props.showReactionsAndReactionButton
              }
              replyCount={props.replyCount}
            />
          )}
        </div>
      </div>
    )
  })
