import { TimelineDirection } from '@crew/enums/app'
import { useCallback, useMemo, useState } from 'react'
import { useLazyGetChatMessagesQuery } from '@crew/apis/chat/chatApis'
import { useFetchLimit, useValueChangeEffect } from '@crew/hooks'
import { MESSAGE_AREA_MIN_HEIGHT } from '@crew/configs/constants'
import { getParamAsString, getParamsOperator, getWindowDimensions } from 'utils'
import { useSearchParams } from 'react-router-dom'
import qs from 'qs'
import { GetChatMessagesRequest } from '@crew/apis/chat/models/getChatMessages/request'
import { useAppDispatch } from 'states/hooks'
import { chatMessageCountUpdated } from '../../states/searchPageSlice'
import { OPERATION_KEY } from 'configs/constants'

export const MessageItemType = {
  /**
   * 基本的なスタイルで表示する。インデントなし、アバターはユーザアイコン大
   */
  NormalMessage: 'normalMessage',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MessageItemType =
  (typeof MessageItemType)[keyof typeof MessageItemType]

/** メッセージアイテムの型 */
export type DisplayMessageItem = {
  id: string
  messageId: string
  handleAdditionalLoading: (() => void) | undefined // 追加読込処理
}

/** チャットメッセージをロードするためのパラメータ */
type LoadSearchParams = {
  criterionMessageId: string | undefined
}

export const useSearchChatResultList = () => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const params = useMemo(
    () => qs.parse(searchParams.toString()),
    [searchParams]
  )

  // チャットデータ取得API
  const [lazyGetChatMessagesQuery] = useLazyGetChatMessagesQuery()

  // 一度にfetchするサイズ
  const fetchLimit = useFetchLimit(MESSAGE_AREA_MIN_HEIGHT, getWindowDimensions)

  // 検索キーワード
  const keyword = useMemo(() => getParamAsString('keyword', params), [params])

  // キーワードフィルタ条件
  const keywordFilterCondition = useMemo(
    () => getParamsOperator(OPERATION_KEY, params),
    [params]
  )

  // プロジェクトId
  const projectId = useMemo(
    () => getParamAsString('projectId', params),
    [params]
  )

  // 検索結果のチャットメッセージ一覧
  const [displayItems, setDisplayItems] = useState<DisplayMessageItem[]>([])

  // チャットメッセージを検索する
  const loadChatMessages = useCallback(
    async (params: LoadSearchParams) => {
      // キーワードが未指定の場合は何もしない
      if (!keyword) {
        return
      }

      const request: GetChatMessagesRequest = {
        keyword: keyword,
        projectId,
        keywordFilterCondition: keywordFilterCondition,
        normalMessageOnly: true, // 検索対象：通常メッセージのみ
        criterionMessageId: params.criterionMessageId ?? undefined,
        direction: TimelineDirection.Older, // 検索時は常に古い方向に読み込む
        limit: fetchLimit,
      }

      try {
        const data = await lazyGetChatMessagesQuery(request).unwrap()

        // 検索結果を表示用アイテムの型に変換する
        let messages: DisplayMessageItem[] = []

        if (data.chatMessages.length > 0) {
          // 型変換
          messages = data.chatMessages.map((chatMessage) => ({
            id: `${MessageItemType.NormalMessage}_${chatMessage.id}`,
            messageId: chatMessage.id,
            handleAdditionalLoading: undefined,
          }))

          // 最後まで読み込んだか
          const isLoadedOfEndOfMessages = fetchLimit > data.chatMessages.length

          // 最後まで読み込んでいない場合、末尾の要素の追加読み込み処理を設定する
          if (!isLoadedOfEndOfMessages) {
            messages[messages.length - 1].handleAdditionalLoading = () =>
              loadChatMessages({
                criterionMessageId: messages[messages.length - 1].messageId,
              })
          }
        }

        // 表示用アイテムを更新する
        setDisplayItems((prev) => {
          // 現在の表示用アイテムの末尾の要素の追加読み込み処理を無効にする
          if (prev.length > 0) {
            prev[prev.length - 1].handleAdditionalLoading = undefined
          }

          // 取得したメッセージを追加
          return [...prev, ...messages]
        })

        // 検索結果の総件数のバッジ表示を更新
        dispatch(chatMessageCountUpdated(data.totalCount))
      } catch (err) {
        // TODO: CREW-13720の対応で、プロジェクトから退出した際にエラートーストが出てしまう問題が発生し、トースト表示をコメントアウトする暫定対応を行った
        // ただ、これにより追加ロード時にエラーがあっても画面上表示されないという状態であるため、以下タスクで恒久対応を行う
        // 現時点ではコンソールにエラーを表示するにとどめる
        // https://break-tmc.atlassian.net/browse/CREW-13724
        // toast.error(t('message.general.failedToRetrieveData'))
        console.error(err)
      }
    },
    [
      dispatch,
      fetchLimit,
      keyword,
      keywordFilterCondition,
      lazyGetChatMessagesQuery,
      projectId,
    ]
  )

  // Run search when params change
  useValueChangeEffect(
    () => {
      // 現在の表示アイテムをクリアする
      setDisplayItems([])

      // キーワードが空の場合は検索しない
      if (!keyword) {
        return
      }

      loadChatMessages({
        criterionMessageId: undefined,
      })
    },
    [keyword, loadChatMessages],
    params
  )

  return {
    keyword,
    displayItems,
  }
}
