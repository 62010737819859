import { FC, memo } from 'react'

import { useAiAssistantThreadPanel } from './useAiAssistantThreadPanel'
import { AiAssistantThread } from '../aiAssistantThread/aiAssistantThread'

export type AiAssistantThreadPanelProps = {
  selectedItemId?: string // 選択中のメッセージ。ハイライト用に使用する
}

/**
 * AIアシスタント - スレッドパネル
 */
export const AiAssistantThreadPanel: FC<AiAssistantThreadPanelProps> = memo(
  (props) => {
    const { topicMessage, canAccessChatRoom } = useAiAssistantThreadPanel()

    // トピックが存在しない、またはチャットルームにアクセス権限がない場合は何も表示しない
    if (!topicMessage || !canAccessChatRoom) {
      return null
    }

    return (
      <div className="flex flex-col w-full h-full gap-1 overflow-hidden">
        <AiAssistantThread selectedItemId={props.selectedItemId} />
      </div>
    )
  }
)
