import { EntityType } from '@crew/enums/domain'
import {
  useProjectAdvancedSettings,
  useProjectPermissions,
  useValueChangeEffect,
} from '@crew/hooks'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { TaskEntryDialog } from 'features/task/components/taskEntryDialog/taskEntryDialog'
import { memo, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { ProjectDetailTaskSearch } from './components/projectDetailTaskSearch/projectDetailTaskSearch'
import { useTranslation } from '@crew/modules/i18n'
import { TaskDetailListTabs, TaskDisplayModeButtonGroup } from 'enums/app'
import { useModal } from 'components/layouts/modal/useModal'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import {
  setIsShowEditWBS,
  setSelectedTaskKanbanBucketType,
  setSelectedTaskListDisplayMode,
} from 'features/project/components/projectDetailPage/states/projectDetailSlice'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { getDefaultTabValue } from '@crew/utils/enum'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { CrewButtonGroup } from 'components/elements/crewButtonGroup/crewButtonGroup'
import { TaskKanbanBucketType } from '@crew/enums/app'
import { CrewSelectBox } from 'components/devextreme/crewSelectBox'

type ButtonGroupInfo = {
  index: number
  text: string | undefined
  icon: JSX.Element | undefined
}

export const ProjectDetailTaskListToolbar = memo(() => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { navigate } = useCrewNavigate()
  const { projectId } = useParams()

  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  /** ダイアログ */
  // タスク登録ダイアログ
  const [
    isProjectDetailTaskEntryDialogOpen,
    openProjectDetailTaskEntryDialog,
    closeProjectDetailTaskEntryDialog,
  ] = useModal()

  // 選択中の表示形式
  const selectedTaskListDisplayMode = useAppSelector(
    (state) => state.projectDetail.selectedTaskListDisplayMode
  )

  const isShowEditWBS = useAppSelector(
    (state) => state.projectDetail.isShowEditWBS
  )

  const isWbsNumberRenumbering = useAppSelector(
    (state) => state.projectDetail.isWbsNumberRenumbering
  )

  const selectedTaskKanbanBucketType = useAppSelector(
    (state) => state.projectDetail.selectedTaskKanbanBucketType
  )

  const { hasPrjTaskCreatePermission } = useProjectPermissions(
    EntityType.Project,
    projectId
  )

  const { doProjectManagement } = useProjectAdvancedSettings(projectId)

  const taskKanbanBucketTypeDataSource = useMemo(
    () =>
      Object.values(TaskKanbanBucketType).map((item) => {
        return { id: item.value, name: t(item.text) }
      }),
    [t]
  )

  // プロジェクトの設定でプロジェクト管理がOFFの場合、ガントチャート、EVMを非表示にする
  useValueChangeEffect(
    () => {
      if (
        !doProjectManagement &&
        (selectedTaskListDisplayMode === TaskDisplayModeButtonGroup.Gantt.id ||
          selectedTaskListDisplayMode === TaskDisplayModeButtonGroup.Evm.id)
      ) {
        dispatch(
          setSelectedTaskListDisplayMode(TaskDisplayModeButtonGroup.List.id)
        )
      }
    },
    [dispatch, doProjectManagement, selectedTaskListDisplayMode],
    doProjectManagement
  )

  // 表示形式のボタングループ
  const taskDisplayModeButtonGroup: ButtonGroupInfo[] = useMemo(() => {
    const items = Object.keys(TaskDisplayModeButtonGroup)
      .filter((key) => {
        const item =
          TaskDisplayModeButtonGroup[
            key as keyof typeof TaskDisplayModeButtonGroup
          ]

        // project management is OFF then hide Gantt chart, EVM
        if (
          item.id === TaskDisplayModeButtonGroup.Gantt.id ||
          item.id === TaskDisplayModeButtonGroup.Evm.id
        ) {
          return doProjectManagement
        }

        return true
      })
      .map((key) => {
        const item =
          TaskDisplayModeButtonGroup[
            key as keyof typeof TaskDisplayModeButtonGroup
          ]

        const SvgIcon = item.icon

        return {
          index: item.id,
          text: undefined,
          icon: <SvgIcon width={24} height={24} />,
        }
      })

    return items
  }, [doProjectManagement])

  // 表示形式の切替ボタンクリック時
  const handleTaskDisplayModeItemClick = useCallback(
    (itemData: ButtonGroupInfo) => {
      // 表示形式を更新
      dispatch(setSelectedTaskListDisplayMode(itemData.index))
    },
    [dispatch]
  )

  // タスク登録完了
  const handleTaskRegistered = useCallback(
    (taskId: string) => {
      // タスク詳細画面に遷移
      navigate(`/tasks/${taskId}/${getDefaultTabValue(TaskDetailListTabs)}`)

      //close project detail task entry dialog
      closeProjectDetailTaskEntryDialog()
    },
    [navigate, closeProjectDetailTaskEntryDialog]
  )

  // show edit WBS
  const handleEditWBSButtonClick = useCallback(() => {
    dispatch(setIsShowEditWBS(true))
  }, [dispatch])

  // finish editing WBS
  const handleFinishEditingWBSButtonClick = useCallback(() => {
    if (isWbsNumberRenumbering) {
      openConfirmDialog()
    } else {
      dispatch(setIsShowEditWBS(false))
    }
  }, [dispatch, isWbsNumberRenumbering, openConfirmDialog])

  // 確認ダイアログの許可ボタンクリック時のイベントハンドラ
  const handlePermitButtonClick = useCallback(() => {
    dispatch(setIsShowEditWBS(false))
    closeConfirmDialog()
  }, [closeConfirmDialog, dispatch])

  // カンバン表示モードのバケットタイプ変更
  const handleTaskKanbanBucketTypeChange = useCallback(
    (value: string) => {
      dispatch(setSelectedTaskKanbanBucketType(value))
    },
    [dispatch]
  )

  // ツールバーアイテムの描画
  const renderToolbarItems = useCallback(() => {
    // WBSを編集/WBSの編集終了
    const renderEditWBSButton = () => {
      return (
        <CrewButton
          type="normal"
          stylingMode="outlined"
          onClick={
            isShowEditWBS
              ? handleFinishEditingWBSButtonClick
              : handleEditWBSButtonClick
          }
          text={t(isShowEditWBS ? 'action.finishEditingWBS' : 'action.editWBS')}
        />
      )
    }

    // 新規タスクボタン
    const renderNewTaskButton = () => (
      <CrewButton
        type="primary"
        onClick={openProjectDetailTaskEntryDialog}
        text={t('action.newTask')}
      />
    )

    // カンバン表示モードのアイテムを描画
    const renderKanbanModeItems = () => (
      <>
        <CrewSelectBox
          dataSource={taskKanbanBucketTypeDataSource}
          valueExpr="id"
          displayExpr="name"
          searchEnabled={false}
          showClearButton={false}
          minSearchLength={0}
          onValueChange={handleTaskKanbanBucketTypeChange}
          value={selectedTaskKanbanBucketType}
        />
        {hasPrjTaskCreatePermission && renderNewTaskButton()}
      </>
    )

    // タスクリスト表示モードによってツールバーアイテムを変更
    if (selectedTaskListDisplayMode === TaskDisplayModeButtonGroup.Gantt.id) {
      return hasPrjTaskCreatePermission ? (
        <div className="flex flex-row items-center gap-x-1">
          {renderEditWBSButton()}
        </div>
      ) : null
    }
    // カンバン表示モードの場合、新規タスクボタンを表示
    else if (
      selectedTaskListDisplayMode === TaskDisplayModeButtonGroup.Kanban.id
    ) {
      return (
        <div className="flex flex-row items-center gap-x-1">
          {renderKanbanModeItems()}
        </div>
      )
    } else {
      // その他の表示モードの場合
      if (hasPrjTaskCreatePermission) {
        return (
          <div className="flex flex-row items-center gap-x-1">
            {renderNewTaskButton()}
          </div>
        )
      } else {
        return null
      }
    }
  }, [
    handleEditWBSButtonClick,
    handleFinishEditingWBSButtonClick,
    handleTaskKanbanBucketTypeChange,
    hasPrjTaskCreatePermission,
    isShowEditWBS,
    openProjectDetailTaskEntryDialog,
    selectedTaskKanbanBucketType,
    selectedTaskListDisplayMode,
    t,
    taskKanbanBucketTypeDataSource,
  ])

  return (
    <div className="flex-shrink-0">
      <div className="flex p-2.5 justify-between">
        {/* 表示切替ボタングループ */}
        <CrewButtonGroup
          items={taskDisplayModeButtonGroup}
          keyExpr="index"
          textExpr="text"
          iconExpr="icon"
          stylingMode="text"
          selectedItemKey={selectedTaskListDisplayMode}
          onItemClick={handleTaskDisplayModeItemClick}
        />

        {/* ツールバーアイテム */}
        {renderToolbarItems()}
      </div>

      {/* 検索条件 */}
      {/* ガントチャート・EVMチャートに対して検索ボックスを表示しない */}
      {selectedTaskListDisplayMode !== TaskDisplayModeButtonGroup.Gantt.id &&
        selectedTaskListDisplayMode !== TaskDisplayModeButtonGroup.Evm.id && (
          <div className="px-2.5 pb-2.5">
            <ProjectDetailTaskSearch />
          </div>
        )}

      {/* タスク登録ダイアログ */}
      <TaskEntryDialog
        isEditMode={false}
        title={t('label.addTaskTitle')}
        onSubmit={handleTaskRegistered}
        isOpen={isProjectDetailTaskEntryDialogOpen}
        onClose={closeProjectDetailTaskEntryDialog}
        projectId={projectId}
      />

      {/* Dialog confirm end the edit order task */}
      <CrewConfirmDialog
        isOpen={isConfirmDialogOpen}
        message={t('message.task.wbsNumberReorderConfirm')}
        onPermitButtonClick={handlePermitButtonClick}
        onCancelButtonClick={closeConfirmDialog}
        permitButtonText={t('action.yes')}
        permitButtonTheme="normal"
        cancelButtonText={t('action.no')}
        cancelButtonTheme="primary"
      />
    </div>
  )
})
