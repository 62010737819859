import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

type DesiredParams<T extends string> = { [key in T]: string | null }
type NonDesiredParams<T extends string> = Record<
  Exclude<string, T>,
  string | string[]
>

/**
 * URLのクエリパラメータをオブジェクトの形式で取得する
 * @param desiredKeys 必ず単体の値として取得したいキー。searchParams.get()に相当する。
 * @returns
 */
export const useMappedSearchParams = <T extends string>(
  ...desiredKeys: T[]
): DesiredParams<T> & NonDesiredParams<T> => {
  const [searchParams] = useSearchParams()

  // 必ず単体の値として取得したいキーを抽出する
  const desiredParams = useMemo(
    () =>
      desiredKeys.reduce((acc, key) => {
        acc[key] = searchParams.get(key)
        return acc
      }, {} as DesiredParams<T>),
    [desiredKeys, searchParams]
  )

  // その他のキーを抽出する
  const restParams = useMemo(() => {
    const result = Array.from(searchParams).reduce((acc, [key, value]) => {
      // 必ず単体の値として取得したいキーは除外する
      if (desiredKeys.includes(key as T)) return acc

      // その他のキー。型推論できないため、明示的に型を指定する
      const restKey = key as Exclude<string, T>

      // 型判定の都合上、今現在の値を一旦prevに格納する
      const prev = acc[restKey]
      if (prev) {
        // 既に存在するキー=重複なので、配列として扱う
        if (Array.isArray(prev)) {
          // 既に配列扱いの場合、配列の値を増やす
          acc[restKey] = [...prev, value]
        } else {
          // まだ配列扱いでない場合、新たに配列を作成する
          acc[restKey] = [prev, value]
        }
      } else {
        // まだ存在しないキー=初めてのキーなので、そのまま格納する
        acc[restKey] = value
      }

      return acc
    }, {} as NonDesiredParams<T>)
    return result
  }, [desiredKeys, searchParams])

  // 結果をマージして返す
  const result = useMemo(
    () => ({ ...desiredParams, ...restParams }),
    [desiredParams, restParams]
  )

  return result
}
