import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Scheduler, { SchedulerTypes, View } from 'devextreme-react/scheduler'
import { useProjectDetailTaskListCalendar } from './useProjectDetailTaskListCalendar'
import dayjs from '@crew/modules/dayjs'
import {
  CrewTaskTooltip,
  TaskTooltip,
} from 'components/elements/crewTaskTooltip/crewTaskTooltip'
import { Task } from '@crew/models/domain'
import { useAppSelector } from 'states/hooks'
import { useParams, useSearchParams } from 'react-router-dom'
import qs from 'qs'
import { getParamAsArray, getParamAsDate, getParamAsString } from 'utils'
import { DetailTaskSearchOptions, TaskDetailListTabs } from 'enums/app'
import { isDate } from '@crew/utils'
import { CrewScheduler } from 'components/devextreme/crewScheduler/crewScheduler'
import { JsonDateFormat } from '@crew/enums/system'
import { useModal } from 'components/layouts/modal/useModal'
import { useTranslation } from '@crew/modules/i18n'
import { TaskEntryDialog } from 'features/task/components/taskEntryDialog/taskEntryDialog'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { getDefaultTabValue } from '@crew/utils/enum'

type AppointmentType = {
  data: { appointmentData: Task }
}

export const ProjectDetailTaskListCalendar = memo(() => {
  const { t } = useTranslation()
  const { navigate } = useCrewNavigate()
  const { projectId } = useParams()

  /** ダイアログ */
  // タスク登録ダイアログ
  const [
    isProjectDetailTaskEntryDialogOpen,
    openProjectDetailTaskEntryDialog,
    closeProjectDetailTaskEntryDialog,
  ] = useModal()

  const [dueDate, setDueDate] = useState<Date>()

  // 表示対象のDate（データ取得対象期間の算出に使用する）
  const [displayTargetDate, setDisplayTargetDate] = useState<Date>(new Date())

  const [searchParams] = useSearchParams()
  const params = useMemo(
    () => qs.parse(searchParams.toString()),
    [searchParams]
  )

  const schedulerRef = useRef<Scheduler>(null)

  const { tasksDataSource } = useProjectDetailTaskListCalendar(schedulerRef)

  useEffect(() => {
    const filterParams = {
      keyword: getParamAsString(DetailTaskSearchOptions.Keyword.id, params),
      taskKindIds: getParamAsArray(
        DetailTaskSearchOptions.TaskKindId.id,
        params
      ),
      assignToUser: getParamAsString(
        DetailTaskSearchOptions.AssignToUser.id,
        params
      ),
      taskStateIds: getParamAsArray(
        DetailTaskSearchOptions.TaskStateId.id,
        params
      ),
      taskStateTypes: getParamAsArray(
        DetailTaskSearchOptions.TaskStateType.id,
        params
      ),
      taskPriorities: getParamAsArray(
        DetailTaskSearchOptions.TaskPriority.id,
        params
      )?.map((taskPriority) => Number(taskPriority)), // string[] -> number[]
      taskCategoryIds: getParamAsArray(
        DetailTaskSearchOptions.TaskCategoryId.id,
        params
      ),
      startDate: getParamAsDate(DetailTaskSearchOptions.StartDate.id, params),
      dueDate: getParamAsDate(DetailTaskSearchOptions.DueDate.id, params),
      createdById: getParamAsString(
        DetailTaskSearchOptions.CreatedById.id,
        params
      ),
      updatedById: getParamAsString(
        DetailTaskSearchOptions.UpdatedById.id,
        params
      ),
      createdAt: getParamAsDate(DetailTaskSearchOptions.CreatedAt.id, params),
      updatedAt: getParamAsDate(DetailTaskSearchOptions.UpdatedAt.id, params),
      targetDateFrom: undefined,
      targetDateTo: undefined,
    }

    tasksDataSource.filter(filterParams)
    tasksDataSource.load()
  }, [params, tasksDataSource])

  const taskEventMessage = useAppSelector((state) => state.app.taskEventMessage)

  // タスクの更新・削除が行われたときのみカスタムデータソースをリロード
  useEffect(() => {
    if (taskEventMessage) {
      // データソースがロードされていないと実行時エラーが発生するため処理を中断する
      if (!tasksDataSource.isLoaded()) return

      tasksDataSource.reload()
    }
  }, [taskEventMessage, tasksDataSource])

  // カレンダーセルクリック時のデフォルトツールチップ表示を抑制
  // NOTE: onCellClickでe.cancel = trueとしてもセルクリック時は抑制できるが、
  //       編集ダイアログ表示時になぜかツールチップもセットで表示されてしまうため、
  //       ツールチップ自体の起動タイミングでキャンセルできるonAppointmentFormOpeningを使用
  const handleAppointmentFormOpening = useCallback(
    (event: SchedulerTypes.AppointmentFormOpeningEvent) => {
      event.cancel = true
      // Set due date to state
      setDueDate(
        new Date(
          dayjs
            .utc(event.appointmentData?.dueDate)
            .format(JsonDateFormat.YYYYMMDD)
        )
      )

      // Open project detail task entry dialog
      openProjectDetailTaskEntryDialog()
    },
    [openProjectDetailTaskEntryDialog]
  )

  // タスク登録完了
  const handleTaskRegistered = useCallback(
    (taskId: string) => {
      // タスク詳細画面に遷移
      navigate(`/tasks/${taskId}/${getDefaultTabValue(TaskDetailListTabs)}`)

      //close project detail task entry dialog
      closeProjectDetailTaskEntryDialog()
    },
    [navigate, closeProjectDetailTaskEntryDialog]
  )

  // close appointment tooltip
  const handleCloseTooltip = useCallback(() => {
    schedulerRef.current?.instance.hideAppointmentTooltip()
  }, [])

  // Render appointment tooltip
  const renderAppointmentTooltip = useCallback(
    (item: AppointmentType) => {
      // Task item data of appointment tooltip
      const task: Task = item.data.appointmentData
      // Data to display in tooltip
      const taskTooltip: TaskTooltip = {
        id: task.id,
        subject: task.subject,
        description: task.description,
        startDate: task.startDate,
        dueDate: task.dueDate,
        assignToUser: task.assignToUser,
        taskPriority: task.taskPriority,
        taskKind: task.taskKind,
        taskState: task.taskState,
        estimatedWorkTimes: task.estimatedWorkTimes,
        actualWorkTimes: task.actualWorkTimes,
        remainingWorkTimes: task.remainingWorkTimes,
      }

      return (
        <CrewTaskTooltip
          data={taskTooltip}
          onCloseTooltip={handleCloseTooltip}
        />
      )
    },
    [handleCloseTooltip]
  )

  // 日付変更イベントハンドラ
  const handleCurrentDateChange = useCallback(
    (e: string | number | Date) => {
      if (isDate(e)) {
        // 表示対象日付のstateを更新する
        setDisplayTargetDate(e)
      }
    },
    [setDisplayTargetDate]
  )

  const [schedulerWidth, setSchedulerWidth] = useState<number>()

  // Listen layout change to update schedule width
  // Cell item position/width will be recalculated by the devextreme after update schedule width
  const schedulerContainerRef = useCallback((node: HTMLDivElement) => {
    if (!node) return
    const resizeObserver = new ResizeObserver(() =>
      setSchedulerWidth(node.offsetWidth)
    )
    resizeObserver.observe(node)
  }, [])

  // 日付セル内のカスタムレンダー
  // タスク数が多い場合、「その他xxxを選択する」を表示する
  const renderAppointmentCollector = useCallback(
    (data: SchedulerTypes.AppointmentCollectorTemplateData) => {
      return (
        <div className="crew-link">
          {t('label.selectMore', {
            value: data.appointmentCount,
          })}
        </div>
      )
    },
    [t]
  )

  return (
    <div ref={schedulerContainerRef} className="h-full w-full overflow-auto">
      <CrewScheduler
        ref={schedulerRef}
        timeZone={dayjs.tz.guess()}
        dataSource={tasksDataSource}
        defaultCurrentView="month"
        defaultCurrentDate={displayTargetDate}
        height={800}
        width={schedulerWidth}
        textExpr="subject"
        startDateExpr="startDate"
        endDateExpr="dueDate"
        appointmentTooltipComponent={renderAppointmentTooltip}
        onAppointmentFormOpening={handleAppointmentFormOpening}
        onCurrentDateChange={handleCurrentDateChange}
        appointmentCollectorRender={renderAppointmentCollector}
      >
        <View type="month" />
      </CrewScheduler>

      {/* タスク登録ダイアログ */}
      <TaskEntryDialog
        isEditMode={false}
        title={t('label.addTaskTitle')}
        onSubmit={handleTaskRegistered}
        isOpen={isProjectDetailTaskEntryDialogOpen}
        onClose={closeProjectDetailTaskEntryDialog}
        projectId={projectId}
        defaultDueDate={dueDate}
      />
    </div>
  )
})
