import { apis } from '../apiBase/apiBase';
export const contractApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        getUpgradePlan: builder.query({
            query: () => {
                return {
                    url: `contract/upgrade-plan`,
                    method: `GET`,
                };
            },
        }),
        createPortalSession: builder.mutation({
            query: () => {
                return {
                    url: `contract/portal-session`,
                    method: `POST`,
                };
            },
        }),
        upgradePlan: builder.mutation({
            query: (params) => {
                return {
                    url: `contract/plan/update`,
                    method: `POST`,
                    body: params,
                };
            },
        }),
        updateBillingCycle: builder.mutation({
            query: (params) => {
                return {
                    url: `contract/billing-cycle/update`,
                    method: `POST`,
                    body: params,
                };
            },
        }),
        updateContact: builder.mutation({
            query: (params) => {
                return {
                    url: `contract/contact/update`,
                    method: `POST`,
                    body: params,
                };
            },
        }),
        updateStorageCapacity: builder.mutation({
            query: (params) => {
                return {
                    url: `contract/storage-capacity/update`,
                    method: `POST`,
                    body: params,
                };
            },
        }),
        getStorageCapacity: builder.query({
            query: () => {
                return {
                    url: `contract/storage-capacity`,
                    method: `GET`,
                };
            },
        }),
        getBillingCycle: builder.query({
            query: () => {
                return {
                    url: `contract/billing-cycle`,
                    method: `GET`,
                };
            },
        }),
        getContact: builder.query({
            query: () => {
                return {
                    url: `contract/contact`,
                    method: `GET`,
                };
            },
        }),
        getContract: builder.query({
            query: () => {
                return {
                    url: `contract`,
                    method: `GET`,
                };
            },
        }),
        getContractPlan: builder.query({
            query: () => {
                return {
                    url: `contract/plan`,
                    method: `GET`,
                };
            },
        }),
        getCollectionMethod: builder.query({
            query: () => {
                return {
                    url: `contract/collection-method`,
                    method: `GET`,
                };
            },
        }),
        createSetupIntent: builder.query({
            query: () => {
                return {
                    url: `contract/setup-intent/create`,
                    method: `GET`,
                };
            },
        }),
        updatePaymentToBankTransfer: builder.mutation({
            query: (params) => {
                return {
                    url: `contract/payment-to-bank-transfer/update`,
                    method: `POST`,
                    body: params,
                };
            },
        }),
        updatePaymentToCard: builder.mutation({
            query: (params) => {
                return {
                    url: `contract/payment-to-card/update`,
                    method: `POST`,
                    body: params,
                };
            },
        }),
        getBillingInformation: builder.query({
            query: () => {
                return {
                    url: `contract/billing-information`,
                    method: `GET`,
                };
            },
        }),
        updateBillingInformation: builder.mutation({
            query: (params) => {
                return {
                    url: `contract/billing-information/update`,
                    method: `POST`,
                    body: params,
                };
            },
        }),
        getPlans: builder.query({
            query: () => {
                return {
                    url: `contract/plans`,
                    method: `GET`,
                };
            },
        }),
        updateExternalUserCount: builder.mutation({
            query: (params) => {
                return {
                    url: `contract/external-user-count/update`,
                    method: `POST`,
                    body: params,
                };
            },
        }),
        getPurchaseUsersCountByUserType: builder.query({
            query: (params) => {
                return {
                    url: `contract/purchase-users-count-by-user-type`,
                    method: `GET`,
                    params: params,
                };
            },
        }),
    }),
    overrideExisting: false,
});
export const { useGetUpgradePlanQuery, useLazyGetUpgradePlanQuery, useCreatePortalSessionMutation, useUpgradePlanMutation, useUpdateBillingCycleMutation, useUpdateStorageCapacityMutation, useGetStorageCapacityQuery, useLazyGetStorageCapacityQuery, useGetBillingCycleQuery, useLazyGetBillingCycleQuery, useGetContactQuery, useLazyGetContactQuery, useGetContractQuery, useLazyGetContractQuery, useGetContractPlanQuery, useCreateSetupIntentQuery, useUpdatePaymentToBankTransferMutation, useGetCollectionMethodQuery, useLazyGetCollectionMethodQuery, useUpdatePaymentToCardMutation, useGetBillingInformationQuery, useLazyGetBillingInformationQuery, useUpdateBillingInformationMutation, useUpdateContactMutation, useGetPlansQuery, useUpdateExternalUserCountMutation, useGetPurchaseUsersCountByUserTypeQuery, useLazyGetPurchaseUsersCountByUserTypeQuery, } = contractApis;
