import { FC, memo } from 'react'
import classNames from 'classnames'
import {
  CrewNavTab,
  CrewNavTabProps,
} from 'components/elements/crewNavTabs/components/crewNavTab'

type Props = {
  tabItems: CrewNavTabProps[]
  // 下線を表示するかどうか
  // デフォルト値でtrueを設定
  visibleBorder?: boolean
}
export const CrewNavTabs: FC<Props> = memo(
  ({ tabItems, visibleBorder = true }) => {
    return (
      <div
        className={classNames(
          'flex flex-row flex-none h-10 items-center',
          visibleBorder && 'border-b crew-border-gray'
        )}
      >
        {/* tabItem分だけ表示する */}
        {tabItems.map((item) => (
          <CrewNavTab
            text={item.text}
            to={item.to}
            onClick={item.onClick}
            // Warning表示回避のkey指定
            key={item.text}
            icon={item.icon}
            badgeCount={item.badgeCount}
            badgeColor={item.badgeColor}
          />
        ))}
      </div>
    )
  }
)
