import { FC, Fragment, memo, useCallback, useState } from 'react'

import { ChatView } from '@crew/enums/app'

import { CrewChatDivider } from 'components/elements/crewChatDivider/crewChatDivider'
import { CrewUnreadLine } from 'components/elements/crewUnreadLine/crewUnreadLine'
import { ChatTimelineMessageListItem } from './components/chatTimelineMessageListItem/chatTimelineMessageListItem'

import {
  DisplayMessageItem,
  MessageItemType,
  useChatTimelineMessageList,
} from './useChatTimelineMessageList'
import { getChatMessageListItemDomId } from 'utils/chat'
import { useValueChangeEffect } from '@crew/hooks'
import { ChatReadStatusDialog } from 'features/chat/components/chatReadStatusDialog/chatReadStatusDialog'
import { useTranslation } from '@crew/modules/i18n'
import { useModal } from 'components/layouts/modal/useModal'

type TopicSummaryProps = {
  item: DisplayMessageItem
}

const TopicSummary: FC<TopicSummaryProps> = (props) => {
  // スレッド返信でない場合は再掲トピックを表示しない
  if (props.item.type !== MessageItemType.ReplyMessage) {
    return null
  }
  // 区切り線が表示されない場合は同じスレッドであるか先頭のアイテムのため、再掲トピックを表示しない
  if (!props.item.showDivideLine) {
    return null
  }

  // 再掲トピックを表示する
  return (
    <ChatTimelineMessageListItem
      key={`${props.item.topicId}_${MessageItemType.TopicSummary}`}
      id={`${props.item.topicId}_${MessageItemType.TopicSummary}`}
      type={MessageItemType.TopicSummary} // 再掲トピックとして表示する
      itemId={props.item.id}
      chatMessageId={props.item.topicId}
      topicId={props.item.topicId}
    />
  )
}

type ChatTimelineMessageListProps = {
  selectedItemId?: string | undefined // 選択中のメッセージ。ハイライト用に使用する
}

export const ChatTimelineMessageList: FC<ChatTimelineMessageListProps> = memo(
  (props) => {
    const {
      displayItems,
      itemsScrollableDivRef,
      messageInView,
      messageOutOfView,
    } = useChatTimelineMessageList()

    const { t } = useTranslation()
    const [
      isOpenedReadStatusDialog,
      openReadStatusDialog,
      closeReadStatusDialog,
    ] = useModal()

    const [selectedReadStatusMessageId, setSelectedReadStatusMessageId] =
      useState<string>('')

    const [selectedMessageId, setSelectedMessageId] = useState<
      string | undefined
    >(undefined)

    useValueChangeEffect(
      () => {
        setSelectedMessageId(props.selectedItemId)
      },
      [props.selectedItemId],
      props.selectedItemId
    )

    // メッセージ全体表示イベントハンドラ
    const handleMessageInView = useCallback(
      (inView: boolean, id: string, messageId: string) => {
        if (inView) {
          messageInView(id, messageId)
        } else {
          messageOutOfView(id)
        }
      },
      [messageInView, messageOutOfView]
    )

    // Open ReadStatusDialog
    const handleClickChatReadStatus = useCallback(
      (chatMessageId: string) => {
        setSelectedReadStatusMessageId(chatMessageId)
        openReadStatusDialog()
      },
      [openReadStatusDialog]
    )

    return (
      <div
        className="grow flex overflow-y-scroll flex-col-reverse"
        ref={itemsScrollableDivRef}
      >
        <div className="grow flex flex-col">
          {displayItems.map((item, index) => {
            const isDeletedTopic = item.deleted
            const hasNextMessage = index < displayItems.length - 1

            // 削除済みのトピックの場合、次のメッセージが違うトピックに関するメッセージかを判定
            let isNextMessageDifferentTopic = false
            if (isDeletedTopic && hasNextMessage) {
              const nextMessage = displayItems[index + 1]
              if (nextMessage.topicId !== item.messageId) {
                isNextMessageDifferentTopic = true
              }
            }

            // 「トピックが削除済み」かつ「次のメッセージがトピックに関わるメッセージではない」場合は表示しない
            if (isDeletedTopic && isNextMessageDifferentTopic) {
              return null
            }

            return (
              <Fragment key={item.id}>
                {item.showDivideLine && <CrewChatDivider />}
                <CrewUnreadLine show={item.showUnreadLine} />

                {/* 再掲トピックの表示制御 */}
                <TopicSummary item={item} />

                <ChatTimelineMessageListItem
                  key={item.id}
                  // スクロール位置制御のためChatViewを含めたidとして指定する
                  id={getChatMessageListItemDomId(
                    item.messageId,
                    ChatView.Timeline
                  )}
                  type={item.type}
                  itemId={item.id}
                  chatMessageId={item.messageId}
                  topicId={item.topicId}
                  container={itemsScrollableDivRef}
                  onMessageInView={handleMessageInView}
                  onAdditionalLoading={item.handleAdditionalLoading}
                  onFirstUnreadMessageViewed={
                    item.handleFirstUnreadMessageViewed
                  }
                  selectedItemId={selectedMessageId}
                  setSelectedItemId={setSelectedMessageId}
                  isFirstUnreadMessage={item.isFirstUnreadMessage}
                  onClickChatReadStatus={handleClickChatReadStatus}
                />
              </Fragment>
            )
          })}
        </div>

        {/* 既読状況 */}
        {selectedReadStatusMessageId && (
          <ChatReadStatusDialog
            isOpen={isOpenedReadStatusDialog}
            title={t('label.readStatus')}
            messageId={selectedReadStatusMessageId}
            onClose={closeReadStatusDialog}
          />
        )}
      </div>
    )
  }
)
