import { useTranslation } from '@crew/modules/i18n'
import { useMemo } from 'react'
import { useDataSource } from './useDataSource'
import {
  FilterScope,
  FileListOrderItems,
  OrganizationRole,
  PaymentMethod,
  ProjectListOrderItems,
  ProjectMemberState,
  ProjectScope,
  TaskListOrderItems,
  TaskPriorities,
  TenantSettingUserStateType,
  TaskStateTypes,
} from 'enums/app'
import { CrewBadgeColorClass, CrewBadgeInvertedColorClass } from 'enums/color'
import { EntityType } from '@crew/enums/domain'
import { camelCase } from 'lodash'
import {
  EntityTypeForTask,
  EntityTypeForFile,
  BookmarkFilters,
  InvitationRoleType,
  TaskDependencyTypes,
  EventScope,
  ContractPlan,
  RecurrenceOption,
  EventType,
  Sort,
} from '@crew/enums/app'

type resourceDataItem = { id: number | string }
type resourceData = resourceDataItem[]

// 汎用
export const useResourceDataSource = <T extends resourceData>(dataList: T) =>
  useDataSource(
    () => ({
      key: 'id',
      load: () => {
        return dataList
      },
      byKey: async (selected) => {
        return Object.values(dataList).find((item) => item.id === selected)
      },
    }),
    [dataList]
  )

// 個々のリソース毎
// ***** タスク優先度 *****
export const useTaskPriorityDataSource = () => {
  const { t } = useTranslation()

  const taskPriorityData = useMemo(() => {
    return Object.values(TaskPriorities).map((item) => {
      return { id: item.value, name: t(item.text) }
    })
  }, [t])

  return useResourceDataSource(taskPriorityData)
}

// 個々のリソース毎
// ***** ブックマーク一覧の検索フィルタ *****
export const useBookmarkFilterDataSource = () => {
  const { t } = useTranslation()

  const bookmarkFilterData = useMemo(() => {
    return Object.values(BookmarkFilters).map((item) => {
      return { id: item.value, name: t(item.text) }
    })
  }, [t])

  return useResourceDataSource(bookmarkFilterData)
}

// ***** バッジの色スタイル *****
export const useBadgeColorClassDataSource = () => {
  const colorClassListData = useMemo(() => {
    return Object.entries(CrewBadgeColorClass).map(([key, value]) => {
      return { id: value, name: value }
    })
  }, [])

  return useResourceDataSource(colorClassListData)
}

// ***** バッジの色スタイル(Inverted) *****
export const useBadgeInvertedColorClassDataSource = () => {
  const colorClassListData = useMemo(() => {
    return Object.entries(CrewBadgeInvertedColorClass).map(([key, value]) => {
      return { id: value, name: value }
    })
  }, [])

  return useResourceDataSource(colorClassListData)
}

// ***** プロジェクトの公開範囲 *****
export const useProjectScopeDataSource = () => {
  const { t } = useTranslation()

  const projectScopeData = useMemo(() => {
    return Object.values(ProjectScope).map((item) => {
      return { id: item.key, name: t(item.name) }
    })
  }, [t])

  return useResourceDataSource(projectScopeData)
}

// ***** フィルタの公開範囲 *****
export const useFilterScopeDataSource = () => {
  const { t } = useTranslation()

  const filterScopeData = useMemo(() => {
    return Object.values(FilterScope).map((item) => {
      return { id: item.key, name: t(item.name) }
    })
  }, [t])

  return useResourceDataSource(filterScopeData)
}

// ***** 並び順の向き *****
export const useSortFilterDataSource = () => {
  const { t } = useTranslation()

  const sortFilterData = useMemo(() => {
    return Object.values(Sort).map((item) => {
      return {
        id: item.value,
        name: t(item.text),
      }
    })
  }, [t])

  return useResourceDataSource(sortFilterData)
}

// ***** 並び順項目（プロジェクト） *****
export const useProjectListOrderItemsDataSource = () => {
  const { t } = useTranslation()

  const orderFilterData = useMemo(() => {
    return Object.values(ProjectListOrderItems).map((item) => {
      return {
        id: item.value,
        name: t(item.text),
      }
    })
  }, [t])

  return useResourceDataSource(orderFilterData)
}

// ***** 並び順項目（タスク） *****
export const useTaskListOrderItemsDataSource = () => {
  const { t } = useTranslation()

  const orderFilterData = useMemo(() => {
    return Object.values(TaskListOrderItems).map((item) => {
      return {
        id: item.value,
        name: t(item.text),
      }
    })
  }, [t])

  return useResourceDataSource(orderFilterData)
}

// ***** 並び順項目（ファイル） *****
export const useFileListOrderItemsDataSource = () => {
  const { t } = useTranslation()

  const orderFilterData = useMemo(() => {
    return Object.values(FileListOrderItems).map((item) => {
      return {
        id: item.value,
        name: t(item.text),
      }
    })
  }, [t])

  return useResourceDataSource(orderFilterData)
}

// ***** エンティティタイプ（全て） *****
export const useAllEntityTypeDataSource = () => {
  const { t } = useTranslation()

  const entityTypeData = useMemo(() => {
    const data = Object.values(EntityType).map((value) => {
      return { id: value, name: t(`general.entityType.${camelCase(value)}`) }
    })

    return data
  }, [t])

  return useResourceDataSource(entityTypeData)
}

// ***** エンティティタイプ（ファイル） *****
export const useFileEntityTypeDataSource = () => {
  const { t } = useTranslation()

  const entityTypeData = useMemo(() => {
    const data = Object.values(EntityTypeForFile).map((value) => {
      return { id: value, name: t(`general.entityType.${camelCase(value)}`) }
    })

    return data
  }, [t])

  return useResourceDataSource(entityTypeData)
}

// ***** エンティティタイプ（タスク） *****
export const useTaskEntityTypeDataSource = () => {
  const { t } = useTranslation()

  const entityTypeData = useMemo(() => {
    const data = Object.values(EntityTypeForTask).map((value) => {
      return { id: value, name: t(`general.entityType.${camelCase(value)}`) }
    })

    return data
  }, [t])

  return useResourceDataSource(entityTypeData)
}

//
// ***** MemberState list search filter *****
export const useTenantSettingUserStateFilterDataSource = () => {
  const { t } = useTranslation()

  const tenantSettingUserStateFilterData = useMemo(() => {
    return Object.values(TenantSettingUserStateType).map((item) => {
      return { id: item.value, name: t(item.text) }
    })
  }, [t])

  return useResourceDataSource(tenantSettingUserStateFilterData)
}

// ***** Organization role *****
export const useOrganizationRoleDataSource = () => {
  const { t } = useTranslation()

  const organizationRoleData = useMemo(() => {
    return Object.values(OrganizationRole).map((item) => {
      return { id: item.key, name: t(item.name) }
    })
  }, [t])

  return useResourceDataSource(organizationRoleData)
}

// ***** ProjectMemberState list search filter *****
export const useProjectMemberStateFilterDataSource = () => {
  const { t } = useTranslation()

  const projectMemberStateFilterData = useMemo(() => {
    return Object.values(ProjectMemberState).map((item) => {
      return { id: item.value, name: t(item.text) }
    })
  }, [t])

  return useResourceDataSource(projectMemberStateFilterData)
}

export const usePaymentMethodDataSource = () => {
  const { t } = useTranslation()

  const paymentMethodData = useMemo(() => {
    return Object.values(PaymentMethod).map((item) => {
      return { id: item.value, name: t(item.text) }
    })
  }, [t])

  return useResourceDataSource(paymentMethodData)
}

// ***** tenant role list *****
export const useTenantRoleDataSource = (contractPlan: ContractPlan) => {
  const { t } = useTranslation()

  // set data source for tenant role dropdown
  const tenantRoleDataSource = useMemo(
    () =>
      Object.values(InvitationRoleType).map((roleType) => {
        return {
          id: roleType.key,
          name: t(roleType.label),
        }
      }),
    [t]
  )

  return useResourceDataSource(tenantRoleDataSource)
}

export const useTaskDependencyTypesDataSource = () => {
  const { t } = useTranslation()

  // set data source for task dependency type dropdown
  const taskDependencyTypesDataSource = useMemo(
    () =>
      Object.values(TaskDependencyTypes).map((taskDependencyType) => {
        return {
          id: taskDependencyType.key,
          name: t(taskDependencyType.name),
          description: t(taskDependencyType.description),
        }
      }),
    [t]
  )

  return useResourceDataSource(taskDependencyTypesDataSource)
}

// ***** タスク状態タイプ *****
export const useTaskStateTypeDataSource = () => {
  const { t } = useTranslation()

  const taskStateTypeData = useMemo(() => {
    return Object.values(TaskStateTypes).map((item) => {
      return { id: item.value, name: t(`label.${item.value}`) }
    })
  }, [t])

  return useResourceDataSource(taskStateTypeData)
}

// ***** event scope data source *****
export const useEventScopeDataSource = () => {
  const { t } = useTranslation()

  const eventScopeData = useMemo(() => {
    return Object.values(EventScope).map((item) => {
      return { id: item.key, name: t(item.name) }
    })
  }, [t])

  return useResourceDataSource(eventScopeData)
}

export const useRecurrenceOptionDataSource = (targetDate: Date) => {
  const { t } = useTranslation()

  const recurrenceOptions = useMemo(() => {
    return Object.values(RecurrenceOption).map((item) => {
      let name = t(item.text)

      switch (item.value) {
        // 毎週○曜日
        case RecurrenceOption.Weekly.value:
          name = t(item.text, {
            weekday: t('format.longWeekday', {
              value: targetDate,
            }),
          })
          break
        // 毎月XX日
        case RecurrenceOption.MonthlyDate.value:
          name = t(item.text, {
            day: t('format.numberOrdinal', {
              value: targetDate.getDate(),
            }),
          })
          break
        // 毎月第X○曜日
        case RecurrenceOption.MonthlyDayOfWeek.value:
          const dayOfMonth = targetDate.getDate()
          const weekOrdinal = Math.ceil(dayOfMonth / 7)

          name = t(item.text, {
            ordinal: t('format.numberOrdinal', {
              value: weekOrdinal,
            }),
            weekday: t('format.longWeekday', {
              value: targetDate,
            }),
          })
          break
        // 毎年XX月XX日
        case RecurrenceOption.Yearly.value:
          name = t(item.text, {
            month: t('format.longMonth', {
              value: targetDate,
            }),
            day: t('format.numberOrdinal', {
              value: targetDate.getDate(),
            }),
          })
          break
      }
      return { id: item.value, name }
    })
  }, [t, targetDate])

  return useResourceDataSource(recurrenceOptions)
}

// ***** event type data source *****
export const useEventTypeDataSource = () => {
  const { t } = useTranslation()

  const eventTypeData = useMemo(() => {
    return Object.values(EventType).map((item) => {
      return { id: item.key, name: t(item.name) }
    })
  }, [t])

  return useResourceDataSource(eventTypeData)
}
