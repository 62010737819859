import { useTranslation } from '@crew/modules/dist/i18n'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewButtonGroup } from 'components/elements/crewButtonGroup/crewButtonGroup'
import { MinutesDisplayMode } from 'enums/app'
import { memo, useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { useEventDetailMeetingMinutesContext } from '../eventDetailMeetingMinutes'
import { setSelectedMinutesDisplayMode } from 'features/event/components/eventDetailPage/states/eventDetailPageSlice'
import {
  useCreateMeetingMinutesAutomaticMutation,
  useLazyDownloadMeetingMinutesDocumentQuery,
} from '@crew/apis/event/eventApis'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useParams } from 'react-router-dom'
import { useToast } from 'hooks/useToast'

type ButtonGroupInfo = {
  key: string
  text: string | undefined
}

export const EventDetailMeetingMinutesToolbar = memo(() => {
  const { eventId } = useParams()

  // この処理が流れる際、eventIdが存在しない場合は正しく描画できないはずなのでエラーを投げる
  // （以降の処理の型制約上もこちらの方が都合が良いはず）
  if (!eventId) {
    throw new Error('eventId is undefined')
  }

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { setIsEditMinutes } = useEventDetailMeetingMinutesContext()
  const [showApiErrors] = useShowApiErrors()
  const toast = useToast()

  // 議事録のダウンロードAPI
  const [lazyDownloadMeetingMinutesDocumentQuery] =
    useLazyDownloadMeetingMinutesDocumentQuery()
  // 議事録生成API
  const [createMeetingMinutesAutomaticMutation] =
    useCreateMeetingMinutesAutomaticMutation()

  // 選択中の表示形式
  const selectedDisplayMode = useAppSelector(
    (state) => state.eventDetailPage.selectedMinutesDisplayMode
  )

  // 表示形式のボタングループ
  const displayModeButtonGroup: ButtonGroupInfo[] = useMemo(() => {
    const items = Object.keys(MinutesDisplayMode).map((key) => {
      const item = MinutesDisplayMode[key as keyof typeof MinutesDisplayMode]
      return { key: item.value, text: t(item.text) }
    })

    return items
  }, [t])

  // 表示形式の切替ボタンクリック時
  const handleDisplayModeItemClick = useCallback(
    (itemData: ButtonGroupInfo) => {
      // 表示形式を更新
      dispatch(setSelectedMinutesDisplayMode(itemData.key))
    },
    [dispatch]
  )

  // Event handle when Edit meeting minutes button is clicked
  const handleEditMeetingMinutesButtonClick = useCallback(() => {
    setIsEditMinutes(true)
  }, [setIsEditMinutes])

  // 議事録生成API実行
  const createMeetingMinutesAutomatic = useCallback(async () => {
    try {
      // 議事録生成API実行
      await createMeetingMinutesAutomaticMutation({
        eventId: eventId,
      }).unwrap()

      toast.success(t('message.meeting.generateMeetingMinutesStarted'))
    } catch (err) {
      showApiErrors(err)
    }
  }, [createMeetingMinutesAutomaticMutation, eventId, showApiErrors, t, toast])

  // 議事録をAI生成ボタン押下時
  const handleGenarateMeetingMinutesButtonClick = useCallback(async () => {
    // 議事録生成API実行
    createMeetingMinutesAutomatic()
  }, [createMeetingMinutesAutomatic])

  // ダウンロードボタン押下時
  const handleDownloadButtonClick = useCallback(async () => {
    try {
      // ダウンロードAPI実行
      const response = await lazyDownloadMeetingMinutesDocumentQuery({
        eventId: eventId,
      }).unwrap()

      // BlobからオブジェクトURLを作成
      const url = window.URL.createObjectURL(response.blob)

      // ダウンロード用のリンクを作成
      const link = document.createElement('a')
      link.href = url
      link.download = response.fileName

      // リンクをクリックしてダウンロード開始
      link.click()
      // メモリ解放
      window.URL.revokeObjectURL(url)
    } catch (error) {
      showApiErrors(error)
    }
  }, [eventId, lazyDownloadMeetingMinutesDocumentQuery, showApiErrors])

  // 議事録を再生成ボタン押下時
  const handleRegenerateMeetingMinutesButtonClick = useCallback(() => {
    // 議事録生成API実行
    createMeetingMinutesAutomatic()
  }, [createMeetingMinutesAutomatic])

  return (
    <div className=" flex flex-col gap-y-5 p-2">
      <div className="flex flex-row justify-between">
        {/* 表示形式の切替ボタン */}
        <CrewButtonGroup
          items={displayModeButtonGroup}
          keyExpr="key"
          textExpr="text"
          stylingMode="text"
          selectedItemKey={selectedDisplayMode}
          onItemClick={handleDisplayModeItemClick}
          size="md"
        />

        <div className="flex gap-x-3 items-center ml-auto">
          {/* 議事録を選択時 */}
          {selectedDisplayMode === MinutesDisplayMode.Minutes.value && (
            <>
              {/* 議事録をAI生成ボタン */}
              <CrewButton
                text={t('action.generateMinutes')}
                onClick={handleGenarateMeetingMinutesButtonClick}
                type="primary"
              />

              {/* ダウンロードボタン */}
              <CrewButton
                text={t('action.download')}
                onClick={handleDownloadButtonClick}
                stylingMode="outlined"
              />

              {/* 編集ボタン */}
              <CrewButton
                text={t('action.edit')}
                onClick={handleEditMeetingMinutesButtonClick}
                stylingMode="outlined"
              />
            </>
          )}

          {/* 文字起こしを選択時 */}
          {selectedDisplayMode === MinutesDisplayMode.Transcription.value && (
            // 議事録を再生成ボタン
            <CrewButton
              text={t('action.regenerateMinutes')}
              onClick={handleRegenerateMeetingMinutesButtonClick}
              type="primary"
            />
          )}
        </div>
      </div>
    </div>
  )
})
