import { FC, memo, useCallback } from 'react'
import { ChatMessage } from '@crew/models/domain'
import {
  AvatarPosition,
  CrewChatMessageItemAvatar,
} from 'components/elements/crewChatMessageItem/components/crewChatMessageItemAvatar/crewChatMessageItemAvatar'
import { CrewChatMessageItemHeader } from 'components/elements/crewChatMessageItem/components/crewChatMessageItemHeader/crewChatMessageItemHeader'
import { CrewNormalChatMessage } from 'components/elements/crewChatMessageItem/components/crewNormalChatMessage/crewNormalChatMessage'
import { CrewReplyAndReactionButton } from '../crewReplyAndReactionButton/crewReplyAndReactionButton'
import { ShowReplyButtonType, ShowReactionType } from '@crew/utils/chat'
import { ReferenceType } from '@crew/enums/domain'
import { RagTargetItem } from './components/ragTargetItem/ragTargetItem'

export type CrewAiAssistantRequestMessageItemProps = {
  message: ChatMessage // 投稿メッセージデータ
  showRelatedLink: boolean // 投稿に関連先リンクを表示するかどうか
  isLargeAvatar: boolean // アバターの表示サイズを大きくするかどうか
  avatarPosition: AvatarPosition // アバターの表示位置
  showReplyButtonType: ShowReplyButtonType // 返信ボタンをどう表示するか
  showRagTarget: boolean // RAG対象を表示するかどうか
  replyCount: number | undefined // 「○件の返信」と表示する場合のみ値を設定し、他はundefined
  onReplyClick: (() => void) | undefined // 返信ボタンを表示しない場合はundefined
  onClick: (() => void) | undefined // メッセージ全体をクリックした際のイベントコールバック
}

/**
 * AIアシスタントへのリクエストメッセージ
 */
export const CrewAiAssistantRequestMessageItem: FC<CrewAiAssistantRequestMessageItemProps> =
  memo((props) => {
    // RAG対象を描画する関数
    const renderRagTargetItems = useCallback(() => {
      const renderItems = props.message.references.map((reference) => {
        // RAG対象のみ表示するため、それ以外はnullを返し何も表示しない
        if (reference.referenceType === ReferenceType.RagTarget) {
          return <RagTargetItem key={reference.id} reference={reference} />
        }
        return null
      })
      return (
        <div className="flex flex-wrap gap-x-1.5 text-sm">{renderItems}</div>
      )
    }, [props.message.references])

    return (
      <div className="flex flex-row gap-2 p-2" onClick={props.onClick}>
        {/* アバター */}
        <CrewChatMessageItemAvatar
          isLargeAvatar={props.isLargeAvatar}
          user={props.message.createdBy}
          position={props.avatarPosition}
          omitUserAvatar={false} // 必ず表示する
        />

        <div className="flex flex-grow flex-col gap-1 min-w-0">
          {/* ユーザー名、投稿日、関連先リンク */}
          <CrewChatMessageItemHeader
            message={props.message}
            isShowChatRoomName={props.showRelatedLink}
          />
          <div>
            <CrewNormalChatMessage
              messageText={props.message.text}
              messagePlainText={props.message.plainText}
              isTopicSummary={false}
            />
          </div>

          {/* RAG対象 */}
          {props.showRagTarget && renderRagTargetItems()}

          {/* 返信ボタンとリアクションボタンを条件に応じて表示する */}
          {props.showReplyButtonType !== ShowReplyButtonType.None && (
            <CrewReplyAndReactionButton
              message={props.message}
              onReplyClick={props.onReplyClick}
              showReplyButtonType={props.showReplyButtonType}
              showReactionsAndReactionButton={ShowReactionType.None} // リアクションボタンは表示しない
              replyCount={props.replyCount}
            />
          )}
        </div>
      </div>
    )
  })
