import { useCallback, useMemo, useState } from 'react'

import { useChatMessage } from '@crew/states'

import { useAppDispatch, useAppSelector } from 'states/hooks'

export const useAiAssistantThreadMessageListItem = (chatMessageId: string) => {
  const dispatch = useAppDispatch()
  const { message, isError } = useChatMessage(
    chatMessageId,
    dispatch,
    useAppSelector
  )

  // 編集モードかどうか
  const [isEditMode, setIsEditMode] = useState(false)

  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)

  // 自分が投稿したメッセージ判定（自分のメッセージのみ編集可能とするフラグ）
  const isMyMessage = useMemo(
    () => message?.createdById === loggedInUser?.id,
    [loggedInUser?.id, message?.createdById]
  )

  // 「編集」押下時に編集モードを切り替える
  const toggleEditMode = useCallback(() => {
    setIsEditMode(!isEditMode)
  }, [isEditMode])

  // 編集モードの登録 / キャンセル時に編集モードをoffにする
  const cancelEditMode = useCallback(() => {
    setIsEditMode(false)
  }, [])

  return {
    message,
    isError,
    isMyMessage,
    isEditMode,
    toggleEditMode,
    cancelEditMode,
  }
}
