import { useGetEventRecordingTranscribesQuery } from '@crew/apis/eventRecordingTranscribe/eventRecordingTranscribeApis'
import { convertSecondsToHHMMSS, convertSecondsToMMSS } from '@crew/utils'
import { CrewUserAvatar } from 'components/elements/crewUserAvatar/crewUserAvatar'
import { memo, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import AccountCircle from '~icons/material-symbols/account-circle'
import MaterialSymbolsPlayArrow from '~icons/material-symbols/play-arrow'
import MaterialSymbolsAdd from '~icons/material-symbols/add'
import BaselineDelete from '~icons/ic/baseline-delete'
import MdiPencil from '~icons/mdi/pencil'
import { useTranslation } from '@crew/modules/i18n'
import { JsonDateFormat } from '@crew/enums/system'
import dayjs from '@crew/modules'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'

export const EventDetailMeetingMinutesPanelTranscriptionList = memo(() => {
  const { eventId } = useParams()
  const { t } = useTranslation()

  // この処理が流れる際、eventIdが存在しない場合は正しく描画できないはずなのでエラーを投げる
  // （以降の処理の型制約上もこちらの方が都合が良いはず）
  if (!eventId) {
    throw new Error('eventId is undefined')
  }

  // 文字起こしリスト取得API
  const { data: getEventRecordingTranscribesResult } =
    useGetEventRecordingTranscribesQuery({
      eventId: eventId,
    })

  // ユーザー設定からデフォルトのユーザープロファイル地域を取得
  const defaultUserProfileRegion = useUserSetting(
    SettingKeyType.UserProfileRegion,
    Region.Japan.value
  )

  // 録画ID毎の発言時刻の最大時間を取得
  // [録画ID, 発言時刻の最大時間(秒)]形式
  const maxSpeechTimeMap =
    getEventRecordingTranscribesResult?.eventRecordings.reduce(
      (acc, eventRecording) => {
        const maxSpeechTime = eventRecording.eventRecordingTranscribes.reduce(
          (max, transcribe) => Math.max(max, transcribe.speechTimestamp || 0),
          0
        )
        acc[eventRecording.id] = maxSpeechTime
        return acc
      },
      {} as Record<string, number>
    )

  // 発言時刻のrender
  const renderSpeechTime = useCallback(
    (eventRecordingId: string, second: number) => {
      const hour = 60 * 60

      // 指定された録画IDの発言時刻の最大時間を取得
      const maxSpeechTime = maxSpeechTimeMap?.[eventRecordingId]

      // 発言時刻の最大時間が取得できない場合は何も描画しない
      if (!maxSpeechTime) {
        return null
      }

      // 発言時間の最大値によって表示形式を決める
      //   →1時間以上の発言がある　場合：[hh:mm:ss]形式で表示
      //   →1時間未満の発言しかない場合：[m:ss]形式で表示
      let speechTime
      if (maxSpeechTime > hour) {
        // 1時間以上の発言がある場合は、[h:mm:ss]形式で表示
        speechTime = convertSecondsToHHMMSS(second)
      } else {
        // 1時間未満の発言しかない場合は、[m:ss]形式で表示
        speechTime = convertSecondsToMMSS(second)
      }

      return speechTime
    },
    [maxSpeechTimeMap]
  )

  // 録画日時のrender
  const renderMeetingRecordingTime = useCallback(
    (startDatetime: string, endDatetime: string | null) => {
      // 終了日時がnullの場合は開始日時のみ表示
      // →録画終了済みで終了日時がnullのケースはありえないがデータ構造上はnull許容のため
      if (!endDatetime) {
        return t('format.datetime', {
          value: startDatetime,
          timeZone: defaultUserProfileRegion,
        })
      }

      // 開始日と終了日が同じか
      const isSameDay =
        dayjs(startDatetime).format(JsonDateFormat.YYYYMMDD) ===
        dayjs(endDatetime).format(JsonDateFormat.YYYYMMDD)

      return (
        <>
          <time>
            {t('format.datetime', {
              value: startDatetime,
              timeZone: defaultUserProfileRegion,
            })}
          </time>
          <time> - </time>
          <time>
            {t(isSameDay ? 'format.shorttime' : 'format.datetime', {
              value: endDatetime,
              timeZone: defaultUserProfileRegion,
            })}
          </time>
        </>
      )
    },
    [defaultUserProfileRegion, t]
  )

  // 再生ボタンクリック時
  const handlePlayButtonClick = useCallback(() => {
    // TODO：下記タスクで実装
    // https://break-tmc.atlassian.net/browse/CREW-18837
    console.log('再生')
  }, [])

  // 編集ボタンクリック時
  const handleEditButtonClick = useCallback(() => {
    // TODO：下記タスクで実装
    // https://break-tmc.atlassian.net/browse/CREW-18838
    console.log('編集')
  }, [])

  // 追加ボタンクリック時
  const handleAddButtonClick = useCallback(() => {
    // TODO：下記タスクで実装
    // https://break-tmc.atlassian.net/browse/CREW-18838
    console.log('追加')
  }, [])

  // 削除ボタンクリック時
  const handleDeleteButtonClick = useCallback(() => {
    // TODO：下記タスクで実装
    // https://break-tmc.atlassian.net/browse/CREW-18838
    console.log('削除')
  }, [])

  // 文字起こしリストが取得できない場合は何も表示しない
  if (
    !getEventRecordingTranscribesResult ||
    getEventRecordingTranscribesResult.eventRecordings.length === 0
  ) {
    return null
  }

  return (
    <div className="flex flex-col gap-y-3">
      {getEventRecordingTranscribesResult.eventRecordings.map(
        (eventRecording) => (
          <div className="flex flex-col gap-y-1">
            {/* 録画日時 */}
            <div className="font-bold py-1 px-1.5 crew-bg-gray-1 rounded">
              {renderMeetingRecordingTime(
                eventRecording.startDatetime,
                eventRecording.endDatetime
              )}
            </div>

            {eventRecording.eventRecordingTranscribes.map(
              (eventRecordingTranscribe) => (
                <div className="flex flex-row gap-x-1 hover:bg-crew-gray-1-light hover:dark:bg-crew-gray-4-dark">
                  {/* アバター */}
                  <div>
                    {eventRecordingTranscribe.speaker ? (
                      <div
                        className="m-[4px]" // AccountCircleのsvgに余白が発生する関係で表示位置がずれるため、マージンを入れて調整
                      >
                        {/* 発言者IDが登録されている場合：ユーザーのアバターを表示 */}
                        <CrewUserAvatar
                          userId={eventRecordingTranscribe.speaker.id}
                          displayName={
                            eventRecordingTranscribe.speaker.displayName
                          }
                          cacheValue={
                            eventRecordingTranscribe.speaker.id +
                            eventRecordingTranscribe.speaker.version
                          }
                        />
                      </div>
                    ) : (
                      // 発言者が登録されていない or 発言者名のみ登録されている場合：デフォルトのアバターを表示
                      <AccountCircle
                        width={48}
                        height={48}
                        className={'crew-text-gray-5'}
                      />
                    )}
                  </div>

                  <div className="flex flex-col justify-center gap-y-0.5">
                    <div className="flex flex-row gap-x-2 text-sm">
                      {/* 発言者 */}
                      <div className="crew-text-gray-4 font-bold">
                        {eventRecordingTranscribe.speaker?.displayName ??
                          eventRecordingTranscribe.speakerName ??
                          t('label.unknown')}
                      </div>

                      {/* 発言時間 */}
                      <div className="crew-text-gray-5">
                        {eventRecordingTranscribe.speechTimestamp &&
                          renderSpeechTime(
                            eventRecordingTranscribe.eventRecordingId,
                            eventRecordingTranscribe.speechTimestamp
                          )}
                      </div>
                    </div>

                    {/* 発言内容 */}
                    <div>{eventRecordingTranscribe.speechContent}</div>
                  </div>

                  <div className="flex flex-row gap-x-2 ml-auto items-center">
                    {/* 再生ボタン */}
                    <div
                      className="cursor-pointer hover:opacity-70"
                      onClick={handlePlayButtonClick}
                    >
                      <MaterialSymbolsPlayArrow width={24} height={24} />
                    </div>

                    {/* 編集ボタン */}
                    <div
                      className="cursor-pointer hover:opacity-70"
                      onClick={handleEditButtonClick}
                    >
                      <MdiPencil width={24} height={24} />
                    </div>

                    {/* 追加ボタン */}
                    <div
                      className="cursor-pointer hover:opacity-70"
                      onClick={handleAddButtonClick}
                    >
                      <MaterialSymbolsAdd width={24} height={24} />
                    </div>

                    {/* 削除ボタン */}
                    <div
                      className="cursor-pointer hover:opacity-70"
                      onClick={handleDeleteButtonClick}
                    >
                      <BaselineDelete width={24} height={24} />
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        )
      )}
    </div>
  )
})
