import { IncomingControlMessageType, PubSubEventType, WebSocketIncomingMessageType, } from '@crew/enums/domain';
export const IsIncomingMessage = (v) => {
    if (typeof v !== 'object' || v === null)
        return false;
    const o = v;
    if (typeof o.type !== 'string' ||
        !Object.values(WebSocketIncomingMessageType).includes(o.type)) {
        return false;
    }
    if (typeof o.payload === 'undefined') {
        return false;
    }
    return true;
};
export const isIncomingAsyncMessage = (message) => IsIncomingMessage(message) &&
    Object.values(PubSubEventType).includes(message.type);
export const isIncomingControlMessage = (message) => IsIncomingMessage(message) &&
    Object.values(IncomingControlMessageType).includes(message.type);
