import { useSendAuthenticationCodeMutation } from '@crew/apis/setting/settingApis'
import { NationPhoneCode } from '@crew/enums/app'
import { useForm } from 'react-hook-form'
import { useCallback } from 'react'
import { trimSpace } from '@crew/utils'

export type FormValues = {
  nation: string
  phoneNumber: string
}
const formInitialValues: FormValues = {
  nation: NationPhoneCode.Japan.value,
  phoneNumber: '',
}

export const useTwoFactorSMS = () => {
  // react-hook-formの各種データを取得
  const { control, reset, setError, handleSubmit } = useForm<FormValues>({
    criteriaMode: 'all',
    defaultValues: formInitialValues,
  })

  // Get function for send authentication code
  const [
    sendAuthenticationCodeMutation,
    { isLoading: isLoadingSendAuthenticationCode },
  ] = useSendAuthenticationCodeMutation()

  // Send authentication code process
  const sendAuthCode = useCallback(
    async (data: FormValues) => {
      // send verification code to email address
      await sendAuthenticationCodeMutation({
        destination: trimSpace(data.phoneNumber),
        regionCode: data.nation,
      }).unwrap()
    },
    [sendAuthenticationCodeMutation]
  )

  return {
    control,
    reset,
    setError,
    handleSubmit,
    sendAuthCode,
    isLoadingSendAuthenticationCode,
  }
}
