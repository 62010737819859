import { GetProjectForDirectChannelRequest } from '@crew/apis/project/models/getProjectForDirectChannel/request'
import { useGetProjectForDirectChannelQuery } from '@crew/apis/project/projectApis'
import { EntityType } from '@crew/enums/domain'
import { useTranslation } from '@crew/modules/i18n'
import { usePresenceState } from '@crew/states'
import { generateImageAvatarUrl } from '@crew/utils/avatar'
import { skipToken } from '@reduxjs/toolkit/query'
import {
  CrewAvatar,
  CrewAvatarSize,
} from 'components/elements/crewAvatar/crewAvatar'
import { CrewPresenceStateIconSize } from 'components/elements/crewPresenceStateIcon/crewPresenceStateIcon'
import {
  CrewUserPresence,
  UserPresenceState,
} from 'components/elements/crewUserPresence/crewUserPresence'
import qs from 'qs'
import { FC, memo, useCallback, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { getParamAsString } from 'utils'

export const SearchInDirectChannelHeadItem: FC = memo(() => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const params = qs.parse(searchParams.toString())

  const projectId = useMemo(
    () => getParamAsString('projectId', params),
    [params]
  )

  // ダイレクトチャンネル用のデータを取得する
  const getProjectForDirectChannel:
    | GetProjectForDirectChannelRequest
    | undefined = Boolean(projectId)
    ? {
        projectId: projectId ?? '',
      }
    : undefined

  const { data: getDirectChannelResult } = useGetProjectForDirectChannelQuery(
    getProjectForDirectChannel ?? skipToken
  )

  // ユーザー在席状態
  const [userPresenceState, setUserPresenceState] =
    useState<UserPresenceState>()

  // 在席状態IDから在席状態を取得
  const presenceState = usePresenceState(userPresenceState?.presenceStateId)

  // handle presence state change
  const handleUserPresenceStateChange = useCallback(
    (userPresence: UserPresenceState) => {
      setUserPresenceState(userPresence)
    },
    []
  )

  // text presence state
  const textPresenceState = useMemo(() => {
    // 在席状態名（取得できない場合は「不明」を表示）
    const presenceStateName = presenceState?.name ?? t('label.unknown')

    return !userPresenceState?.presenceStateMessage
      ? presenceStateName // メッセージがない場合：在席状態のみ表示
      : `${presenceStateName} / ${userPresenceState.presenceStateMessage}` // メッセージがある場合：「在席状態 / メッセージ」の形式で表示
  }, [presenceState?.name, t, userPresenceState?.presenceStateMessage])

  // ダイレクトチャンネルの相手の情報
  const partner = getDirectChannelResult?.directChannel
    ? getDirectChannelResult.directChannel.user2
    : null

  if (!partner) {
    return null
  }

  return (
    <>
      <div className="relative">
        <CrewAvatar
          displayName={partner.displayName}
          size={CrewAvatarSize.xs}
          imageURL={generateImageAvatarUrl(EntityType.DirectChannel, projectId)}
          cacheValue={partner.id + partner.version}
          showPresenceState
          textPresenceState={textPresenceState}
        />
        <div className="absolute bottom-0 right-0">
          <CrewUserPresence
            userId={partner.id}
            size={CrewPresenceStateIconSize.sm}
            onChange={handleUserPresenceStateChange}
          />
        </div>
      </div>
      <p title={partner.displayName}>{partner.displayName}</p>
    </>
  )
})
