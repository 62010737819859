import { FC, memo } from 'react'
import { ChatMessageReference } from '@crew/models/domain'
import { MessageType, ReferenceType } from '@crew/enums/domain'
import { useTranslation } from '@crew/modules/i18n'
import { useGetLookupUsersQuery } from '@crew/apis/lookup/lookupApis'
import classNames from 'classnames'

export type CrewProjectMemberNotificationChatMessageProps = {
  messageType: MessageType
  chatMessageReferences: ChatMessageReference[]
  truncateMessage: boolean
}

export const CrewProjectMemberNotificationChatMessage: FC<CrewProjectMemberNotificationChatMessageProps> =
  memo((props) => {
    // 現時点では、chat_message_referencesのデータをもとに最新のレコードを取得する仕様
    // このためプロジェクト更新を行った後で「プロジェクト登録時」の自動投稿メッセージには、更新後の件名と説明が表示される（2022/11/10プロダクトオーナー確認済み）

    const { t } = useTranslation()

    const { data: getAttendeesResult } = useGetLookupUsersQuery({
      keyword: undefined,
      userId: props.chatMessageReferences
        .filter(
          (data) => data.referenceType === ReferenceType.NotificationSource
        )
        .map((reference) => reference.entityRecordId),
    })

    // t()に渡す文字列をmessageTypeから生成する
    // messageTypeと同一名のkeyのものは、自動投稿メッセージの帯に使用されているため、本文用に別に定義されているものを使用する

    let translationMessageType: string

    switch (props.messageType) {
      case MessageType.ProjectMemberJoined:
        translationMessageType = 'memberToJoined'
        break
      case MessageType.ProjectMemberRequested:
        translationMessageType = 'memberToRequested'
        break
      case MessageType.ProjectMemberLeft:
        translationMessageType = 'memberToLeft'
        break
      default:
        translationMessageType = ''
    }
    // displayNameの後には、カンマと半角スペースを付与する
    // TODO: 英語のときも最後のユーザーにandが付かない
    // a, b, c and dと表示されるようにしたい
    // https://break-tmc.atlassian.net/browse/CREW-8424
    const displayNames = getAttendeesResult?.users
      .map((user) => t('message.chat.userName', { name: user.displayName }))
      .join(', ')

    const translationMessage = t(`message.chat.${translationMessageType}`, {
      names: displayNames,
    })

    return (
      <div className={classNames(props.truncateMessage && 'truncate')}>
        {translationMessage}
      </div>
    )
  })
