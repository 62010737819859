import { FC, memo, RefObject, useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'
import { MessageType } from '@crew/enums/domain'
import { useAiAssistantThreadListMessageListItem } from './useAiAssistantThreadListMessageListItem'
import { AvatarPosition } from 'components/elements/crewChatMessageItem/components/crewChatMessageItemAvatar/crewChatMessageItemAvatar'
import { CrewChatMessageActionMenu } from 'components/elements/crewChatMessageItem/components/crewChatMessageActionMenu/crewChatMessageActionMenu'
import { CrewEditMessageItem } from 'components/elements/crewMessageItem/components/crewEditMessageItem/crewEditMessageItem'
import { ShowReplyButtonType } from '@crew/utils/chat'
import { useInView } from 'react-intersection-observer'
import { ADDITIONAL_LOADING_TRIGGER_INTERSECTION_ROOT_MARGIN } from 'configs/constants'
import { useHasHover } from 'hooks/useHasHover'
import { ChatMessageEditorType } from 'components/elements/crewChatMessageEditor/crewChatMessageEditor'
import { ChatMessage } from '@crew/models/domain'
import { CrewAiAssistantRequestMessageItem } from 'components/elements/crewMessageItem/components/crewAiAssistantRequestMessageItem/crewAiAssistantRequestMessageItem'
import { ContextMenuItems } from 'enums/app'

export type AiAssistantThreadListMessageListItemProps = {
  id: string
  chatMessageId: string // チャットメッセージID
  replyCount: number // 返信件数。返信ボタンに表示する
  container: RefObject<HTMLDivElement>
  onAdditionalLoading: (() => void) | undefined // 追加読み込みの関数
  setSelectedItemId?: (messageId: string) => void // 選択されたメッセージを更新するために使用する
  selectedItemId?: string // 選択されたメッセージのハイライト用に使用する
  onMessageEdit: (message: ChatMessage) => void // メッセージ編集時のコールバック
}

/**
 * AIアシスタント - スレッドリスト形式のメッセージアイテム
 */
export const AiAssistantThreadListMessageListItem: FC<AiAssistantThreadListMessageListItemProps> =
  memo((props) => {
    const {
      message,
      isError,
      isMyMessage,
      isEditMode,
      toggleEditMode,
      cancelEditMode,
      openTargetThread,
    } = useAiAssistantThreadListMessageListItem(props.chatMessageId)

    const hasHover = useHasHover()
    const [isHover, setIsHover] = useState<boolean>(false)

    // ------------------------------ イベントハンドラ ------------------------------

    // 返信ボタンクリックイベントハンドラ
    const handleReplyButtonClick = useCallback(() => {
      // 返信ボタンをクリックされた投稿に紐づくスレッドを表示する
      openTargetThread()

      // ハイライト表示するため、選択されたメッセージを更新する
      if (props.setSelectedItemId && message) {
        props.setSelectedItemId(message.id)
      }
    }, [message, openTargetThread, props])

    // マウスホバー時にアクションメニューを表示する
    const handleMouseEnter = useCallback(() => {
      setIsHover(true)
    }, [setIsHover])

    // マウスが離れたらアクションメニューを非表示にする
    const handleMouseLeave = useCallback(() => {
      setIsHover(false)
    }, [setIsHover])

    // 「編集」押下時に編集モードを切り替える
    const handleEditButtonClick = useCallback(() => {
      toggleEditMode()
    }, [toggleEditMode])

    // 編集モードの登録 / キャンセル時に編集モードをoffにする
    const handleEditModeCancel = useCallback(() => {
      cancelEditMode()
    }, [cancelEditMode])

    // メッセージアイテムクリック時のイベントハンドラ
    const handleMessageItemClick = useCallback(() => {
      // hover機能がないデバイスに限り、メッセージ自体のクリックを返信ボタンのクリックに読み換える
      if (!hasHover) {
        handleReplyButtonClick()
      }
    }, [handleReplyButtonClick, hasHover])

    // アイテムが表示領域に近づいたら追加ロードイベントハンドラを呼ぶ
    const { ref: loadingTriggerRef } = useInView({
      rootMargin: ADDITIONAL_LOADING_TRIGGER_INTERSECTION_ROOT_MARGIN, // 表示領域に「近づいた」をトリガーにするため、領域の外側にマージンを付与する
      root: props.container.current,
      onChange: (inView) => {
        // このイベントは、アイテムと判定領域の重なり方の割合がthreshold(デフォルト: 0)を越えた場合に発火する。
        // 近づいた場合と離れた場合のどちらも発火するが、inViewの値で方向を判定することができる。
        //   近づいた場合: true 離れた場合: false
        if (inView) {
          // アイテムが近づいてきた場合、追加読込を行う
          props.onAdditionalLoading?.()
        }
      },
    })

    // 選択中メッセージが自メッセージかどうかを判定
    const isSelected =
      props.selectedItemId !== undefined && message?.id === props.selectedItemId

    // メニューを表示するかどうか
    const visibleActionMenu = hasHover
      ? isHover // ホバー有効ならホバー状態であること
      : isSelected // ホバー無効なら選択状態であること

    // メッセージの表示コンポーネントを返す
    const renderMessageItem = useCallback(() => {
      // メッセージがない場合は何も表示しない
      if (!message) {
        return null
      }

      // 編集モードの場合は編集用コンポーネントを表示
      if (isEditMode) {
        return (
          <CrewEditMessageItem
            message={message}
            onEditModeCancel={handleEditModeCancel}
            canUploadFile={false} // ファイルアップロードは不可
            isLargeAvatar={true} // アバターはすべてサイズを大きくする
            avatarPosition={AvatarPosition.Center} // アバターはすべて中央寄せにする
            editorType={ChatMessageEditorType.AiAssistant} // AIアシスタント用のエディタを使用する
            onMessageEdit={props.onMessageEdit} // メッセージ編集時のコールバック
          />
        )
      }

      // messageTypeに応じて表示コンポーネントを出し分ける
      if (message.messageType === MessageType.AiAssistantRequest) {
        // AIアシスタントリクエストメッセージ
        return (
          <CrewAiAssistantRequestMessageItem
            message={message}
            showRelatedLink={true} // 関連先リンクを表示する
            onReplyClick={handleReplyButtonClick}
            isLargeAvatar={true} // アバターはすべてサイズを大きくする
            avatarPosition={AvatarPosition.Center} // アバターはすべて中央寄せにする
            showReplyButtonType={ShowReplyButtonType.ShowWithCount} // 「○件の返信」ボタンを表示する
            showRagTarget={true} // RAG対象を表示する
            replyCount={props.replyCount}
            onClick={undefined}
          />
        )
      } else {
        return null
      }
    }, [
      handleEditModeCancel,
      handleReplyButtonClick,
      isEditMode,
      message,
      props.onMessageEdit,
      props.replyCount,
    ])

    // アクションメニューの表示設定
    const enabledActionMenus = useMemo(() => {
      const actionMenus: ContextMenuItems[] = []

      // 自分自身のメッセージの場合は「編集」「削除」を表示する
      if (isMyMessage) {
        actionMenus.push(ContextMenuItems.Edit)
        actionMenus.push(ContextMenuItems.Delete)
      }
      return actionMenus
    }, [isMyMessage])

    // エラーが発生している場合は何も表示しない
    if (isError) {
      return null
    }

    // 表示に必要なデータがない場合は何も表示しない
    // 当初「読み込み中」を表示しようとしていたが、メッセージごとにその表示が出てしまうと見栄えが悪かったので表示しないようにした
    if (!message) {
      return null
    }

    return (
      <div
        id={props.id}
        className={classNames('flex flex-col crew-border-gray relative', {
          'bg-crew-blue-1-light dark:bg-crew-blue-3-dark': isSelected, // 対象メッセージが選択されている場合ハイライトする
          'bg-crew-gray-100 dark:bg-crew-gray-800': !isSelected && isHover, // ホバー表示、ただし選択されていたらそちらを優先する
        })}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleMessageItemClick}
      >
        {props.onAdditionalLoading && (
          <div
            ref={loadingTriggerRef}
            className="absolute left-0 top-0 right-0 bottom-0 -z-10"
          />
        )}

        {/* メッセージ */}
        {renderMessageItem()}

        {/* ホバーメニュー */}
        {/* メッセージ枠右上にホバーメニューを表示するためabsoluteを使用 */}
        <div className="absolute top-0 right-0">
          <CrewChatMessageActionMenu
            visible={visibleActionMenu}
            isBookmarkedMessage={
              message.bookmarks ? message.bookmarks.length > 0 : false
            }
            bookmarkMessages={message.bookmarks}
            chatMessageId={props.chatMessageId}
            chatMessageVersion={message.version}
            enabledActionMenus={enabledActionMenus}
            onEditButtonClick={handleEditButtonClick}
          />
        </div>
      </div>
    )
  })
