import { memo, FC, useCallback } from 'react'
import { ChatMessage } from '@crew/models/domain'
import { AiAssistantThreadListMessageList } from './components/aiAssistantThreadListMessageList/aiAssistantThreadListMessageList'
import {
  ChatMessageEditorType,
  CrewChatMessageEditor,
} from 'components/elements/crewChatMessageEditor/crewChatMessageEditor'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { useAiAssistantService } from '@crew/states'

/**
 * AIアシスタント - スレッドリスト表示
 */
export const AiAssistantThreadList: FC = memo(() => {
  // Sliceの操作を行うためのServiceを取得
  const dispatch = useAppDispatch()
  const aiAssistantService = useAiAssistantService(dispatch)

  // 処理対象のAIアシスタントチャンネルのチャットルーム情報をReduxから取得
  const aiAssistantChatRoom = useAppSelector(
    (state) => state.message.chat.aiAssistantChannel.chatRoom
  )

  // チャット投稿時のイベントハンドラ
  const handleChatMessageSubmit = useCallback(
    (message: ChatMessage) => {
      // 右パネルにスレッド表示をするため、トピックを設定する
      aiAssistantService.setAiAssistantChannelCurrentChatThread({
        topicId: message.id,
        chatRoomId: message.chatRoomId,
        rootEntityType: message.chatRoom.rootEntityType,
        rootEntityRecordId: message.chatRoom.rootEntityRecordId,
        entityType: message.chatRoom.entityType,
        entityRecordId: message.chatRoom.entityRecordId,
      })
    },
    [aiAssistantService]
  )

  // 表示に必要なデータが指定されていない場合、表示することができないので、何も表示しない
  if (!aiAssistantChatRoom) {
    return null
  }

  return (
    <>
      {/* メッセージリスト */}
      <AiAssistantThreadListMessageList
        key={aiAssistantChatRoom.id}
        onMessageEdit={handleChatMessageSubmit}
      />

      {/* チャット投稿コンポーネント */}
      <CrewChatMessageEditor
        isEditMode={false}
        chatRoomId={aiAssistantChatRoom.id}
        threadRootMessageId={undefined} // スレッドリスト形式の際はスレッド返信ではないのでundefinedを渡す
        rootEntityType={aiAssistantChatRoom.rootEntityType}
        rootEntityRecordId={aiAssistantChatRoom.rootEntityRecordId}
        canUploadFile={false}
        editorType={ChatMessageEditorType.AiAssistant} // AIアシスタント用のエディタを使用する
        onSubmit={handleChatMessageSubmit}
      />
    </>
  )
})
