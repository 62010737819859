import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { Dispatch, SetStateAction, memo, useState } from 'react'
import { EventDetailMeetingMinutesToolbar } from './eventDetailMeetingMinutesToolbar/eventDetailMeetingMinutesToolbar'
import { EventDetailMeetingMinutesPanel } from './eventDetailMeetingMinutesPanel/eventDetailMeetingMinutesPanel'
import { createUninitializedContext } from '@crew/utils/context'

/** Context用の型 */
type EventDetailMeetingMinutesContextType = {
  // 議事録を編集中か
  isEditMinutes: boolean
  // 議事録の編集状態を更新する関数
  setIsEditMinutes: Dispatch<SetStateAction<boolean>>
}

/** Context */
const {
  context: eventDetailMeetingMinutesContext,
  useInitializedContext: useEventDetailMeetingMinutesContext,
} = createUninitializedContext<EventDetailMeetingMinutesContextType>()

export { useEventDetailMeetingMinutesContext }

export const EventDetailMeetingMinutes = memo(() => {
  // 議事録を編集中か
  const [isEditMinutes, setIsEditMinutes] = useState(false)

  return (
    <eventDetailMeetingMinutesContext.Provider
      value={{
        isEditMinutes,
        setIsEditMinutes,
      }}
    >
      <CrewErrorBoundary>
        <EventDetailMeetingMinutesToolbar />
      </CrewErrorBoundary>

      <CrewErrorBoundary>
        <div className="h-full grow min-h-0 p-2">
          <EventDetailMeetingMinutesPanel />
        </div>
      </CrewErrorBoundary>
    </eventDetailMeetingMinutesContext.Provider>
  )
})
