import { FC, memo, useCallback, useEffect, useRef, useState } from 'react'
import { $isDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode'
import {
  $createParagraphNode,
  $getNodeByKey,
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  $isTextNode,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_NORMAL,
  ElementFormatType,
  FORMAT_TEXT_COMMAND,
  KEY_MODIFIER_COMMAND,
  LexicalEditor,
  NodeKey,
  SELECTION_CHANGE_COMMAND,
} from 'lexical'
import { ListNode } from '@lexical/list'
import {
  $getSelectionStyleValueForProperty,
  $patchStyleText,
  $isParentElementRTL,
} from '@lexical/selection'
import { $isTableNode, $isTableSelection } from '@lexical/table'
import {
  $findMatchingParent,
  mergeRegister,
  $getNearestNodeOfType,
  $getNearestBlockElementAncestorOrThrow,
} from '@lexical/utils'
import {
  // $createHeadingNode,
  // $createQuoteNode,
  $isHeadingNode,
  // HeadingTagType,
  $isQuoteNode,
} from '@lexical/rich-text'
import {
  // $createCodeNode,
  $isCodeNode,
  getLanguageFriendlyName,
} from '@lexical/code'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  $isListNode,
  // INSERT_CHECK_LIST_COMMAND,
  // INSERT_ORDERED_LIST_COMMAND,
  // INSERT_UNORDERED_LIST_COMMAND,
  // ListNode,
  // REMOVE_LIST_COMMAND,
} from '@lexical/list'

import { ToolbarColorPicker } from '../../components/toolbarColorPicker'
import { HTML_EDITOR_DEFAULT_FONT_SIZES } from 'configs/constants'
import { INSERT_EMOJI_COMMAND } from '../emojiPlugin'
import { ToolbarButton } from '../../components/toolbarButton'
import { useCrewEmojiPicker } from 'components/elements/crewEmojiPicker/crewEmojiPickerContext'
import { BlockFormatDropDown } from '../../components/toolbarStyleChangeDropDown'
import { DropDownItem, ToolbarDropDown } from '../../components/toolbarDropDown'
import { sanitizeUrl } from '../../utils/lexical'
import { getSelectedNode } from '../../utils/getSelectedNode'
import { ToolbarIndentAndAlignChangeDropDown } from '../../components/toolbarIndentAndAlignChangeDropDown'
import { ToolbarInsertSpecialEditorNodeDropdown } from '../../components/toolbarInsertSpecialEditorNodeDropdown'
import 'prismjs/components/prism-go'
import {
  CODE_LANGUAGE_FRIENDLY_NAME_MAP,
  CODE_LANGUAGE_MAP,
} from '../../constants/codeHighlightConstants'
import { ToolbarFontSizeChangeDropDown } from '../../components/toolbarFontSizeChangeDropDown'
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link'
import AttachFile from '~icons/material-symbols/attach-file'
import EmoticonHappyOutline from '~icons/mdi/emoticon-happy-outline'
import FormatBold from '~icons/material-symbols/format-bold'
import FormatColorFill from '~icons/material-symbols/format-color-fill'
import FormatColorText from '~icons/material-symbols/format-color-text'
import FormatItalic from '~icons/material-symbols/format-italic'
import FormatUnderlined from '~icons/material-symbols/format-underlined'
import BaselineDelete from '~icons/ic/baseline-delete'
import Code from '~icons/material-symbols/code'
import Link from '~icons/material-symbols/link'
import { INSERT_LINK_COMMAND } from '../linkEditorPlugin/linkEditorPlugin'
import { shouldModifyFileNameForUpload } from 'utils'
import dayjs from '@crew/modules'
import { SimpleDateFormat } from '@crew/enums/system'

export const blockTypeToBlockName = {
  bulletList: 'Bulleted List',
  checkList: 'Check List',
  codeBlock: 'Code Block',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  numberList: 'Numbered List',
  paragraph: 'Normal',
  quote: 'Quote',
}

export const rootTypeToRootName = {
  root: 'Root',
  table: 'Table',
}

function getCodeLanguageOptions(): [string, string][] {
  const options: [string, string][] = []

  for (const [lang, friendlyName] of Object.entries(
    CODE_LANGUAGE_FRIENDLY_NAME_MAP
  )) {
    options.push([lang, friendlyName])
  }

  return options
}

const CODE_LANGUAGE_OPTIONS = getCodeLanguageOptions()

export function dropDownActiveClass(active: boolean) {
  if (active) {
    return 'active dropdown-item-active'
  } else {
    return ''
  }
}

/**
 * リスト挿入ボタン
 * @param param0
 * @returns
 */
type InsertLinkButtonProps = {
  editor: LexicalEditor
  disabled: boolean
  isLink: boolean
}

const InsertLinkButton: FC<InsertLinkButtonProps> = ({
  editor,
  disabled,
  isLink,
}) => {
  const handleLinkButtonClick = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(INSERT_LINK_COMMAND, '')
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
    }
  }, [editor, isLink])

  return (
    <ToolbarButton
      onHandleButtonClick={handleLinkButtonClick}
      icon={<Link width={24} height={24} />}
      disabled={disabled}
    />
  )
}

/**
 * 絵文字挿入ボタン
 * @param param0
 * @returns
 */
type EmojiButtonProps = {
  editor: LexicalEditor
  disabled?: boolean
}

const EmojiButton: FC<EmojiButtonProps> = ({ editor, disabled = false }) => {
  const { open: openEmojiPicker } = useCrewEmojiPicker()
  const emojiButtonRef = useRef<HTMLButtonElement>(null)

  const handleChooseEmoji = useCallback(
    (shortName: string) => {
      // 絵文字挿入コマンドを発行することで、現在のカーソル位置に絵文字を挿入する
      editor.dispatchCommand(INSERT_EMOJI_COMMAND, shortName)
    },
    [editor]
  )

  //Open emoji picker
  const handleOpenEmojiPicker = useCallback((): void => {
    if (!emojiButtonRef?.current) {
      return
    }
    openEmojiPicker(emojiButtonRef.current, (emoji: string) =>
      handleChooseEmoji(emoji)
    )
  }, [handleChooseEmoji, openEmojiPicker])

  return (
    <ToolbarButton
      onHandleButtonClick={handleOpenEmojiPicker}
      disabled={disabled}
      icon={<EmoticonHappyOutline width={24} height={24} />}
      ref={emojiButtonRef}
    />
  )
}

/**
 * フォントカラー変更ボタン
 */
type FontAndBgColorPickerProps = {
  onChange: (value: string, skipHistoryStack: boolean) => void
  disabled: boolean
  buttonAriaLabel: string
  icon: React.ReactNode
  color: string
  title: string
}

const FontAndBgColorPicker: FC<FontAndBgColorPickerProps> = ({
  onChange,
  disabled = false,
  buttonAriaLabel,
  icon,
  color,
  title,
}) => {
  return (
    <ToolbarColorPicker
      disabled={disabled}
      onChange={onChange}
      buttonAriaLabel={buttonAriaLabel}
      icon={icon}
      color={color}
      title={title}
    />
  )
}

/**
 * 太文字, 斜体, 下線, 取り消し線
 */
type FontStyleButtonProps = {
  editor: LexicalEditor
  disabled: boolean
  fontStyle: 'italic' | 'underline' | 'bold' | 'strikethrough' | 'code'
  icon: React.ReactNode
  isApplied: boolean
}
const FontStyleButton: FC<FontStyleButtonProps> = ({
  editor,
  disabled = false,
  fontStyle,
  icon,
  isApplied,
}) => {
  const handleButtonClick = useCallback(() => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, fontStyle)
  }, [editor, fontStyle])

  return (
    <ToolbarButton
      onHandleButtonClick={handleButtonClick}
      icon={icon}
      disabled={disabled}
      isApplied={isApplied}
    />
  )
}

/**
 * フォーマットクリアボタン
 */

type FormatClearButtonProps = {
  editor: LexicalEditor
  disabled: boolean
}

const FormatClearButton: FC<FormatClearButtonProps> = ({
  editor,
  disabled = false,
}) => {
  const handleButtonClick = useCallback(() => {
    editor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection) || $isTableSelection(selection)) {
        const anchor = selection.anchor
        const focus = selection.focus
        const nodes = selection.getNodes()
        const extractedNodes = selection.extract()

        if (anchor.key === focus.key && anchor.offset === focus.offset) {
          return
        }

        nodes.forEach((node, idx) => {
          // We split the first and last node by the selection
          // So that we don't format unselected text inside those nodes
          if ($isTextNode(node)) {
            // Use a separate variable to ensure TS does not lose the refinement
            let textNode = node
            if (idx === 0 && anchor.offset !== 0) {
              textNode = textNode.splitText(anchor.offset)[1] || textNode
            }
            if (idx === nodes.length - 1) {
              textNode = textNode.splitText(focus.offset)[0] || textNode
            }
            /**
             * If the selected text has one format applied
             * selecting a portion of the text, could
             * clear the format to the wrong portion of the text.
             *
             * The cleared text is based on the length of the selected text.
             */
            // We need this in case the selected text only has one format
            const extractedTextNode = extractedNodes[0]
            if (nodes.length === 1 && $isTextNode(extractedTextNode)) {
              textNode = extractedTextNode
            }

            if (textNode.__style !== '') {
              textNode.setStyle('')
            }
            if (textNode.__format !== 0) {
              textNode.setFormat(0)
              $getNearestBlockElementAncestorOrThrow(textNode).setFormat('')
            }
            node = textNode
          } else if ($isHeadingNode(node) || $isQuoteNode(node)) {
            node.replace($createParagraphNode(), true)
          } else if ($isDecoratorBlockNode(node)) {
            node.setFormat('')
          }
        })
      }
    })
  }, [editor])

  return (
    <ToolbarButton
      onHandleButtonClick={handleButtonClick}
      icon={<BaselineDelete width={24} height={24} />}
      disabled={disabled}
    />
  )
}

/**
 * ファイル名にタイムスタンプを付与する
 * @param fileName
 * @returns
 */
const insertTimestampToFileName = (fileName: string): string => {
  const timestamp = dayjs().format(SimpleDateFormat.YYYYMMDDHHmmss)

  // 拡張子を判定する。ファイル名に含まれる場合はそれを使い、無い場合は空文字とする
  const splittedFileName = fileName.split('.')
  const ext = splittedFileName.length >= 2 ? `.${splittedFileName.pop()}` : ''

  return `${splittedFileName.join('.')}_${timestamp}${ext}`
}

/**
 * Attachment Button
 * @param param0
 * @returns
 */
type AttachmentButtonProps = {
  editor: LexicalEditor
  disabled?: boolean
  uploadFile?: (file: File) => void
}

type TargetInputFileProps = {
  files: FileList
}

const AttachmentButton: FC<AttachmentButtonProps> = ({
  editor,
  disabled = false,
  uploadFile,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  // 添付ファイルボタンクリック
  const handleAttachFileButtonClick = useCallback(() => {
    // iPad上のブラウザで使用した時、エディタにカーソルが当たってる状態（キーボードが表示されている状態）でファイル選択を行うと
    // ファイル選択ダイアログにフォーカスが当たってしまうことで、キーボードが閉じた後にファイル選択のメニューが表示されて
    // メニューの表示位置がボタンの近くにならない問題があるため、回避策としてsetTimeoutで少し遅延させることとした。
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.value = '' // 前回の選択をクリア
        inputRef.current.click()
      }
    }, 100)
  }, [])

  // ファイル選択時の処理
  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target as TargetInputFileProps | null
      if (target && uploadFile) {
        for (let i = 0; i < target.files.length; i++) {
          const fileItem = target.files.item(i)
          if (fileItem === null) {
            continue
          }

          const modFileItem = shouldModifyFileNameForUpload(fileItem)
            ? // 「今」カメラで撮影されたファイルの場合、ファイル名にタイムスタンプを付与する
              // iOSでファイル名が固定値になってしまい更新と扱われてしまう件の対策
              new File([fileItem], insertTimestampToFileName(fileItem.name), {
                type: fileItem.type,
                endings: 'transparent',
                lastModified: fileItem.lastModified,
              })
            : fileItem

          uploadFile(modFileItem)
        }
      }
    },
    [uploadFile]
  )

  return (
    <>
      {/* 動的に作成したinputの場合、iPad上のブラウザからカメラ起動で画像を添付するとChangeイベントが発火しないことがあるため
          静的に作成したinputを再利用することで、この問題を回避する
       */}
      <input
        ref={inputRef}
        type="file"
        multiple
        className="hidden"
        onChange={handleFileChange}
      />
      <ToolbarButton
        onHandleButtonClick={handleAttachFileButtonClick}
        disabled={disabled}
        icon={<AttachFile width={24} height={24} />}
      />
    </>
  )
}

/**
 * LexicalEditorにツールバーを表示するプラグイン
 */
export const ToolbarPluginTop: FC = memo(() => {
  const [editor] = useLexicalComposerContext()
  const [activeEditor, setActiveEditor] = useState(editor)
  const [blockType, setBlockType] =
    useState<keyof typeof blockTypeToBlockName>('paragraph')
  const [rootType, setRootType] =
    useState<keyof typeof rootTypeToRootName>('root')
  const [selectedElementKey, setSelectedElementKey] = useState<NodeKey | null>(
    null
  )
  const [fontSize, setFontSize] = useState<string>(
    HTML_EDITOR_DEFAULT_FONT_SIZES
  )
  const [elementFormat, setElementFormat] = useState<ElementFormatType>('left')
  const [isLink, setIsLink] = useState(false)
  const [fontColor, setFontColor] = useState<string>('#000')
  const [bgColor, setBgColor] = useState<string>('#fff')
  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)
  // const [isStrikethrough, setIsStrikethrough] = useState(false)
  const [isCode, setIsCode] = useState(false)
  // const [modal, showModal] = useModal()
  const [codeLanguage, setCodeLanguage] = useState<string>('')
  const [isEditable, setIsEditable] = useState(() => editor.isEditable())

  const [isRTL, setIsRTL] = useState(false)

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode()
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent()
              return parent !== null && $isRootOrShadowRoot(parent)
            })

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow()
      }

      const elementKey = element.getKey()
      const elementDOM = activeEditor.getElementByKey(elementKey)

      // Update text format
      setIsBold(selection.hasFormat('bold'))
      setIsItalic(selection.hasFormat('italic'))
      setIsUnderline(selection.hasFormat('underline'))
      // setIsStrikethrough(selection.hasFormat('strikethrough'))
      setIsCode(selection.hasFormat('code'))
      setIsRTL($isParentElementRTL(selection))

      // Update links
      const node = getSelectedNode(selection)
      const parent = node.getParent()
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true)
      } else {
        setIsLink(false)
      }

      const tableNode = $findMatchingParent(node, $isTableNode)
      if ($isTableNode(tableNode)) {
        setRootType('table')
      } else {
        setRootType('root')
      }

      if (elementDOM !== null) {
        setSelectedElementKey(elementKey)
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode
          )
          const type = parentList
            ? parentList.getListType()
            : element.getListType()
          setBlockType(`${type}List`)
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType()
          if ($isCodeNode(element)) {
            const language =
              element.getLanguage() as keyof typeof CODE_LANGUAGE_MAP
            setCodeLanguage(
              language ? CODE_LANGUAGE_MAP[language] || language : ''
            )
            setBlockType('codeBlock')
            return
          }
          if (type in blockTypeToBlockName) {
            setBlockType(type as keyof typeof blockTypeToBlockName)
          }
        }
      }
      // Handle buttons
      setFontColor(
        $getSelectionStyleValueForProperty(selection, 'color', '#000')
      )
      setBgColor(
        $getSelectionStyleValueForProperty(
          selection,
          'background-color',
          '#fff'
        )
      )
      let matchingParent
      if ($isLinkNode(parent)) {
        // If node is a link, we need to fetch the parent paragraph node to set format
        matchingParent = $findMatchingParent(
          node,
          (parentNode) => $isElementNode(parentNode) && !parentNode.isInline()
        )
      }

      // If matchingParent is a valid node, pass it's format type
      setElementFormat(
        $isElementNode(matchingParent)
          ? matchingParent.getFormatType()
          : $isElementNode(node)
          ? node.getFormatType()
          : parent?.getFormatType() || 'left'
      )
    }
    if ($isRangeSelection(selection) || $isTableSelection(selection)) {
      setFontSize(
        $getSelectionStyleValueForProperty(
          selection,
          'font-size',
          HTML_EDITOR_DEFAULT_FONT_SIZES
        )
      )
    }
  }, [activeEditor])

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        setActiveEditor(newEditor)
        $updateToolbar()
        return false
      },
      COMMAND_PRIORITY_CRITICAL
    )
  }, [editor, $updateToolbar])

  useEffect(() => {
    activeEditor.getEditorState().read(() => {
      $updateToolbar()
    })
  }, [activeEditor, $updateToolbar])

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener((editable) => {
        setIsEditable(editable)
      }),
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar()
        })
      })
    )
  }, [activeEditor, editor, $updateToolbar])

  // Ctrl + K
  useEffect(() => {
    return activeEditor.registerCommand(
      KEY_MODIFIER_COMMAND,
      (payload) => {
        const event: KeyboardEvent = payload
        const { code, ctrlKey, metaKey } = event

        if (code === 'KeyK' && (ctrlKey || metaKey)) {
          event.preventDefault()
          let url: string | null
          if (!isLink) {
            url = sanitizeUrl('https://')
          } else {
            url = null
          }
          return activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, url)
        }
        return false
      },
      COMMAND_PRIORITY_NORMAL
    )
  }, [activeEditor, isLink])

  const applyStyleText = useCallback(
    (styles: Record<string, string>, skipHistoryStack?: boolean) => {
      activeEditor.update(
        () => {
          const selection = $getSelection()
          if (selection !== null) {
            $patchStyleText(selection, styles)
          }
        },
        skipHistoryStack ? { tag: 'historic' } : {}
      )
    },
    [activeEditor]
  )

  const onFontColorSelect = useCallback(
    (value: string, skipHistoryStack: boolean) => {
      applyStyleText({ color: value }, skipHistoryStack)
    },
    [applyStyleText]
  )

  const onBgColorSelect = useCallback(
    (value: string, skipHistoryStack: boolean) => {
      applyStyleText({ 'background-color': value }, skipHistoryStack)
    },
    [applyStyleText]
  )

  const onCodeLanguageSelect = useCallback(
    (value: string) => {
      activeEditor.update(() => {
        if (selectedElementKey !== null) {
          const node = $getNodeByKey(selectedElementKey)
          if ($isCodeNode(node)) {
            node.setLanguage(value)
          }
        }
      })
    },
    [activeEditor, selectedElementKey]
  )

  return (
    <div className="shrink-0 flex flex-row gap-2 h-8 items-center">
      <div className="w-[calc(100%-2rem)]">
        <div className="flex flex-row gap-1 items-center">
          <div className="h-8">
            {blockType in blockTypeToBlockName && activeEditor === editor && (
              <BlockFormatDropDown
                disabled={!isEditable}
                blockType={blockType}
                rootType={rootType}
                editor={activeEditor}
              />
            )}
          </div>

          {blockType === 'codeBlock' ? (
            <div className="h-8">
              <ToolbarDropDown
                disabled={!isEditable}
                // buttonClassName="toolbar-item code-language"
                buttonLabel={getLanguageFriendlyName(codeLanguage)}
                buttonAriaLabel="Select language"
                showDropDownIcon={true}
              >
                {CODE_LANGUAGE_OPTIONS.map(([value, name]) => {
                  return (
                    <DropDownItem
                      className={`item ${dropDownActiveClass(
                        value === codeLanguage
                      )}`}
                      onClick={() => onCodeLanguageSelect(value)}
                      key={value}
                    >
                      <span className="text">{name}</span>
                    </DropDownItem>
                  )
                })}
              </ToolbarDropDown>
            </div>
          ) : (
            <>
              {/* change editor font size dropdown */}
              <div className="h-8">
                <ToolbarFontSizeChangeDropDown
                  disabled={!isEditable}
                  editor={activeEditor}
                  value={fontSize}
                  isRTL={isRTL}
                />
              </div>

              {/* select format color fill button */}
              <div className="h-8">
                <FontAndBgColorPicker
                  disabled={!isEditable}
                  onChange={onBgColorSelect}
                  buttonAriaLabel="Formatting bg color"
                  icon={<FormatColorFill width={24} height={24} />}
                  color={bgColor}
                  title="bg color"
                />
              </div>

              {/* select format color text button */}
              <div className="h-8">
                <FontAndBgColorPicker
                  disabled={!isEditable}
                  onChange={onFontColorSelect}
                  buttonAriaLabel="Formatting text color"
                  icon={<FormatColorText width={24} height={24} />}
                  color={fontColor}
                  title="text color"
                />
              </div>

              {/* font style bold button */}
              <div className="h-8">
                <FontStyleButton
                  editor={activeEditor}
                  disabled={!isEditable}
                  fontStyle="bold"
                  icon={<FormatBold width={24} height={24} />}
                  isApplied={isBold}
                />
              </div>

              {/* font style italic button */}
              <div className="h-8">
                <FontStyleButton
                  editor={activeEditor}
                  disabled={!isEditable}
                  fontStyle="italic"
                  icon={<FormatItalic width={24} height={24} />}
                  isApplied={isItalic}
                />
              </div>

              {/* font style underline button */}
              <div className="h-8">
                <FontStyleButton
                  editor={activeEditor}
                  disabled={!isEditable}
                  fontStyle="underline"
                  icon={<FormatUnderlined width={24} height={24} />}
                  isApplied={isUnderline}
                />
              </div>

              {/* insert link button*/}
              <div className="h-8">
                <InsertLinkButton
                  editor={activeEditor}
                  disabled={!isEditable}
                  isLink={isLink}
                />
              </div>

              <div className="h-8">
                <FontStyleButton
                  editor={activeEditor}
                  disabled={!isEditable}
                  fontStyle="code"
                  icon={<Code width={24} height={24} />}
                  isApplied={isCode}
                />
              </div>

              {/* font style strikethrough button */}
              {/* TODO: 後ほどスプリント9.0内でツールバーの構造を変更予定。CREW-9501で不要なツールをコメントアウトにより除去する（表示時の参考にするため）
          <div className="h-8">
            <FontStyleButton
              editor={activeEditor}
              disabled={!isEditable}
              fontStyle="strikethrough"
              icon={mdiFormatStrikethrough}
              isApplied={isStrikethrough}
            />
          </div>
          */}

              {/* determine how to align and insert indent */}
              <div className="h-8">
                <ToolbarIndentAndAlignChangeDropDown
                  editor={activeEditor}
                  value={elementFormat}
                  isRTL={isRTL}
                  disabled={!isEditable}
                />
              </div>

              {/* format clear button */}
              <div className="h-8">
                <FormatClearButton
                  editor={activeEditor}
                  disabled={!isEditable}
                />
              </div>

              <div className="h-8">
                <ToolbarInsertSpecialEditorNodeDropdown
                  disabled={!isEditable}
                  editor={activeEditor}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
})

type ToolbarPluginBottomProps = {
  uploadFile?: (file: File) => void
  fileUploaderDisabled: boolean | undefined
  emojiPickerDisabled: boolean | undefined
}

/**
 * LexicalEditorにツールバーを表示するプラグイン
 * List plugin show in bottom of html editor
 */
export const ToolbarPluginBottom: FC<ToolbarPluginBottomProps> = memo(
  ({ uploadFile, fileUploaderDisabled, emojiPickerDisabled }) => {
    const [editor] = useLexicalComposerContext()

    return (
      <div className="shrink-0 flex flex-row gap-1 h-8">
        {!emojiPickerDisabled && <EmojiButton editor={editor} />}
        {!fileUploaderDisabled && (
          <AttachmentButton editor={editor} uploadFile={uploadFile} />
        )}
      </div>
    )
  }
)
