import { FC, memo, RefObject, useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'
import { MessageType } from '@crew/enums/domain'
import { useAiAssistantThreadMessageListItem } from './useAiAssistantThreadMessageListItem'
import { AvatarPosition } from 'components/elements/crewChatMessageItem/components/crewChatMessageItemAvatar/crewChatMessageItemAvatar'
import { CrewChatMessageActionMenu } from 'components/elements/crewChatMessageItem/components/crewChatMessageActionMenu/crewChatMessageActionMenu'
import { CrewEditMessageItem } from 'components/elements/crewMessageItem/components/crewEditMessageItem/crewEditMessageItem'
import { ShowReplyButtonType } from '@crew/utils/chat'
import { useInView } from 'react-intersection-observer'
import { ADDITIONAL_LOADING_TRIGGER_INTERSECTION_ROOT_MARGIN } from 'configs/constants'
import { useHasHover } from 'hooks/useHasHover'
import { ChatMessageEditorType } from 'components/elements/crewChatMessageEditor/crewChatMessageEditor'
import { CrewAiAssistantResponseMessageItem } from 'components/elements/crewMessageItem/components/crewAiAssistantResponseMessageItem/crewAiAssistantResponseMessageItem'
import { CrewAiAssistantRequestMessageItem } from 'components/elements/crewMessageItem/components/crewAiAssistantRequestMessageItem/crewAiAssistantRequestMessageItem'
import { ContextMenuItems } from 'enums/app'

export type AiAssistantThreadMessageListItemProps = {
  id: string
  itemId: string
  chatMessageId: string // チャットメッセージID
  container?: RefObject<HTMLDivElement> // トピックの場合は指定不要
  isSelected: boolean // このアイテムが選択状態かどうか
  selectedMessageId?: string // 選択されたメッセージのハイライト用に使用する

  // トピックの場合は指定不要。トピックでなく且つ追加読込が無い場合は「undefinedを格納した変数」が渡されるため、undefinedも受け取れる型として明示しておく
  onAdditionalLoading?: (() => void) | undefined
  onClick: (id: string) => void
}

/**
 * AIアシスタント - スレッド形式のメッセージアイテム
 */
export const AiAssistantThreadMessageListItem: FC<AiAssistantThreadMessageListItemProps> =
  memo((props) => {
    const {
      message,
      isError,
      isMyMessage,
      isEditMode,
      toggleEditMode,
      cancelEditMode,
    } = useAiAssistantThreadMessageListItem(props.chatMessageId)

    const hasHover = useHasHover()
    const [isHover, setIsHover] = useState<boolean>(false)

    const isActive = hasHover
      ? isHover // ホバー有効ならホバー状態であること
      : props.isSelected // ホバー無効なら選択状態であること

    // 選択中メッセージが自メッセージかどうかを判定
    const isHighlighted =
      props.selectedMessageId && message?.id === props.selectedMessageId

    // ------------------------------ イベントハンドラ ------------------------------

    // マウスホバー時にアクションメニューを表示する
    const handleMouseEnter = useCallback(() => {
      setIsHover(true)
    }, [setIsHover])

    // マウスが離れたらアクションメニューを非表示にする
    const handleMouseLeave = useCallback(() => {
      setIsHover(false)
    }, [setIsHover])

    // 「編集」押下時に編集モードを切り替える
    const handleEditButtonClick = useCallback(() => {
      toggleEditMode()
    }, [toggleEditMode])

    // 編集モードの登録 / キャンセル時に編集モードをoffにする
    const handleEditModeCancel = useCallback(() => {
      cancelEditMode()
    }, [cancelEditMode])

    // アイテムが表示領域に近づいたら追加ロードイベントハンドラを呼ぶ
    const { ref: loadingTriggerRef } = useInView({
      rootMargin: ADDITIONAL_LOADING_TRIGGER_INTERSECTION_ROOT_MARGIN, // 表示領域に「近づいた」をトリガーにするため、領域の外側にマージンを付与する
      root: props.container?.current,
      onChange: (inView) => {
        // このイベントは、アイテムと判定領域の重なり方の割合がthreshold(デフォルト: 0)を越えた場合に発火する。
        // 近づいた場合と離れた場合のどちらも発火するが、inViewの値で方向を判定することができる。
        //   近づいた場合: true 離れた場合: false
        if (inView) {
          // アイテムが近づいてきた場合、追加読込を行う
          props.onAdditionalLoading?.()
        }
      },
    })

    // メッセージの表示コンポーネントを返す
    const renderMessageItem = useCallback(() => {
      // メッセージがない場合は何も表示しない
      if (!message) return null

      // 編集モードの場合は編集用コンポーネントを表示
      if (isEditMode) {
        return (
          <CrewEditMessageItem
            message={message}
            onEditModeCancel={handleEditModeCancel}
            canUploadFile={false} // ファイルアップロードは不可
            isLargeAvatar={true} // アバターはすべてサイズを大きくする
            avatarPosition={AvatarPosition.Center} // アバターはすべて中央寄せにする
            editorType={ChatMessageEditorType.AiAssistant} // AIアシスタント用のエディタを使用する
          />
        )
      }

      // messageTypeに応じて表示コンポーネントを出し分ける
      if (message.messageType === MessageType.AiAssistantRequest) {
        // AIアシスタントリクエストメッセージ
        return (
          <CrewAiAssistantRequestMessageItem
            message={message}
            showRelatedLink={false} // スレッド表示では投稿に関連先リンクを表示しない
            isLargeAvatar={true} // アバターはすべてサイズを大きくする
            avatarPosition={AvatarPosition.Center} // アバターはすべて中央寄せにする
            showReplyButtonType={ShowReplyButtonType.None} // 返信ボタンは表示しない
            showRagTarget={message.parentChatMessageId === null ? false : true} // トピックにはRAG対象を表示しないので、parentChatMessageIdに応じて制御する
            replyCount={undefined} // 返信ボタンを表示しないのでundefinedを指定する
            onReplyClick={undefined} // 返信ボタンを表示しないのでundefinedを指定する
            onClick={undefined}
          />
        )
      } else if (message.messageType === MessageType.AiAssistantResponse) {
        // AIアシスタントの応答メッセージ
        return <CrewAiAssistantResponseMessageItem message={message} />
      } else {
        return null
      }
    }, [handleEditModeCancel, isEditMode, message])

    // メッセージアイテムクリック時のイベントハンドラ
    const handleMessageItemClick = useCallback(() => {
      props.onClick(props.itemId)
    }, [props])

    // アクションメニューの表示設定
    const enabledActionMenus = useMemo(() => {
      const actionMenus: ContextMenuItems[] = []

      // 自分自身のメッセージの場合は「編集」「削除」を表示する
      if (isMyMessage) {
        actionMenus.push(ContextMenuItems.Edit)
        actionMenus.push(ContextMenuItems.Delete)
      }
      return actionMenus
    }, [isMyMessage])

    // エラーが発生している場合は何も表示しない
    if (isError) {
      return null
    }

    // 表示に必要なデータがない場合は何も表示しない
    // 当初「読み込み中」を表示しようとしていたが、メッセージごとにその表示が出てしまうと見栄えが悪かったので表示しないようにした
    if (!message) {
      return null
    }

    return (
      <div
        id={props.id}
        className={classNames('flex flex-col crew-border-gray relative', {
          'bg-crew-blue-1-light dark:bg-crew-blue-3-dark': isHighlighted,
          'bg-crew-gray-100 dark:bg-crew-gray-800': !isHighlighted && isActive,
        })}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleMessageItemClick}
      >
        {props.onAdditionalLoading && (
          <div
            ref={loadingTriggerRef}
            className="absolute left-0 top-0 right-0 bottom-0 -z-10"
          />
        )}

        {/* メッセージ */}
        {renderMessageItem()}

        {/* ホバーメニュー */}
        {/* メッセージ枠右上にホバーメニューを表示するためabsoluteを使用 */}
        <div className="absolute top-0 right-0">
          <CrewChatMessageActionMenu
            visible={isActive}
            isBookmarkedMessage={
              message.bookmarks ? message.bookmarks.length > 0 : false
            }
            bookmarkMessages={message.bookmarks}
            chatMessageId={props.chatMessageId}
            chatMessageVersion={message.version}
            enabledActionMenus={enabledActionMenus}
            onEditButtonClick={handleEditButtonClick}
          />
        </div>
      </div>
    )
  })
