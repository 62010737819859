import { FC, memo, useMemo } from 'react'
import { ChatMessage } from '@crew/models/domain'
import {
  AvatarPosition,
  CrewChatMessageItemAvatar,
} from 'components/elements/crewChatMessageItem/components/crewChatMessageItemAvatar/crewChatMessageItemAvatar'
import { CrewChatMessageItemHeader } from 'components/elements/crewChatMessageItem/components/crewChatMessageItemHeader/crewChatMessageItemHeader'
import { CrewNormalChatMessage } from 'components/elements/crewChatMessageItem/components/crewNormalChatMessage/crewNormalChatMessage'
import { CrewAttachments } from 'components/elements/crewAttachments/crewAttachments'
import { CrewAttachmentThumbnails } from 'components/elements//crewAttachmentThumbnails/crewAttachmentThumbnails'
import { CrewReplyAndReactionButton } from '../crewReplyAndReactionButton/crewReplyAndReactionButton'
import {
  ShowReplyButtonType,
  ShowReactionType,
  getChatMessageFileWithoutImageFile,
  getChatMessageImageFile,
} from '@crew/utils/chat'
import { useTranslation } from '@crew/modules/dist/i18n'

export type CrewNormalMessageItemProps = {
  message: ChatMessage // 投稿メッセージデータ
  showRelatedLink: boolean // 投稿に関連先リンクを表示するかどうか
  highlightKeyword: string // 検索時にハイライトするキーワード
  // Permissionに関する実装は、今後hook化などを行うため現時点ではこの実装を許容する（CREW-10789）
  showDeleteAttachmentButton: boolean // 添付ファイルを削除できるかどうか（ボタン表示制御に使用する）
  canDownloadAttachment: boolean // 添付ファイルをダウンロードできるかどうか（ボタン表示制御に使用する）
  isLargeAvatar: boolean // アバターの表示サイズを大きくするかどうか
  avatarPosition: AvatarPosition // アバターの表示位置
  showReplyButtonType: ShowReplyButtonType // 返信ボタンをどう表示するか
  showReactionsAndReactionButton: ShowReactionType // リアクションとリアクションボタンを表示するかどうか
  replyCount: number | undefined // 「○件の返信」と表示する場合のみ値を設定し、他はundefined
  customHeaderContent?: React.ReactNode | JSX.Element // ヘッダー部分をカスタマイズする場合は指定する
  onReplyClick: (() => void) | undefined // 返信ボタンを表示しない場合はundefined
  onAttachmentFileDeleted: (() => Promise<void>) | undefined // 添付ファイル削除時のコールバック
  onClick: (() => void) | undefined // メッセージ全体をクリックした際のイベントコールバック
}

/**
 * 通常投稿メッセージ
 */
export const CrewNormalMessageItem: FC<CrewNormalMessageItemProps> = memo(
  (props) => {
    const { t } = useTranslation()

    // チャットメッセージから画像以外の添付ファイルを抽出する
    const files = useMemo(() => {
      return getChatMessageFileWithoutImageFile(props.message)
    }, [props.message])

    // チャットメッセージから添付画像ファイルを抽出する
    const imageFiles = useMemo(() => {
      return getChatMessageImageFile(props.message)
    }, [props.message])

    return (
      <div className="flex flex-row gap-2 p-2" onClick={props.onClick}>
        {props.message.deleted ? (
          // 削除済みのトピックの場合：「削除済み」と表示する
          <div className="flex flex-row gap-2 items-center">
            {/* アバター */}
            <CrewChatMessageItemAvatar
              isLargeAvatar={true} // 削除フラグがtrueのものは必ずトピックであるため、アバターのサイズは大きくする
              position={props.avatarPosition}
              omitUserAvatar={false} // 必ず表示する
              isAnonymous={true} // 匿名ユーザーとして表示
              deletedMessage={props.message.deleted}
            />

            <div className="flex flex-grow flex-col gap-1 min-w-0">
              <div className="crew-text-gray-5">
                {t('label.deletedMessage')}
              </div>

              {/* 返信ボタンを条件に応じて表示する */}
              {(props.showReplyButtonType !== ShowReplyButtonType.None ||
                props.showReactionsAndReactionButton !==
                  ShowReactionType.None) && (
                <CrewReplyAndReactionButton
                  message={props.message}
                  onReplyClick={props.onReplyClick}
                  showReplyButtonType={props.showReplyButtonType}
                  showReactionsAndReactionButton={ShowReactionType.None} // 削除済みのメッセージにはリアクションボタンは表示しない
                  replyCount={props.replyCount}
                />
              )}
            </div>
          </div>
        ) : (
          // 削除されていないメッセージの場合：通常の表示を行う
          <>
            {/* アバター */}
            <CrewChatMessageItemAvatar
              isLargeAvatar={props.isLargeAvatar} // 返信かどうかに応じてアバターのサイズを変更する
              user={props.message.createdBy}
              position={props.avatarPosition}
              omitUserAvatar={false} // 必ず表示する
            />

            <div className="flex flex-grow flex-col gap-1 min-w-0">
              {/* カスタムヘッダー */}
              {props.customHeaderContent && props.customHeaderContent}

              {/* ユーザー名、投稿日、関連先リンク */}
              <CrewChatMessageItemHeader
                message={props.message}
                isShowChatRoomName={props.showRelatedLink}
              />
              <div>
                <CrewNormalChatMessage
                  messageText={props.message.text}
                  messagePlainText={props.message.plainText}
                  isTopicSummary={false}
                  highlightKeyword={props.highlightKeyword}
                />
                <>
                  {/* 添付画像ファイル */}
                  <CrewAttachmentThumbnails
                    attachmentThumbnails={imageFiles}
                    showDeleteButton={props.showDeleteAttachmentButton}
                    onFileDeleted={props.onAttachmentFileDeleted}
                    canDownload={props.canDownloadAttachment}
                  />
                  {/* 添付ファイル */}
                  <CrewAttachments
                    attachments={files}
                    showDeleteButton={props.showDeleteAttachmentButton}
                    onFileDeleted={props.onAttachmentFileDeleted}
                    canDownload={props.canDownloadAttachment}
                  />
                </>
              </div>

              {/* 返信ボタンとリアクションボタンを条件に応じて表示する */}
              {(props.showReplyButtonType !== ShowReplyButtonType.None ||
                props.showReactionsAndReactionButton !==
                  ShowReactionType.None) && (
                <CrewReplyAndReactionButton
                  message={props.message}
                  onReplyClick={props.onReplyClick}
                  showReplyButtonType={props.showReplyButtonType}
                  showReactionsAndReactionButton={
                    props.showReactionsAndReactionButton
                  }
                  replyCount={props.replyCount}
                />
              )}
            </div>
          </>
        )}
      </div>
    )
  }
)
