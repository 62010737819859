import { useChatMessage } from '@crew/states'

import { useAppDispatch, useAppSelector } from 'states/hooks'
import { useMemo } from 'react'
import { useCanAccessChatRoomQuery } from '@crew/apis/chat/chatApis'
import { skipToken } from '@reduxjs/toolkit/query'

export const useAiAssistantThreadPanel = () => {
  // AIアシスタントチャンネルで表示するトピックID
  const aiAssistantCurrentChatThreadTopicId = useAppSelector(
    (state) => state.message.chat.aiAssistantChannel.currentChatThread?.topicId
  )

  // トピックのメッセージ
  const dispatch = useAppDispatch()
  const { message: topicMessage } = useChatMessage(
    aiAssistantCurrentChatThreadTopicId ?? '',
    dispatch,
    useAppSelector
  )

  // チャットルームにアクセスする権限があるかを取得する
  const canAccessChatRoomParams = topicMessage?.chatRoomId
    ? {
        chatRoomId: topicMessage?.chatRoomId,
      }
    : undefined
  const { data: canAccessChatRoomResult } = useCanAccessChatRoomQuery(
    canAccessChatRoomParams ?? skipToken
  )

  // チャットルームへのアクセス権限があるか（チャットを表示するかどうかに使用する）
  const canAccessChatRoom = useMemo(() => {
    const canAccess = canAccessChatRoomResult?.canAccess
    if (canAccess === undefined) {
      return false
    } else {
      return canAccess
    }
  }, [canAccessChatRoomResult?.canAccess])

  return {
    topicMessage,
    canAccessChatRoom,
  }
}
