import { FC, memo } from 'react'
import { MessageType } from '@crew/enums/domain'
import { useTranslation } from '@crew/modules/i18n'

export type CrewCallNotificationChatMessageProps = {
  messageType: string
}

export const CrewCallNotificationChatMessage: FC<CrewCallNotificationChatMessageProps> =
  memo((props) => {
    const { t } = useTranslation()

    // 通話失敗の場合、詳細メッセージを表示
    return props.messageType === MessageType.CallFailed ? (
      <>{t(`message.chat.callFailedReason`)}</>
    ) : null
  })
