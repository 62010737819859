import { FC, memo } from 'react'
import { ChatMessageReference } from '@crew/models/domain'
import { useGetProjectQuery } from '@crew/apis/project/projectApis'
import { GetProjectRequest } from '@crew/apis/project/models/getProject/request'
import { skipToken } from '@reduxjs/toolkit/query'

export type RagTargetItemProps = {
  reference: ChatMessageReference // チャットメッセージ参照データ
}

/**
 * RAG対象のアイテム表示
 */
export const RagTargetItem: FC<RagTargetItemProps> = memo((props) => {
  // プロジェクトを取得
  const getProjectParam: GetProjectRequest | undefined =
    props.reference.rootEntityRecordId !== null
      ? {
          projectId: props.reference.rootEntityRecordId, // プロジェクト名を表示するため、ルートエンティティレコードIDを使用する
        }
      : undefined
  const { data: getProjectResult } = useGetProjectQuery(
    getProjectParam ?? skipToken
  )

  const project = getProjectResult?.project
  // ロードされるまでは何も表示しない
  if (!project) {
    return null
  }

  return (
    <span
      key={project.id}
      className="crew-badge border text-sm text-center crew-text-gray-4 crew-border-gray"
    >
      {project.subject}
    </span>
  )
})
