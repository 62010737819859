import { useCallback, useMemo, useRef } from 'react'
import { useEffectOnce } from '@dx-system/react-use'

import { useLazyGetChatRoomMessagesQuery } from '@crew/apis/chat/chatApis'
import { GetChatRoomMessagesRequest } from '@crew/apis/chat/models/getChatRoomMessages/request'
import { ChatMessage } from '@crew/apis/chat/models/getChatRoomMessages/response'
import { MESSAGE_AREA_MIN_HEIGHT } from '@crew/configs/constants'
import { SearchRange, TimelineDirection } from '@crew/enums/app'
import { useFetchLimit, useValueChangeEffect } from '@crew/hooks'
import { useSubscribeChat } from '@crew/providers/websocket'
import {
  Chat,
  useChatMessageService,
  useChatThreadListService,
} from '@crew/states'

import { useAppDispatch, useAppSelector } from 'states/hooks'
import { getWindowDimensions } from 'utils'

/** メッセージアイテムの型 */
export type DisplayMessageItem = {
  id: string // メッセージIDと表示形式を組み合わせたID
  messageId: string // メッセージID
  threadReplyCount: number // スレッドの返信数
  handleAdditionalLoading: (() => void) | undefined // 追加読込処理
}

/** messagesから表示用アイテムへ変換する関数の戻り値の型 */
type ConvertMessagesToDisplayItemsResult = {
  items: DisplayMessageItem[]
}

/** 複数メッセージ追加時のパラメータ */
type AddMessagesParams = {
  chatMessages: ChatMessage[]
  criterionMessageId: string | undefined
  direction: TimelineDirection
  isLoadedOfEndOfMessages: boolean
}

/** チャットメッセージをロードするためのパラメータ */
type LoadChatMessagesParams = {
  criterionMessageId: string | undefined
  direction: TimelineDirection
}

/**
 * 引数のmessagesから表示用のアイテムリストを生成する関数
 * @param messages ViewModelから取得したThreadList形式のmessages
 * @param loadChatMessages チャットメッセージをロードする関数
 * @returns 表示用アイテムリスト
 */
export const convertMessagesToDisplayItems = (
  messages: Chat.ThreadListMessage[],
  loadChatMessages: (params: LoadChatMessagesParams) => void
): ConvertMessagesToDisplayItemsResult => {
  const items: DisplayMessageItem[] = []

  messages.forEach((message, _) => {
    // 追加読込の方向を決定する。必要無ければundefined
    const additionalLoadingDirection =
      message.hasMorePrev && message.hasMoreNext // 時間軸上で両方向の隣のメッセージが未読込だった場合はboth
        ? TimelineDirection.BothNewerAndOlder
        : message.hasMorePrev // 時間軸上でprev方向の隣のメッセージが未読込だった場合はolder
        ? TimelineDirection.Older
        : message.hasMoreNext // 時間軸上でnext方向の隣のメッセージが未読込だった場合はnewer
        ? TimelineDirection.Newer
        : undefined // 両方向の隣のメッセージが読込済だった場合は追加読込は不要

    // 追加読込処理を行う関数を生成する。必要無ければundefined
    const handleAdditionalLoading = additionalLoadingDirection
      ? () =>
          loadChatMessages({
            criterionMessageId: message.id,
            direction: additionalLoadingDirection,
          })
      : undefined

    // 表示アイテムを生成する
    items.push({
      id: message.id,
      messageId: message.id,
      threadReplyCount: message.replyCount ?? 0,
      handleAdditionalLoading,
    })
  })

  return { items }
}

/**
 * AIアシスタント - スレッドリスト形式のメッセージ一覧を表示するためのフック
 */
export const useAiAssistantThreadListMessageList = () => {
  // 処理対象のAIアシスタントチャンネルのチャットルーム情報をReduxから取得
  const aiAssistantChatRoom = useAppSelector(
    (state) => state.message.chat.aiAssistantChannel.chatRoom
  )
  // この処理が流れる際、Reduxには必ずAIアシスタントチャンネルのチャットルームが設定されているはずなので、未設定の場合はエラーとする
  if (!aiAssistantChatRoom) {
    throw new Error('aiAssistantChatRoom is undefined')
  }

  const dispatch = useAppDispatch()

  // Sliceの操作を行うためのServiceを取得
  const chatThreadListService = useChatThreadListService(dispatch)

  // メッセージ表示領域のref
  const itemsScrollableDivRef = useRef<HTMLDivElement>(null)

  // 取得したチャットメッセージをSliceにキャッシュする関数を取得
  const chatMessageService = useChatMessageService(dispatch)

  // チャットメッセージ取得API
  const [lazyGetChatRoomMessagesQuery] = useLazyGetChatRoomMessagesQuery()

  // Reduxに格納されている対象チャットルームのメッセージのDictionaryを取得する
  const chatThreadListMessageDictionary = useAppSelector(
    (state) =>
      state.message.chat.threadList.entities[aiAssistantChatRoom.id]?.messages
        .entities
  )
  // Reduxに格納されている対象チャットルームのメッセージのIDリストを取得する
  // Reduxに格納されている順番で取得する必要があるので、entitiesだけでなくidsも取得しておく必要がある
  const chatThreadListMessageIds = useAppSelector(
    (state) =>
      state.message.chat.threadList.entities[aiAssistantChatRoom.id]?.messages
        .ids
  )
  // 表示対象のメッセージを返す
  const chatThreadListMessages = useMemo(() => {
    if (!chatThreadListMessageIds || !chatThreadListMessageDictionary) {
      return []
    }
    // id順にメッセージを設定する
    return chatThreadListMessageIds.reduce(
      (result: Chat.ThreadListMessage[], id) => {
        const message = chatThreadListMessageDictionary[id]
        if (message) {
          result.push(message)
        }
        return result
      },
      []
    )
  }, [chatThreadListMessageDictionary, chatThreadListMessageIds])

  /**
   * 複数アイテムをViewModelに追加する
   * @param params
   */
  const addItems = useCallback(
    (params: AddMessagesParams) => {
      // ViewModelへデータを追加
      const parameter = {
        chatRoomId: aiAssistantChatRoom.id,
        messages: params.chatMessages,
        criterionMessageId: params.criterionMessageId,
        direction: params.direction,
        isLoadedOfEndOfSourceItems: params.isLoadedOfEndOfMessages,
      }
      chatThreadListService.addChatThreadListMessages(parameter)
    },
    [aiAssistantChatRoom.id, chatThreadListService]
  )

  /**
   * API経由で取得したチャットメッセージをメッセージのキャッシュとViewModelに追加する
   */
  const addMessages = useCallback(
    (params: AddMessagesParams) => {
      // キャッシュへ追加
      chatMessageService.addChatMessagesToCache({
        chatMessages: params.chatMessages,
      })
      // ViewModelへ追加
      addItems({
        chatMessages: params.chatMessages,
        criterionMessageId: params.criterionMessageId,
        direction: params.direction,
        isLoadedOfEndOfMessages: params.isLoadedOfEndOfMessages,
      })
    },
    [addItems, chatMessageService]
  )

  // 一度にfetchするサイズ
  const fetchLimit = useFetchLimit(MESSAGE_AREA_MIN_HEIGHT, getWindowDimensions)

  /**
   * チャットメッセージをロードするメソッド
   * @param params 対象メッセージを絞り込むためのパラメータ
   * @returns
   */
  const loadChatMessages = useCallback(
    async (params: LoadChatMessagesParams) => {
      const request: GetChatRoomMessagesRequest = {
        filter: SearchRange.AllMessage, // AIアシスタントの場合チャットルームは階層化していないので、すべてのメッセージを取得する
        keyword: '', // キーワード検索は行わないので空文字を指定する
        chatRoomId: aiAssistantChatRoom.id,
        threadRootOnly: true, // スレッドリスト形式の場合はトピックのみ取得する
        threadRootMessageId: undefined, // 特定スレッドではなくトピックを取得するのでundefinedを指定する
        normalMessageOnly: undefined, // message_typeでの絞り込みは行わないのでundefinedを指定する
        criterionMessageId: params.criterionMessageId,
        direction: params.direction,
        limit: fetchLimit,
      }

      try {
        const data = await lazyGetChatRoomMessagesQuery(request).unwrap()

        const isLoadedOfEndOfMessages = request.limit > data.chatMessages.length

        const messagesAddedPayload: AddMessagesParams = {
          chatMessages: data.chatMessages,
          ...request, // 型が違うがduck typeによって代入可能
          isLoadedOfEndOfMessages,
        }

        if (request.chatRoomId === aiAssistantChatRoom.id) {
          addMessages(messagesAddedPayload)
        } else {
          console.info(
            `[useAiAssistantThreadListMessageList] Chat room id mismatch. current:${aiAssistantChatRoom.id} recv:${request.chatRoomId}`
          )
        }
      } catch (err) {
        // TODO: CREW-13720の対応で、プロジェクトから退出した際にエラートーストが出てしまう問題が発生し、トースト表示をコメントアウトする暫定対応を行った
        // ただ、これにより追加ロード時にエラーがあっても画面上表示されないという状態であるため、以下タスクで恒久対応を行う
        // 現時点ではコンソールにエラーを表示するにとどめる
        // https://break-tmc.atlassian.net/browse/CREW-13724
        // toast.error(t('message.general.failedToRetrieveData'))
        console.error(err)
      }
    },
    [
      fetchLimit,
      lazyGetChatRoomMessagesQuery,
      aiAssistantChatRoom.id,
      addMessages,
    ]
  )

  // チャット表示用アイテムリスト
  const { items: displayItems } = useMemo(
    () =>
      convertMessagesToDisplayItems(chatThreadListMessages, loadChatMessages),
    [chatThreadListMessages, loadChatMessages]
  )

  // チャット関連のメッセージをwebsocket経由でsubscribeする
  useSubscribeChat(aiAssistantChatRoom.id, undefined, dispatch, useAppSelector)

  useEffectOnce(() => {
    // 追加読み込みが走るように、最新のメッセージのhasMoreNextを強制的にtrueにする
    chatThreadListService.forceUpdateThreadListMessageHasMoreNext({
      chatRoomId: aiAssistantChatRoom.id,
    })
  })

  // 表示対象のチャットルームが変わったら初期ロードを行う
  useValueChangeEffect(
    () => {
      loadChatMessages({
        criterionMessageId: undefined, // 最新のものをロード
        direction: TimelineDirection.Older,
      })
    },
    [loadChatMessages],
    aiAssistantChatRoom.id
  )

  return {
    displayItems,
    itemsScrollableDivRef,
  }
}
