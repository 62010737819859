import { useGetProjectQuery } from '@crew/apis/project/projectApis'
import { KeywordFilterCondition, ProjectType } from '@crew/enums/domain'
import { getDefaultTabValue } from '@crew/utils/enum'
import { CrewTextBox } from 'components/devextreme/crewTextBox'
import { OPERATION_KEY } from 'configs/constants'
import { ButtonTypes } from 'devextreme-react/cjs/button'
import { NativeEventInfo } from 'devextreme/events'
import { TextBoxInstance } from 'devextreme/ui/text_box'
import { SearchTabs } from 'enums/app'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import qs from 'qs'
import { FC, memo, useCallback, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { getParamAsString, getParamsOperator } from 'utils'
import { Button as TextBoxButton } from 'devextreme-react/text-box'
import { useTranslation } from '@crew/modules/dist/i18n'
import { SearchInDirectChannelHeadItem } from './components/searchInDirectChannelHeadItem'
import { SearchInProjectHeadItem } from './components/searchInProjectHeadItem'

export const SearchInProjectHeadPanel: FC = memo((props) => {
  const { navigate } = useCrewNavigate()
  const { t } = useTranslation()

  const [searchParams] = useSearchParams()
  const params = qs.parse(searchParams.toString())

  const projectId = useMemo(
    () => getParamAsString('projectId', params),
    [params]
  )

  const operation = useMemo(
    () => getParamsOperator(OPERATION_KEY, params),
    [params]
  )

  const keyword = useMemo(() => getParamAsString('keyword', params), [params])

  const [searchConditionMode, setSearchConditionMode] =
    useState<KeywordFilterCondition>(
      operation === KeywordFilterCondition.And
        ? KeywordFilterCondition.And
        : KeywordFilterCondition.Or
    )

  // Apply search keyword to cross search
  const handleCrossSearchChanged = useCallback(
    (e: NativeEventInfo<TextBoxInstance, KeyboardEvent>) => {
      const keyword = e.component.option('text')
      keyword &&
        navigate(`/search/${getDefaultTabValue(SearchTabs)}`, {
          keyword,
          projectId,
          [OPERATION_KEY]: searchConditionMode,
        })
    },
    [navigate, projectId, searchConditionMode]
  )

  const searchConditionButton = useMemo<ButtonTypes.Properties>(
    () => ({
      stylingMode: 'text',
      onClick: () => {
        setSearchConditionMode((prevConditionMode: KeywordFilterCondition) =>
          prevConditionMode === 'and' ? 'or' : 'and'
        )
      },
      text: searchConditionMode.toLocaleUpperCase(),
    }),
    [searchConditionMode]
  )

  // ヘッダー表示のためにプロジェクト詳細を取得する
  const { data: getProjectResult } = useGetProjectQuery(
    {
      projectId: projectId ?? '',
    },
    // プロジェクトIDがセットされていない状態の時はクエリをスキップする
    { skip: !projectId }
  )

  // ダイレクトチャンネルのプロジェクトかどうか
  const isDirectChannelProject =
    getProjectResult?.project?.projectType === ProjectType.DirectChannel

  return (
    <div className="flex flex-row items-center gap-2 w-full">
      <div className="font-bold">{`${t('action.search')} : `}</div>
      {/* アバター と 表示名*/}
      {isDirectChannelProject ? (
        // ダイレクトチャンネルのプロジェクトの場合
        <SearchInDirectChannelHeadItem />
      ) : (
        // 通常のプロジェクトの場合
        <SearchInProjectHeadItem projectData={getProjectResult} />
      )}

      <div className="ml-auto">
        <CrewTextBox
          id="search"
          name="search"
          placeholder={t('action.search')}
          mode="search"
          onEnterKey={handleCrossSearchChanged}
          defaultValue={keyword}
        >
          <TextBoxButton
            name="searchCondition"
            location="after"
            options={searchConditionButton}
          />
        </CrewTextBox>
      </div>
    </div>
  )
})
