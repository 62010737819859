import { FC, memo, useMemo } from 'react'
import { ChatMessageReference } from '@crew/models/domain'
import EventAvailable from '~icons/material-symbols/event-available'
import { CrewLink } from 'components/elements/crewLink/crewLink'
import { getDefaultTabValue } from '@crew/utils/enum'
import { EntityType } from '@crew/enums/domain'
import { TaskDetailListTabs } from 'enums/app'
import { useGetTaskCommentQuery } from '@crew/apis/task/taskApis'
import { GetTaskCommentRequest } from '@crew/apis/task/models/getTaskComment/request'
import { skipToken } from '@reduxjs/toolkit/query'
import { useTranslation } from '@crew/modules/i18n'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'
import { CrewHtmlContent } from 'components/elements/crewHtmlContent/crewHtmlContent'
import { HTMLEDITOR_VIEW_STYLE } from 'configs/constants'
import classNames from 'classnames'
import { sanitizeHtml } from 'utils/html'

export type RagResponseReferenceTaskCommentProps = {
  reference: ChatMessageReference // RAG応答表示に使用するチャットメッセージ参照
}

/**
 * RAG応答参照
 * タスクコメント
 */
export const RagResponseReferenceTaskComment: FC<RagResponseReferenceTaskCommentProps> =
  memo((props) => {
    const { t } = useTranslation()

    // ユーザー設定からデフォルトのユーザープロファイル地域を取得
    const defaultUserProfileRegion = useUserSetting(
      SettingKeyType.UserProfileRegion,
      Region.Japan.value
    )

    // タスクコメントを取得
    const getTaskCommentParam: GetTaskCommentRequest = {
      taskCommentId: props.reference.entityRecordId,
    }
    const { data: getTaskCommentResult } = useGetTaskCommentQuery(
      getTaskCommentParam ?? skipToken
    )

    const taskComment = getTaskCommentResult?.taskComment

    // リンク下に表示するコンテンツ
    const displayHtmlContent = useMemo(() => {
      // 取得できていない場合は何も表示しない
      if (!taskComment) {
        return ''
      }

      // 説明をサニタイズして表示
      return sanitizeHtml(taskComment.description)
    }, [taskComment])

    // ロードされるまでは何も表示しない。型の都合でtaskHistoryもundefinedチェックしておく
    if (!taskComment || !taskComment.taskHistory) {
      return null
    }

    return (
      <div className="flex flex-row gap-x-1">
        {/* アイコン */}
        <div className="flex-shrink-0">
          <EventAvailable className="crew-text-gray-4" width={24} height={24} />
        </div>
        <div className="flex flex-col gap-y-1">
          <div className="flex flex-row gap-x-1 items-center">
            {/* 対象エンティティへのリンク */}
            <CrewLink
              className="text-md"
              to={`/${EntityType.Task}/${
                taskComment.taskHistory.taskId
              }/${getDefaultTabValue(TaskDetailListTabs)}`}
            >
              {taskComment.taskHistory.subject}
            </CrewLink>

            {/* タスクコメント更新日時 */}
            <span className="text-sm crew-text-gray-5">
              {t('format.timestamp', {
                value: taskComment.updatedAt,
                timeZone: defaultUserProfileRegion,
              })}
            </span>
          </div>
          <div className={classNames(HTMLEDITOR_VIEW_STYLE, 'line-clamp-3')}>
            <CrewHtmlContent html={displayHtmlContent} />
          </div>
        </div>
      </div>
    )
  })
