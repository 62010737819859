import { apis } from '../apiBase/apiBase';
export const callApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        getCall: builder.query({
            query: (params) => ({
                url: `calls/${params.callId}`,
                method: 'GET',
            }),
        }),
        startCall: builder.mutation({
            query: (params) => ({
                url: 'calls/start',
                method: 'POST',
                body: params,
            }),
        }),
        startVideoCall: builder.mutation({
            query: (params) => ({
                url: `calls/start-video`,
                method: 'POST',
                body: params,
            }),
        }),
        endCall: builder.mutation({
            query: (params) => ({
                url: `calls/end`,
                method: 'POST',
                body: params,
            }),
        }),
        answerCall: builder.mutation({
            query: (params) => ({
                url: `calls/answer`,
                method: 'POST',
                body: params,
            }),
        }),
        declineCall: builder.mutation({
            query: (params) => ({
                url: `calls/decline`,
                method: 'POST',
                body: params,
            }),
        }),
        reportCall: builder.mutation({
            query: (params) => ({
                url: `calls/report`,
                method: 'POST',
                body: params,
            }),
        }),
    }),
    overrideExisting: false,
});
export const { useGetCallQuery, useLazyGetCallQuery, useStartCallMutation, useStartVideoCallMutation, useEndCallMutation, useAnswerCallMutation, useDeclineCallMutation, } = callApis;
