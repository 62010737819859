import { useEffectOnce } from '@dx-system/react-use'
import qs from 'qs'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useUpdatePaymentToCardMutation } from '@crew/apis/contract/contractApis'
import { UPDATE_PAYMENT_TO_CARD_TIMEOUT_MSEC } from '@crew/configs/constants'
import { useTranslation } from '@crew/modules/i18n'

import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { getParamAsString, validateContractPlan } from 'utils'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { StripeSubscriptionStatus } from '@crew/enums/app'
import { validateBillingCycle } from 'utils'

const Status = {
  Ok: 'ok',
  Timeout: 'timeout',
  Error: 'error',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
type Status = (typeof Status)[keyof typeof Status]

export const ContractUpdatePaymentToCardPage = memo(() => {
  const [complete, setComplete] = useState(false)
  const [subscriptionStatus, setSubscriptionStatus] =
    useState<StripeSubscriptionStatus>(StripeSubscriptionStatus.Trialing)

  const { t } = useTranslation()
  const { navigate } = useCrewNavigate()

  const [updatePaymentToCardMutation] = useUpdatePaymentToCardMutation()

  const [searchParams] = useSearchParams()

  // パラメータの検証
  const valid = useMemo(() => {
    const params = qs.parse(searchParams.toString())
    const confirmIntentTimeStr = getParamAsString('t', params)
    const redirectStatus = getParamAsString('redirect_status', params)
    if (!confirmIntentTimeStr || !redirectStatus) {
      return Status.Error
    }

    const confirmIntentTime = Number(confirmIntentTimeStr)
    if (isNaN(confirmIntentTime)) {
      return Status.Error
    }

    if (redirectStatus !== 'succeeded') {
      return Status.Error
    }

    // intentが確定した時点からの経過時間が一定時間を超えた場合はタイムアウトとする
    const now = Date.now()
    if (confirmIntentTime + UPDATE_PAYMENT_TO_CARD_TIMEOUT_MSEC < now) {
      return Status.Timeout
    }

    return Status.Ok
  }, [searchParams])

  // リクエストパラメータから請求サイクルを取得
  const params = qs.parse(searchParams.toString())
  const billingCycleStr = getParamAsString('billingCycle', params)
  const planStr = getParamAsString('plan', params)
  const companyName = getParamAsString('companyName', params)
  const contactName = getParamAsString('contactName', params)
  const departmentName = getParamAsString('departmentName', params)
  const officialPosition = getParamAsString('officialPosition', params)
  const tel = getParamAsString('tel', params)
  const purchaseExternalUsersCount = getParamAsString(
    'purchaseExternalUsersCount',
    params
  )

  // パラメータの検証結果を基にBackendのAPIを呼び出す。最初のレンダリング時に一度だけ呼べばよいのでuseEffectOnceを使用する
  useEffectOnce(() => {
    if (valid === Status.Ok) {
      ;(async () => {
        const result = await updatePaymentToCardMutation({
          billingCycle: validateBillingCycle(billingCycleStr)
            ? billingCycleStr
            : undefined,
          plan: validateContractPlan(planStr) ? planStr : undefined,
          companyName: companyName ?? undefined,
          contactName: contactName ?? undefined,
          departmentName: departmentName ?? undefined,
          officialPosition: officialPosition ?? undefined,
          tel: tel ?? undefined,
          purchaseExternalUsersCount: purchaseExternalUsersCount
            ? Number(purchaseExternalUsersCount)
            : undefined,
        }).unwrap()
        setSubscriptionStatus(result.subscriptionStatus)
        setComplete(true)
      })()
    }
  })

  // Backend処理終了時に契約情報画面に遷移する。
  // routerの初期化の進捗などによってnavigateが差し替わることがあり、その場合新しいnavigateを使って改めて遷移を行う必要がある。そのため、
  //  1. 複数回実行する場合があるため、useEffectOnce内部で直接実行するのではなく、別のEffectに分離しておきstateでトリガーする。
  //  2. 依存対象のcompleteとnavigateのどちらかが変わったら再実行する必要があるため、特定の値をみるuseValueChangeEffectではなく、全依存の変化をみるuseEffectを使用する。
  // なお一度実行されればnavigateでrouteが替わりunmountされるため、多重に実行されることは気にしなくてよい
  useEffect(() => {
    if (complete) {
      if (subscriptionStatus === StripeSubscriptionStatus.Trialing) {
        // トライアルの場合は請求先情報登録画面に遷移する
        navigate('/contract/change-billing-address', null, true) // ブラウザバックを避けるためreplaceする
      } else {
        // それ以外はポータル画面に遷移する
        navigate('/contract/portal', null, true) // ブラウザバックを避けるためreplaceする
      }
    }
  }, [complete, navigate, subscriptionStatus])

  const message = useMemo(() => {
    switch (valid) {
      case Status.Ok:
        return t('message.contract.enablingCardPayments')
      case Status.Timeout:
        return t('message.contract.enablingCardPaymentsTimeout')
      default:
        return t('message.contract.enablingCardPaymentsError')
    }
  }, [t, valid])

  const handleBackButtonClick = useCallback(() => {
    navigate('/contract/portal')
  }, [navigate])

  return (
    <div>
      <div className="m-2">{message}</div>
      {valid !== Status.Ok && (
        <CrewButton
          type="primary"
          text={t('label.returnToContractInformation')}
          onClick={handleBackButtonClick}
        />
      )}
    </div>
  )
})
