import { FC, memo, useMemo } from 'react'
import { ChatMessageReference } from '@crew/models/domain'
import OutlineInsertDriveFile from '~icons/ic/outline-insert-drive-file'
import { CrewLink } from 'components/elements/crewLink/crewLink'
import { getDefaultTabValue } from '@crew/utils/enum'
import { EntityType } from '@crew/enums/domain'
import { FileDetailListTabs } from 'enums/app'
import { useGetFileQuery } from '@crew/apis/file/fileApis'
import { GetFileRequest } from '@crew/apis/file/models/getFile/request'
import { skipToken } from '@reduxjs/toolkit/query'
import { useTranslation } from '@crew/modules/i18n'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'
import { CrewHtmlContent } from 'components/elements/crewHtmlContent/crewHtmlContent'
import { HTMLEDITOR_VIEW_STYLE } from 'configs/constants'
import classNames from 'classnames'
import { sanitizeHtml } from 'utils/html'

export type RagResponseReferenceFileProps = {
  reference: ChatMessageReference // RAG応答表示に使用するチャットメッセージ参照
}

/**
 * RAG応答参照
 * ファイル
 */
export const RagResponseReferenceFile: FC<RagResponseReferenceFileProps> = memo(
  (props) => {
    const { t } = useTranslation()

    // ユーザー設定からデフォルトのユーザープロファイル地域を取得
    const defaultUserProfileRegion = useUserSetting(
      SettingKeyType.UserProfileRegion,
      Region.Japan.value
    )

    // ファイルを取得
    const getFileParam: GetFileRequest = {
      fileId: props.reference.entityRecordId,
    }
    const { data: getFileResult } = useGetFileQuery(getFileParam ?? skipToken)

    const file = getFileResult?.file

    // リンク下に表示するコンテンツ
    const displayHtmlContent = useMemo(() => {
      // 表示するコンテンツ
      let displayContent = ''

      // ナレッジベースファイルコンテキストから表示する内容に追加
      props.reference.knowledgeBaseFileContexts.forEach((context) => {
        displayContent += context.text
      })

      // サニタイズして表示
      return sanitizeHtml(displayContent)
    }, [props.reference.knowledgeBaseFileContexts])

    // ロードされるまでは何も表示しない
    if (!file) {
      return null
    }

    return (
      <div className="flex flex-row gap-x-1">
        {/* アイコン */}
        <div className="flex-shrink-0">
          <OutlineInsertDriveFile
            className="crew-text-gray-4"
            width={24}
            height={24}
          />
        </div>
        <div className="flex flex-col gap-y-1">
          <div className="flex flex-row gap-x-1 items-center">
            {/* 対象エンティティへのリンク */}
            <CrewLink
              className="text-md"
              to={`/${EntityType.File}/${
                props.reference.entityRecordId
              }/${getDefaultTabValue(FileDetailListTabs)}`}
            >
              {file.name}
            </CrewLink>

            {/* ファイル更新日時 */}
            <span className="text-sm crew-text-gray-5">
              {t('format.timestamp', {
                value: file.lastUpdatedAttachmentAt,
                timeZone: defaultUserProfileRegion,
              })}
            </span>
          </div>

          <div className={classNames(HTMLEDITOR_VIEW_STYLE, 'line-clamp-3')}>
            <CrewHtmlContent html={displayHtmlContent} />
          </div>
        </div>
      </div>
    )
  }
)
