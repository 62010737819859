import { memo, useEffect, useState } from 'react'
import {
  TaskCategoryRef,
  TaskStateRef,
  TaskKindRef,
  UserRef,
  TaskRef,
} from '@crew/models/refs'
import { useTranslation } from '@crew/modules/i18n'
import { TaskHistoryType } from '@crew/enums/app'
import { getTaskPriorityEnumByValue } from 'utils'
import { convertMinutesToHHMM } from '@crew/utils'

type History = {
  label: string
  content: string
}

type TaskHistory = {
  originalId: string | null
  originalHistory: TaskHistory
  subject?: string
  startDate?: string | null
  dueDate?: string | null

  assignToUserId: string | null
  assignToUser: UserRef | null

  taskPriority: number

  taskStateId: string
  taskState: TaskStateRef

  taskKindId?: string
  taskKind?: TaskKindRef

  taskCategoryId?: string | null
  taskCategory?: TaskCategoryRef | null

  estimatedWorkTimes?: number | null
  actualWorkTimes?: number | null
  remainingWorkTimes?: number | null

  actualProgress: number | null
  wbsNumber: string | null
  isProgressManagementDisabled: boolean

  parentTaskId: string | null
  parentTask: TaskRef | null
}

type CrewTaskHistoryProps = {
  taskHistory: TaskHistory
}

export const CrewTaskHistory = memo((props: CrewTaskHistoryProps) => {
  const { t } = useTranslation()
  const [histories, setHistories] = useState<History[]>([])

  useEffect(() => {
    if (props.taskHistory.originalHistory) {
      const historyList: History[] = []
      Object.values(TaskHistoryType).forEach((item) => {
        switch (item.key) {
          //check change subject
          case TaskHistoryType.Subject.key:
            if (
              props.taskHistory.subject !==
              props.taskHistory.originalHistory.subject
            ) {
              historyList.push({
                label: t(`label.${TaskHistoryType.Subject.displayText}`),
                content: `${props.taskHistory.originalHistory.subject} > ${props.taskHistory.subject}`,
              })
            }
            break
          //check change startDate
          case TaskHistoryType.StartDate.key:
            if (
              (props.taskHistory.startDate ||
                props.taskHistory.originalHistory.startDate) &&
              props.taskHistory.startDate !==
                props.taskHistory.originalHistory.startDate
            ) {
              historyList.push({
                label: t(`label.${TaskHistoryType.StartDate.displayText}`),
                content: `${
                  props.taskHistory.originalHistory.startDate
                    ? t('format.date', {
                        value: props.taskHistory.originalHistory.startDate,
                      })
                    : t('label.notSet')
                }> ${
                  props.taskHistory.startDate
                    ? t('format.date', {
                        value: props.taskHistory.startDate,
                      })
                    : t('label.notSet')
                }`,
              })
            }
            break
          //check change dueDate
          case TaskHistoryType.DueDate.key:
            if (
              (props.taskHistory.dueDate ||
                props.taskHistory.originalHistory.dueDate) &&
              props.taskHistory.dueDate !==
                props.taskHistory.originalHistory.dueDate
            ) {
              historyList.push({
                label: t(`label.${TaskHistoryType.DueDate.displayText}`),
                content: `${
                  props.taskHistory.originalHistory.dueDate
                    ? t('format.date', {
                        value: props.taskHistory.originalHistory.dueDate,
                      })
                    : t('label.notSet')
                } > ${
                  props.taskHistory.dueDate
                    ? t('format.date', {
                        value: props.taskHistory.dueDate,
                      })
                    : t('label.notSet')
                }`,
              })
            }
            break
          //check change owner user
          //task has not been assigned, let it be unassigned
          case TaskHistoryType.AssignToUser.key:
            if (
              (props.taskHistory.assignToUserId ||
                props.taskHistory.originalHistory.assignToUserId) &&
              props.taskHistory.assignToUserId !==
                props.taskHistory.originalHistory.assignToUserId
            ) {
              historyList.push({
                label: t(`label.${TaskHistoryType.AssignToUser.displayText}`),
                content: `${
                  props.taskHistory.originalHistory.assignToUser
                    ? props.taskHistory.originalHistory.assignToUser.displayName
                    : t('label.unassigned')
                } > ${
                  props.taskHistory.assignToUser
                    ? props.taskHistory.assignToUser.displayName
                    : t('label.unassigned')
                }`,
              })
            }
            break

          //check change task state
          case TaskHistoryType.TaskState.key:
            if (
              props.taskHistory.taskStateId &&
              props.taskHistory.originalHistory.taskStateId &&
              props.taskHistory.taskStateId !==
                props.taskHistory.originalHistory.taskStateId
            ) {
              historyList.push({
                label: t(`label.${TaskHistoryType.TaskState.displayText}`),
                content: `${props.taskHistory.originalHistory.taskState.name} > ${props.taskHistory.taskState.name}`,
              })
            }
            break

          //check change task priority
          case TaskHistoryType.TaskPriority.key:
            if (
              props.taskHistory.taskPriority &&
              props.taskHistory.originalHistory.taskPriority &&
              props.taskHistory.taskPriority !==
                props.taskHistory.originalHistory.taskPriority
            ) {
              // 変更前のタスク優先度
              const beforeTaskPriority = getTaskPriorityEnumByValue(
                props.taskHistory.originalHistory.taskPriority
              )
              // 変更後のタスク優先度
              const afterTaskPriority = getTaskPriorityEnumByValue(
                props.taskHistory.taskPriority
              )
              // それぞれタスク優先度が取れているのでenumが取得できないことはあり得ないが、
              // getTaskPriorityEnumByValue()で値を取得する兼ね合いでundefinedが入りうるので、undefined時は処理を中断する
              if (!beforeTaskPriority || !afterTaskPriority) {
                break
              }
              historyList.push({
                label: t(`label.${TaskHistoryType.TaskPriority.displayText}`),
                content: `${t(beforeTaskPriority.text)} > ${t(
                  afterTaskPriority.text
                )}`,
              })
            }
            break

          //check change task type
          case TaskHistoryType.TaskKind.key:
            if (
              (props.taskHistory.taskKindId ||
                props.taskHistory.originalHistory.taskKindId) &&
              props.taskHistory.taskKindId !==
                props.taskHistory.originalHistory.taskKindId
            ) {
              historyList.push({
                label: t(`label.${TaskHistoryType.TaskKind.displayText}`),
                content: `${
                  props.taskHistory.originalHistory.taskKind?.name ??
                  t('label.notSet')
                } > ${props.taskHistory.taskKind?.name ?? t('label.notSet')}`,
              })
            }
            break

          //check change task category
          case TaskHistoryType.TaskCategory.key:
            if (
              (props.taskHistory.taskCategoryId ||
                props.taskHistory.originalHistory.taskCategoryId) &&
              props.taskHistory.taskCategoryId !==
                props.taskHistory.originalHistory.taskCategoryId
            ) {
              historyList.push({
                label: t(`label.${TaskHistoryType.TaskCategory.displayText}`),
                content: `${
                  props.taskHistory.originalHistory.taskCategory?.name ??
                  t('label.notSet')
                } > ${
                  props.taskHistory.taskCategory?.name ?? t('label.notSet')
                }`,
              })
            }
            break

          //check change estimated work time
          case TaskHistoryType.EstimatedWorkTime.key:
            if (
              props.taskHistory.estimatedWorkTimes !==
              props.taskHistory.originalHistory.estimatedWorkTimes
            ) {
              // 変更前の見積時間
              const beforeEstimatedWorkTime =
                typeof props.taskHistory.originalHistory.estimatedWorkTimes ===
                'number'
                  ? convertMinutesToHHMM(
                      props.taskHistory.originalHistory.estimatedWorkTimes
                    )
                  : t('label.notSet')

              // 変更後の見積時間
              const afterEstimatedWorkTime =
                typeof props.taskHistory.estimatedWorkTimes === 'number'
                  ? convertMinutesToHHMM(props.taskHistory.estimatedWorkTimes)
                  : t('label.notSet')

              historyList.push({
                label: t(
                  `label.${TaskHistoryType.EstimatedWorkTime.displayText}`
                ),
                content: `${beforeEstimatedWorkTime} > ${afterEstimatedWorkTime}`,
              })
            }
            break
          //check change actual work time
          case TaskHistoryType.ActualWorkTime.key:
            if (
              props.taskHistory.actualWorkTimes !==
              props.taskHistory.originalHistory.actualWorkTimes
            ) {
              // 変更前の実績時間
              const beforeActualWorkTime =
                typeof props.taskHistory.originalHistory.actualWorkTimes ===
                'number'
                  ? convertMinutesToHHMM(
                      props.taskHistory.originalHistory.actualWorkTimes
                    )
                  : t('label.notSet')

              // 変更後の実績時間
              const afterActualWorkTime =
                typeof props.taskHistory.actualWorkTimes === 'number'
                  ? convertMinutesToHHMM(props.taskHistory.actualWorkTimes)
                  : t('label.notSet')

              historyList.push({
                label: t(`label.${TaskHistoryType.ActualWorkTime.displayText}`),
                content: `${beforeActualWorkTime} > ${afterActualWorkTime}`,
              })
            }
            break
          //check change remaining work time
          case TaskHistoryType.RemainingWorkTime.key:
            if (
              props.taskHistory.remainingWorkTimes !==
              props.taskHistory.originalHistory.remainingWorkTimes
            ) {
              // 変更前の残り時間
              const beforeRemainingWorkTime =
                typeof props.taskHistory.originalHistory.remainingWorkTimes ===
                'number'
                  ? convertMinutesToHHMM(
                      props.taskHistory.originalHistory.remainingWorkTimes
                    )
                  : t('label.notSet')

              // 変更後の残り時間
              const afterRemainingWorkTime =
                typeof props.taskHistory.remainingWorkTimes === 'number'
                  ? convertMinutesToHHMM(props.taskHistory.remainingWorkTimes)
                  : t('label.notSet')

              historyList.push({
                label: t(
                  `label.${TaskHistoryType.RemainingWorkTime.displayText}`
                ),
                content: `${beforeRemainingWorkTime} > ${afterRemainingWorkTime}`,
              })
            }
            break
          //check change task progress rate
          case TaskHistoryType.ActualProgress.key:
            if (
              (props.taskHistory.actualProgress ||
                props.taskHistory.originalHistory.actualProgress) &&
              props.taskHistory.actualProgress !==
                props.taskHistory.originalHistory.actualProgress
            ) {
              historyList.push({
                label: t(`label.${TaskHistoryType.ActualProgress.displayText}`),
                content: `${
                  props.taskHistory.originalHistory.actualProgress
                    ? props.taskHistory.originalHistory.actualProgress + '%'
                    : t('label.notSet')
                } > ${props.taskHistory.actualProgress}%`,
              })
            }
            break
          //check change wbsNumber
          case TaskHistoryType.WbsNumber.key:
            if (
              props.taskHistory.wbsNumber !==
              props.taskHistory.originalHistory.wbsNumber
            ) {
              historyList.push({
                label: t(`label.${TaskHistoryType.WbsNumber.displayText}`),
                content: `${
                  props.taskHistory.originalHistory.wbsNumber
                    ? props.taskHistory.originalHistory.wbsNumber
                    : t('label.notSet')
                }> ${
                  props.taskHistory.wbsNumber
                    ? props.taskHistory.wbsNumber
                    : t('label.notSet')
                }`,
              })
            }
            break
          //check change wbsNumber
          case TaskHistoryType.IsProgressManagementDisabled.key:
            if (
              props.taskHistory.isProgressManagementDisabled !==
              props.taskHistory.originalHistory.isProgressManagementDisabled
            ) {
              historyList.push({
                label: t(
                  `label.${TaskHistoryType.IsProgressManagementDisabled.displayText}`
                ),
                content: `${
                  props.taskHistory.originalHistory.isProgressManagementDisabled
                    ? t('label.on')
                    : t('label.off')
                }> ${
                  props.taskHistory.isProgressManagementDisabled
                    ? t('label.on')
                    : t('label.off')
                }`,
              })
            }
            break
          //check change parent task
          case TaskHistoryType.ParentTask.key:
            // 変更後のタスク履歴
            const currentHistory = props.taskHistory
            // 変更前のタスク履歴
            const originalHistory = props.taskHistory.originalHistory

            if (
              (currentHistory.parentTaskId || originalHistory.parentTaskId) &&
              currentHistory.parentTaskId !== originalHistory.parentTaskId
            ) {
              // 変更前の親タスク名
              const originalParentTaskSubject =
                originalHistory.parentTaskId === null
                  ? t('label.notSet') // 親タスクが設定されていない場合：(未設定)
                  : originalHistory.parentTask
                  ? originalHistory.parentTask.subject // 親タスクが設定されていた場合：親タスク名
                  : t('label.deletedTask') // 親タスクが設定されているが削除済みだった場合：(削除済みタスク)

              // 変更後の親タスク名
              const parentTaskSubject =
                currentHistory.parentTaskId === null
                  ? t('label.notSet') // 親タスクが設定されていない場合：(未設定)
                  : currentHistory.parentTask
                  ? currentHistory.parentTask.subject // 親タスクが設定されていた場合：親タスク名
                  : t('label.deletedTask') // 親タスクが設定されているが削除済みだった場合：(削除済みタスク)

              historyList.push({
                label: t(`label.${TaskHistoryType.ParentTask.displayText}`),
                content: `${originalParentTaskSubject} > ${parentTaskSubject}`,
              })
            }
            break
        }
      })
      setHistories(historyList)
    }

    // 関数で不変のためgetTaskPriorityEnumByValueへの依存は不要
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, props.taskHistory])

  return (
    <ul className="list-disc pl-5">
      {props.taskHistory.originalId ? (
        histories.map((history, index) => {
          return (
            <li
              key={index}
              className="text-crew-green-4-light dark:text-crew-green-3-dark"
            >
              {history.label}: {history.content}
            </li>
          )
        })
      ) : (
        <li className="text-crew-green-4-light dark:text-crew-green-3-dark">
          {t('label.taskAdded')}
        </li>
      )}
    </ul>
  )
})
