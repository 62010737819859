import {
  useGetContactQuery,
  useUpdatePaymentToBankTransferMutation,
} from '@crew/apis/contract/contractApis'
import {
  BillingCycle,
  StripeSubscriptionStatus,
  ContractPlan,
} from '@crew/enums/app'
import { useTranslation } from '@crew/modules/i18n'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import { FC, memo, useCallback, useEffect } from 'react'
import {
  FormValues,
  useContractRegisterBankTransferForm,
} from './useContractRegisterBankTransferForm'
import { ValueChangedEvent } from 'devextreme/ui/text_box'
import { UpdatePaymentToBankTransferRequest } from '@crew/apis/contract/models/updatePaymentToBankTransfer/request'

type Props = {
  billingCycle: BillingCycle | undefined
  plan: ContractPlan | undefined
  showContactInformation: boolean
  purchaseExternalUsersCount: number | undefined // フリープランやトライアル中の場合のみ指定される
}

export const ContractRegisterBankTransferForm: FC<Props> = memo((props) => {
  const { t } = useTranslation()
  const { success } = useToast()
  const { historyBack, navigate } = useCrewNavigate()
  const [showApiErrors] = useShowApiErrors()

  const { control, clearErrors, validateRules, reset, handleSubmit } =
    useContractRegisterBankTransferForm(props.showContactInformation)

  const { data: getContactResult, isFetching } = useGetContactQuery()

  // フォーム初期化処理関数
  const initializeForm = useCallback(async () => {
    if (getContactResult) {
      reset({
        companyName: getContactResult.companyName,
        contactName: getContactResult.contactName ?? '',
        departmentName: getContactResult.departmentName ?? '',
        officialPosition: getContactResult.officialPosition ?? '',
        tel: getContactResult.tel ?? '',
      })
    }
  }, [getContactResult, reset])

  // フォーム初期化処理
  useEffect(() => {
    initializeForm()
  }, [initializeForm])

  const [
    updatePaymentToBankTransfer,
    { isLoading: isLoadingUpdatePaymentToBankTransfer },
  ] = useUpdatePaymentToBankTransferMutation()

  const handleContactNameChange = useCallback(
    (e: ValueChangedEvent) => {
      const value = e.value
      // キーワード未入力時は何もしない
      if (value.trim().length === 0) {
        return
      }

      clearErrors('contactName')
    },
    [clearErrors]
  )

  const handleTelChange = useCallback(
    (e: ValueChangedEvent) => {
      const value = e.value
      // キーワード未入力時は何もしない
      if (value.trim().length === 0) {
        return
      }

      clearErrors('tel')
    },
    [clearErrors]
  )

  // 登録ボタン押下時
  const handleSubmitButtonClick = useCallback(() => {
    const onSubmit = async (data: FormValues) => {
      try {
        const params: UpdatePaymentToBankTransferRequest = {
          billingCycle: props.billingCycle,
          plan: props.plan,
          companyName: undefined,
          contactName: undefined,
          departmentName: undefined,
          officialPosition: undefined,
          tel: undefined,
          purchaseExternalUsersCount: props.purchaseExternalUsersCount,
        }

        // 連絡先情報が表示されている場合は、それぞれの値をパラメータにセットする
        if (props.showContactInformation) {
          if (data.companyName) {
            // 会社名
            params.companyName = data.companyName
          }

          if (data.contactName) {
            // 氏名
            params.contactName = data.contactName
          }

          if (data.departmentName) {
            // 部署名
            params.departmentName = data.departmentName
          }

          if (data.officialPosition) {
            // 役職
            params.officialPosition = data.officialPosition
          }

          if (data.tel) {
            // Tel
            params.tel = data.tel
          }
        }

        // 支払い方法を銀行振込に更新
        const { subscriptionStatus } = await updatePaymentToBankTransfer(
          params
        ).unwrap()

        success(t('message.contract.registerPaymentMethodSuccess'))

        if (subscriptionStatus === StripeSubscriptionStatus.Trialing) {
          // トライアル中の場合は請求先情報の変更画面に遷移
          navigate('/contract/change-billing-address')
        } else {
          // 請求ポータル画面に遷移
          navigate('/contract/portal')
        }
      } catch (error) {
        showApiErrors(error)
      }
    }
    handleSubmit(onSubmit)()
  }, [
    handleSubmit,
    navigate,
    props.billingCycle,
    props.plan,
    props.purchaseExternalUsersCount,
    props.showContactInformation,
    showApiErrors,
    success,
    t,
    updatePaymentToBankTransfer,
  ])

  // キャンセルボタン押下時
  const handleCancelButtonClick = useCallback(() => {
    historyBack()
  }, [historyBack])

  return (
    <form className="flex flex-col gap-5 max-w-3xl">
      <p>{t('label.bankTransferDescription')}</p>

      {/* サブスクリプションステータスがトライアル中またはフリープランを利用中の場合は連絡先情報を入力する */}
      {props.showContactInformation && (
        <div className="flex flex-col gap-2.5 w-full max-w-2xl">
          {/* 見出し（連絡先情報） */}
          <div className="py-2.5">
            <span className="text-md font-bold">
              {t('label.contactInformation')}
            </span>
          </div>

          {/* 会社名 */}
          <div className="flex flex-col gap-1">
            <CrewTextBoxField
              id="companyName"
              name="companyName"
              className="h-11"
              control={control}
              placeholder={t('label.companyNameSample')}
              label={t('label.companyName')}
              showLabel
              rules={validateRules.companyName}
              required
            />
            <span className="text-sm text-red-500">
              {t('label.companyNameNotice')}
            </span>
          </div>

          {/* 氏名 */}
          <div className="flex flex-col gap-1">
            <CrewTextBoxField
              id="contactName"
              name="contactName"
              className="h-11"
              control={control}
              placeholder={t('label.contactNameSample')}
              label={t('label.contactName')}
              showLabel
              rules={validateRules.contactName}
              required
              onValueChanged={handleContactNameChange}
            />
            <span className="text-sm text-red-500">
              {t('label.contactNameNotice')}
            </span>
          </div>

          {/* 部門名 */}
          <CrewTextBoxField
            id="departmentName"
            name="departmentName"
            className="h-11"
            control={control}
            placeholder={t('label.departmentNameSample')}
            label={t('label.departmentName')}
            showLabel
            rules={validateRules.departmentName}
          />

          {/* 役職 */}
          <CrewTextBoxField
            id="officialPosition"
            name="officialPosition"
            className="h-11"
            control={control}
            placeholder={t('label.officialPositionSample')}
            label={t('label.officialPosition')}
            showLabel
            rules={validateRules.officialPosition}
          />

          {/* 電話番号 */}
          <CrewTextBoxField
            id="tel"
            name="tel"
            className="h-11"
            control={control}
            placeholder={t('label.telSample')}
            label={t('label.tel')}
            showLabel
            rules={validateRules.tel}
            required
            onValueChanged={handleTelChange}
          />
        </div>
      )}

      <div className="flex gap-2.5">
        {/* 登録ボタン */}
        <CrewButton
          type="primary"
          text={t('action.register2')}
          disabled={isFetching || isLoadingUpdatePaymentToBankTransfer}
          onClick={handleSubmitButtonClick}
        />
        {/* キャンセルボタン */}
        <CrewButton
          type="normal"
          stylingMode="outlined"
          text={t('action.cancel')}
          onClick={handleCancelButtonClick}
        />
      </div>
    </form>
  )
})
