import { memo, FC, Fragment, useState } from 'react'
import { useAiAssistantThreadListMessageList } from './useAiAssistantThreadListMessageList'
import { getChatMessageListItemDomId } from 'utils/chat'
import { ChatView } from '@crew/enums/app'
import { AiAssistantThreadListMessageListItem } from './components/aiAssistantThreadListMessageListItem/aiAssistantThreadListMessageListItem'
import { useValueChangeEffect } from '@crew/hooks'
import { ChatMessage } from '@crew/models/domain'

type AiAssistantThreadListMessageListProps = {
  onMessageEdit: (message: ChatMessage) => void // メッセージ編集時のコールバック
  selectedItemId?: string // 選択中のメッセージ。ハイライト用に使用する
}

/**
 * AIアシスタント - スレッドリスト形式のメッセージ一覧
 */
export const AiAssistantThreadListMessageList: FC<AiAssistantThreadListMessageListProps> =
  memo((props) => {
    const { displayItems, itemsScrollableDivRef } =
      useAiAssistantThreadListMessageList()

    const [selectedMessageId, setSelectedMessageId] = useState<
      string | undefined
    >(undefined)

    useValueChangeEffect(
      () => {
        setSelectedMessageId(props.selectedItemId)
      },
      [props.selectedItemId],
      props.selectedItemId
    )

    return (
      <div
        className="grow flex overflow-y-scroll flex-col-reverse"
        ref={itemsScrollableDivRef}
      >
        <div>
          {displayItems.map((item) => (
            <Fragment key={item.id}>
              <AiAssistantThreadListMessageListItem
                key={item.id}
                // スクロール位置制御のためChatViewを含めたidとして指定する
                id={getChatMessageListItemDomId(
                  item.messageId,
                  ChatView.ThreadList
                )}
                replyCount={item.threadReplyCount}
                chatMessageId={item.messageId}
                container={itemsScrollableDivRef}
                onAdditionalLoading={item.handleAdditionalLoading}
                selectedItemId={selectedMessageId}
                setSelectedItemId={setSelectedMessageId}
                onMessageEdit={props.onMessageEdit}
              />
            </Fragment>
          ))}
        </div>
      </div>
    )
  })
