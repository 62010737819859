import { apis } from '../apiBase/apiBase';
export const eventRecordingTranscribeApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        getEventRecordingTranscribes: builder.query({
            query: (params) => {
                return {
                    url: `event-recording-transcribes/${params.eventId}`,
                    method: 'GET',
                };
            },
        }),
        insertEventRecordingTranscribe: builder.mutation({
            query: (params) => ({
                url: 'event-recording-transcribes/insert',
                method: 'POST',
                body: params,
            }),
        }),
        updateEventRecordingTranscribe: builder.mutation({
            query: (params) => ({
                url: 'event-recording-transcribes/insert',
                method: 'POST',
                body: params,
            }),
        }),
        deleteEventRecordingTranscribe: builder.mutation({
            query: (params) => ({
                url: 'event-recording-transcribes/delete',
                method: 'POST',
                body: params,
            }),
        }),
    }),
    overrideExisting: false,
});
export const { useGetEventRecordingTranscribesQuery, useLazyGetEventRecordingTranscribesQuery, useInsertEventRecordingTranscribeMutation, useUpdateEventRecordingTranscribeMutation, useDeleteEventRecordingTranscribeMutation, } = eventRecordingTranscribeApis;
