import { FC, memo } from 'react'
import {
  ChatMessageEditorType,
  CrewChatMessageEditor,
} from 'components/elements/crewChatMessageEditor/crewChatMessageEditor'
import { useAppSelector } from 'states/hooks'
import { AiAssistantThreadMessageList } from './components/aiAssistantThreadMessageList/aiAssistantThreadMessageList'

export type AiAssistantThreadProps = {
  showBackButton?: boolean // ヘッダーに戻るボタンを表示するか。デフォルトはfalse
  selectedItemId?: string // 選択中のメッセージ。ハイライト用に使用する
}

/**
 * AIアシスタント - スレッド表示
 */
export const AiAssistantThread: FC<AiAssistantThreadProps> = memo((props) => {
  // AIアシスタントチャンネルで表示するトピックID
  const aiAssistantCurrentChatThread = useAppSelector(
    (state) => state.message.chat.aiAssistantChannel.currentChatThread
  )

  // 表示に必要なデータが指定されていない場合、表示することができないので、何も表示しない
  if (!aiAssistantCurrentChatThread) {
    return null
  }

  return (
    <>
      {/* メッセージリスト */}
      <AiAssistantThreadMessageList
        key={aiAssistantCurrentChatThread.topicId}
        selectedItemId={props.selectedItemId}
      />

      {/* チャット投稿コンポーネント(表示しているトピックへの返信として投稿される) */}
      <CrewChatMessageEditor
        isEditMode={false}
        chatRoomId={aiAssistantCurrentChatThread.chatRoomId}
        threadRootMessageId={aiAssistantCurrentChatThread.topicId}
        rootEntityType={aiAssistantCurrentChatThread.rootEntityType}
        rootEntityRecordId={aiAssistantCurrentChatThread.rootEntityRecordId}
        canUploadFile={false}
        editorType={ChatMessageEditorType.AiAssistant} // AIアシスタント用のエディタを使用する
      />
    </>
  )
})
