const ApiErrorCode = {
    ValidationFailed: 'validationFailed',
    DetectedUpdateByOthers: 'detectedUpdateByOthers',
    JwtMissingError: 'jwtMissingError',
    JwtInvalidError: 'jwtInvalidError',
    InvalidPassword: 'invalidPassword',
    InvalidPasswordLength: 'invalidPasswordLength',
    UnauthorizedError: 'unauthorizedError',
    AlreadyInACall: 'alreadyInACall',
    CallingDeviceNotFound: 'callingDeviceNotFound',
    Unknown: 'unknown',
};
export const IsApiErrorResultValidationFailed = (error) => error.code === ApiErrorCode.ValidationFailed;
export const IsApiErrorResultDetectedUpdatedByOthers = (error) => error.code === ApiErrorCode.DetectedUpdateByOthers;
export const IsApiErrorUnknown = (error) => error.code === ApiErrorCode.Unknown;
export const IsApiErrorJwtMissingError = (error) => error.code === ApiErrorCode.JwtMissingError;
export const IsApiErrorJwtInvalidError = (error) => error.code === ApiErrorCode.JwtInvalidError;
export const IsApiErrorInvalidPassword = (error) => typeof error === 'object' &&
    error != null &&
    error.code === ApiErrorCode.InvalidPassword &&
    typeof error.policy === 'object' &&
    error.policy != null &&
    typeof error.policy.minLength === 'number' &&
    typeof error.policy.complexity === 'boolean' &&
    typeof error.policy.symbols === 'string';
export function isApiErrorResult(error) {
    return (typeof error === 'object' &&
        error != null &&
        error.status !== undefined &&
        error.data !== undefined &&
        typeof error.data.code === 'string');
}
export const IsApiErrorInvalidPasswordLength = (error) => error.code === ApiErrorCode.InvalidPasswordLength;
export const IsApiErrorAlreadyInACall = (error) => error.code === ApiErrorCode.AlreadyInACall;
export const IsApiErrorCallingDeviceNotFound = (error) => error.code === ApiErrorCode.CallingDeviceNotFound;
