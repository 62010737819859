import { FC, memo, useCallback } from 'react'
import { UploadFile } from 'models/domain/uploadFile'
import { formatByteSize } from '@crew/utils/number'
import { CrewFileIcon } from 'components/elements/crewFileIcon/crewFileIcon'
import classNames from 'classnames'
import { CrewPopover } from 'components/devextreme/crewPopover'
import { uniqueString } from '@crew/utils'
import BaselineDelete from '~icons/ic/baseline-delete'
import CloseCircle from '~icons/mdi/close-circle'
import { CrewProgressIndicator } from 'components/elements/crewProgressIndicator/crewProgressIndicator'

// Global in my component
const abortController = new AbortController()

const PROGRESS_FULL = 100

type CrewUploadedFileItemProps = {
  item: UploadFile
  onDeleteFile?: (file: UploadFile) => void // 添付ファイル削除時のコールバック
  showThumbnail: boolean
  fillIcon?: boolean
}

export const CrewUploadedFileItem: FC<CrewUploadedFileItemProps> = memo(
  (props) => {
    const popoverKey = uniqueString()

    // 削除ボタンクリック時の処理
    const handleDeleteButtonClick = useCallback(() => {
      props.onDeleteFile?.(props.item)
      abortController.abort()
    }, [props])

    return props.showThumbnail ? (
      // サムネイル表示モード
      <div
        id={`reacted-${popoverKey}`}
        // 横80pxで固定
        className="w-20 h-20"
      >
        <div
          className={classNames(
            // 横full(=80px)、縦80pxで固定
            'w-full h-20',
            // 枠線の指定。この分だけ↑で計算されたサイズより大きくなる(つまりコンテンツ部分は指定サイズどおりになる)
            'border crew-border-gray',
            // 削除ボタンをabsoluteで表示するため、relativeで座標基準をこのdivとする
            'relative',
            // 角丸
            'rounded-md'
          )}
        >
          <>
            {/* プレビューURLがある場合は、サムネイルを表示 */}
            {props.item.previewUrl && (
              <img
                src={props.item.previewUrl}
                alt={props.item.name}
                // 外枠目一杯に、coverで表示(縦横小さい方を合わせ、はみ出すところは隠す)
                className="h-full w-full object-cover"
              />
            )}

            {/* プレビューURLがない場合は、ファイルアイコンを表示 */}
            {!props.item.previewUrl && (
              <CrewFileIcon fileName={props.item.name} fill={props.fillIcon} />
            )}

            <div
              // サムネイル内の右上にアイコンを位置させるためabsoluteで位置を指定する
              // wとhを0にし、内側の要素をflexで中央に表示することで、表示内容によって位置がずれることを防ぐ
              className="absolute right-1 top-1 w-5 h-5 rounded-full bg-crew-white text-crew-gray-900"
              onClick={handleDeleteButtonClick}
            >
              <CloseCircle width="100%" height="100%" />
            </div>

            {props.item.progress && props.item.progress <= PROGRESS_FULL && (
              <div className="w-full absolute bottom-0 left-0">
                <CrewProgressIndicator progress={props.item.progress} />
              </div>
            )}
          </>
        </div>
        <CrewPopover
          target={`#reacted-${popoverKey}`}
          showEvent="mouseenter"
          hideEvent="mouseleave"
          position="top"
        >
          <span className="text-sm">
            {props.item.name} ({formatByteSize(props.item.size)})
          </span>
        </CrewPopover>
      </div>
    ) : (
      // ファイル名表示モード
      <div className="flex flex-row gap-x-1 items-center">
        {/* File icon */}
        <div>
          <CrewFileIcon fileName={props.item.name} />
        </div>
        <div className="text-sm truncate">
          {props.item.name} ({formatByteSize(props.item.size)})
        </div>
        {props.item.progress && props.item.progress <= PROGRESS_FULL && (
          <div className="w-24">
            <CrewProgressIndicator progress={props.item.progress} />
          </div>
        )}
        {props.onDeleteFile && (
          <span className="cursor-pointer" onClick={handleDeleteButtonClick}>
            <BaselineDelete width={20} height={20} />
          </span>
        )}
      </div>
    )
  }
)
