import { FC, memo, useEffect, useMemo } from 'react'
import { TaskDetailDependencyParentTask } from './components/taskDetailDependencyParentTask/taskDetailDependencyParentTask'
import { TaskDetailDependencyChildTask } from './components/taskDetailDependencyChildTask/taskDetailDependencyChildTask'
import { TaskDetailDependencyPredecessorTask } from './components/taskDetailDependencyPredecessorTask/taskDetailDependencyPredecessorTask'
import { TaskDetailDependencySuccessorTask } from './components/taskDetailDependencySuccessorTask/taskDetailDependencySuccessorTask'
import { useParams } from 'react-router-dom'
import { GetTaskRequest } from '@crew/apis/task/models/getTask/request'
import {
  useGetTaskDependenciesQuery,
  useGetTaskQuery,
} from '@crew/apis/task/taskApis'
import { skipToken } from '@reduxjs/toolkit/query'
import { useAppSelector } from 'states/hooks'
import { GetTaskDependenciesRequest } from '@crew/apis/task/models/getTaskDependencies/request'
import { useProjectAdvancedSettings } from '@crew/hooks'

export const TaskDetailDependencyList: FC = memo(() => {
  const { taskId } = useParams()
  const taskEventMessage = useAppSelector((state) => state.app.taskEventMessage)

  const getTaskParams: GetTaskRequest | undefined = taskId
    ? { taskId }
    : undefined
  const { data: getTaskResult, refetch: getTaskRefetch } = useGetTaskQuery(
    getTaskParams ?? skipToken
  )

  // タスク更新時、親タスクを再取得
  useEffect(() => {
    getTaskRefetch()
  }, [taskEventMessage, getTaskRefetch])

  const taskDependencyEventMessage = useAppSelector(
    (state) => state.app.taskDependencyEventMessage
  )

  const getTaskDependenciesParams: GetTaskDependenciesRequest | undefined =
    taskId &&
    getTaskResult?.task?.entityType &&
    getTaskResult?.task?.entityRecordId
      ? {
          entityType: getTaskResult.task.entityType,
          entityRecordId: getTaskResult.task.entityRecordId,
          taskId,
          keyword: undefined,
          taskKindIds: undefined,
          assignToUser: undefined,
          taskStateIds: undefined,
          taskStateTypes: undefined,
          taskPriorities: undefined,
          taskCategoryIds: undefined,
          startDate: undefined,
          dueDate: undefined,
          createdById: undefined,
          updatedById: undefined,
          createdAt: undefined,
          updatedAt: undefined,
        }
      : undefined
  const { data: getTaskDependencies, refetch: getTaskDependenciesRefetch } =
    useGetTaskDependenciesQuery(getTaskDependenciesParams ?? skipToken)

  // Get task predecessors
  const predecessorTasks = useMemo(() => {
    if (!getTaskDependencies?.taskDependencies) return []

    return getTaskDependencies.taskDependencies.filter(
      (taskDependency) => taskDependency.successorTaskId === taskId
    )
  }, [getTaskDependencies?.taskDependencies, taskId])

  // Get task successors
  const successorTasks = useMemo(() => {
    if (!getTaskDependencies?.taskDependencies) return []

    return getTaskDependencies.taskDependencies.filter(
      (taskDependency) => taskDependency.predecessorTaskId === taskId
    )
  }, [getTaskDependencies?.taskDependencies, taskId])

  useEffect(() => {
    taskDependencyEventMessage && getTaskDependenciesRefetch()
  }, [getTaskDependenciesRefetch, taskDependencyEventMessage])

  const { doProjectManagement } = useProjectAdvancedSettings(
    getTaskResult?.task?.entityRecordId
  )

  // gridで並べる。
  // 1列目は「タイトル」、2列目は「+アイコン」を表示する。各行の列は全て揃うので、「＋」アイコンの横位置を各項目毎に揃えることができる。
  // 1行目は「タイトル」と「+アイコン」を表示し、2行目は列を全て結合し依存先のタスクや依存先タスク追加フォームなどを表示する。
  // このセットを各項目毎に並べる。各項目の間には隙間を設ける。
  //
  // |「タイトル」|「+アイコン」|
  // |   「依存先タスク情報」   |
  // |「タイトル」|「+アイコン」|
  // |   「依存先タスク情報」   |
  //
  // grid-cols-[max-content_1fr]
  //    "grid-template-columns: max-content 1fr"を生成する。gridの行を、最初の列がコンテンツ幅、2つ目が自動拡幅に設定する
  // [&>*:nth-child(3n)]:mb-2.5
  //    このタグの直下(&>)の全て(*)を対象に、3の倍数番目の子(nth-child(3n))に対してマージンボトムを設定する。各項目の間に隙間を設けるのに使用
  //    https://tailwindcss.com/docs/hover-focus-and-other-states#using-arbitrary-variants
  return (
    <div className="grid grid-cols-[max-content_1fr] [&>*:nth-child(3n)]:mb-2.5 gap-2.5 px-4 py-2.5">
      {/* 親タスク */}
      <TaskDetailDependencyParentTask task={getTaskResult?.task} />

      {/* 子タスク */}
      <TaskDetailDependencyChildTask taskId={getTaskResult?.task?.id} />

      {doProjectManagement && (
        <>
          {/* 先行タスク */}
          <TaskDetailDependencyPredecessorTask
            taskDependencies={predecessorTasks}
          />

          {/* 後続タスク */}
          <TaskDetailDependencySuccessorTask
            taskDependencies={successorTasks}
          />
        </>
      )}
    </div>
  )
})
