import { useCallback, useMemo, useRef, useState } from 'react'
import { useEffectOnce } from '@dx-system/react-use'

import {
  useLazyGetChatMessageQuery,
  useLazyGetChatRoomMessagesQuery,
} from '@crew/apis/chat/chatApis'
import { GetChatRoomMessagesRequest } from '@crew/apis/chat/models/getChatRoomMessages/request'
import { ChatMessage } from '@crew/apis/chat/models/getChatRoomMessages/response'
import { MESSAGE_AREA_MIN_HEIGHT } from '@crew/configs/constants'
import {
  ChatView,
  SearchRange,
  SettingKeyType,
  TimelineDirection,
} from '@crew/enums/app'
import {
  useChatRoomLastReadMessage,
  useFetchLimit,
  useValueChangeEffect,
  useProcessInLaterRenderingWithParams,
} from '@crew/hooks'
import { useSubscribeChat } from '@crew/providers/websocket'
import {
  Chat,
  useChatMessageService,
  useChatTimelineService,
} from '@crew/states'
import { compareUlid } from '@crew/utils/ulid'

import { UserChatSettingDisplayRange } from 'enums/app'
import { useUserSetting } from '@crew/states'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { getWindowDimensions } from 'utils'
import { getChatMessageListItemDomId } from 'utils/chat'

import { useSearchParams } from 'react-router-dom'
import qs from 'qs'

export const MessageItemType = {
  /**
   * 基本的なスタイルで表示する。インデントなし、アバターはユーザアイコン大
   */
  NormalMessage: 'normalMessage',
  /**
   * 返信として表示する。インデントあり、アバターはユーザアイコン小
   */
  ReplyMessage: 'replyMessage',
  /**
   * appendスタイルで、スレッドが切り替わった場合のヘッダとして表示する。インデントなし、アバターはスレッドマーク小、本文省略あり
   */
  TopicSummary: 'topicSummary',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MessageItemType =
  (typeof MessageItemType)[keyof typeof MessageItemType]

/** メッセージアイテムの型 */
export type DisplayMessageItem = {
  id: string // メッセージIDと表示形式を組み合わせたID
  messageId: string // メッセージID
  topicId: string // トピックID（表示上、同じトピックに属するかどうかの判別に使用する）
  type: MessageItemType // アイテムのタイプ。描画の切り替えに使用する
  showDivideLine: boolean // 分割ラインを表示するかどうか
  showUnreadLine: boolean // 未読ラインを表示するかどうか
  isFirstUnreadMessage: boolean // 最古の未読メッセージかどうか
  deleted: boolean // 削除済みメッセージかどうか(トピック以外は常にfalse)
  handleAdditionalLoading: (() => void) | undefined // 追加読込処理
  handleFirstUnreadMessageViewed: (() => void) | undefined // 最初の未読アイテムが表示されたときの処理
}

/** messagesから表示用アイテムへ変換する関数の戻り値の型 */
type ConvertMessagesToDisplayItemsResult = {
  items: DisplayMessageItem[]
  unreadLineMessageId: string | undefined
}

/** 複数メッセージ追加時のパラメータ */
type AddMessagesParams = {
  chatMessages: ChatMessage[]
  criterionMessageId: string | undefined
  direction: TimelineDirection
  isLoadedOfEndOfMessages: boolean
}

/** チャットメッセージをロードするためのパラメータ */
type LoadChatMessagesParams = {
  filter: SearchRange
  criterionMessageId: string | undefined
  direction: TimelineDirection
}

/**
 * 引数のmessagesから表示用のアイテムリストを生成する関数
 * @param messages ViewModelから取得したTimeline形式のmessages
 * @param isSuccessLastReadFetch 既読情報の取得に成功しているかどうか
 * @param lastReadMessageIdsInChatRoomClan 子孫チャットルームを含め、未読があるチャットルームとその最終既読メッセージIDのmap。未読ラインの表示に使う
 * @param loadChatMessages チャットメッセージをロードする関数
 * @param delayedUpdateLastReadMessageId 既読更新を遅延実行させるための関数
 * @param currentSearchRange 現在の検索範囲
 * @param loggedInUserId ログイン中のユーザID
 * @returns 表示用アイテムリストと更新後の最終既読メッセージID
 */
export const convertMessagesToDisplayItems = (
  messages: Chat.TimelineMessage[],
  isSuccessLastReadFetch: boolean,
  lastReadMessageIdsInChatRoomClan: { [key: string]: string },
  loadChatMessages: (params: LoadChatMessagesParams) => void,
  delayedUpdateLastReadMessageId: (
    unreadLineMessageId: string | undefined
  ) => void,
  currentSearchRange: SearchRange,
  loggedInUserId: string | undefined
): ConvertMessagesToDisplayItemsResult => {
  const items: DisplayMessageItem[] = []

  // 最古の未読メッセージのID。ここに未読ラインが置かれ、既読処理のトリガが設置される
  let firstUnreadMessageId: string | undefined

  messages.forEach((message, index) => {
    const prevMessage = index > 0 ? messages[index - 1] : undefined

    // このメッセージが最古の未読メッセージかどうか
    let isFirstUnreadMessage = false

    // 最古の未読メッセージ(既読処理のトリガ且つ未読ライン位置)である条件は、
    // ■1. 既読情報の取得が完了している
    // ■2. まだ最古の未読メッセージが見つかっていない
    // ■3. このメッセージが未読である
    // ■4. このメッセージが時間軸で最古であるか、1つ前のメッセージが既読であるか、1つ前のメッセージが自分の投稿である
    // なお、古いメッセージからループ処理していることが前提

    // メッセージが自分の投稿でない場合のみ「最古の未読メッセージ」の判定処理を行う
    // 自分の投稿の場合は未読として扱わず、既読処理も行わない
    if (
      message.createdById !== undefined &&
      message.createdById !== loggedInUserId
    ) {
      if (
        // ■1. 既読情報の取得が完了している
        isSuccessLastReadFetch &&
        // ■2. まだ最古の未読メッセージが見つかっていない
        firstUnreadMessageId === undefined
      ) {
        // このメッセージが未読かどうか
        // 1. このメッセージの属するチャットルームに既読位置が設定されている
        // 2. 且つ、既読位置より新しいメッセージである
        const isUnreadMessage =
          lastReadMessageIdsInChatRoomClan[message.chatRoomId] &&
          compareUlid(
            lastReadMessageIdsInChatRoomClan[message.chatRoomId],
            '<',
            message.id
          )

        // このメッセージが時間軸で最古のメッセージかどうか
        // 1. このメッセージの前にメッセージがない
        // 2. 且つ、古い方向に追加読込する必要がない
        const isOldestMessage =
          message.prevId === undefined && !message.hasMorePrev

        // 1つ前のメッセージが既読かどうか
        // 1. 1つ前のメッセージが存在している
        // 2. 且つ、1つ前のメッセージの属するチャットルームに既読位置が設定されていない(チャットルーム内すべて既読)か、既読位置以前のメッセージである
        const isReadPrevMessage =
          prevMessage &&
          (lastReadMessageIdsInChatRoomClan[prevMessage.chatRoomId] ===
            undefined ||
            compareUlid(
              lastReadMessageIdsInChatRoomClan[prevMessage.chatRoomId],
              '>=',
              prevMessage.id
            ))

        // 1つ前のメッセージが自分の投稿かどうか
        //   自分の投稿は既読処理が走らず、最終既読メッセージIDとの比較上は未読扱いされてしまうため、
        //   isReadPrevMessageの判定で拾うことができないので、1つ前のメッセージが自分の投稿かどうかも判定に使用する必要がある
        const isPrevMyMessage =
          prevMessage && prevMessage.createdById === loggedInUserId

        if (
          // ■3. このメッセージが未読である
          isUnreadMessage &&
          // ■4. このメッセージが時間軸で最古であるか、1つ前のメッセージが既読であるか、1つ前のメッセージが自分の投稿である
          (isOldestMessage || isReadPrevMessage || isPrevMyMessage)
        ) {
          firstUnreadMessageId = message.id
          isFirstUnreadMessage = true
        }
      }
    }

    // このメッセージで分割ラインを表示するか
    const showDivideLine = isNeedDivideLine(prevMessage, message)

    // 追加読込の方向を決定する。必要無ければundefined
    const additionalLoadingDirection =
      message.hasMorePrev && message.hasMoreNext // 時間軸上で両方向の隣のメッセージが未読込だった場合はboth
        ? TimelineDirection.BothNewerAndOlder
        : message.hasMorePrev // 時間軸上でprev方向の隣のメッセージが未読込だった場合はolder
        ? TimelineDirection.Older
        : message.hasMoreNext // 時間軸上でnext方向の隣のメッセージが未読込だった場合はnewer
        ? TimelineDirection.Newer
        : undefined // 両方向の隣のメッセージが読込済だった場合は追加読込は不要

    // 追加読込処理を行う関数を生成する。必要無ければundefined
    const handleAdditionalLoading = additionalLoadingDirection
      ? () =>
          loadChatMessages({
            filter: currentSearchRange,
            criterionMessageId: message.id,
            direction: additionalLoadingDirection,
          })
      : undefined

    // 最初の未読アイテムが表示されたときの処理を行う関数を生成する。必要無ければundefined
    const handleFirstUnreadMessageViewed = isFirstUnreadMessage
      ? () => {
          delayedUpdateLastReadMessageId(firstUnreadMessageId)
        }
      : undefined

    if (message.topicId === undefined) {
      // スレッドの親
      items.push({
        id: `${MessageItemType.NormalMessage}_${message.id}`,
        type: MessageItemType.NormalMessage,
        messageId: message.id,
        topicId: message.id,
        showDivideLine,
        showUnreadLine: isFirstUnreadMessage,
        isFirstUnreadMessage,
        deleted: message.deleted,
        handleAdditionalLoading,
        handleFirstUnreadMessageViewed,
      })
    } else {
      // スレッドの子(reply)
      items.push({
        id: `${MessageItemType.ReplyMessage}_${message.id}`,
        type: MessageItemType.ReplyMessage,
        messageId: message.id,
        topicId: message.topicId,
        showDivideLine,
        showUnreadLine: isFirstUnreadMessage,
        isFirstUnreadMessage,
        deleted: message.deleted,
        handleAdditionalLoading,
        handleFirstUnreadMessageViewed,
      })
    }
  })

  return { items, unreadLineMessageId: firstUnreadMessageId }
}

//**************************************************** utilities ***********************************************************/

/**
 * 2つのメッセージが同じスレッドか判定する。片方が片方の親、または兄弟(同じ親を持つ)なら同一スレッドとする
 * @param a
 * @param b
 * @returns
 */
const isSameThread = (
  a: Chat.TimelineMessage,
  b: Chat.TimelineMessage
): boolean => {
  if (a.topicId === b.id) {
    // aの親がb
    return true
  }
  if (a.id === b.topicId) {
    // bの親がa
    return true
  }
  if (a.topicId !== undefined && a.topicId === b.topicId) {
    // aとbが兄弟(=親がいて、且つ親が同じ)
    return true
  }

  // その他は他人
  return false
}

/**
 * 区切り線を表示する必要があるかどうかを返す関数
 * @param prevMessage 前のメッセージ。ない場合はundefinedとなり、区切り線は表示しない
 * @param currentMessage 現在のメッセージ
 * @returns
 */
export const isNeedDivideLine = (
  prevMessage: Chat.TimelineMessage | undefined,
  currentMessage: Chat.TimelineMessage
) => {
  // 一番最初のメッセージでなく(=1つ以上前のメッセージがある)、且つスレッド切り替わりの場合は分割ラインを挟む
  return prevMessage !== undefined && !isSameThread(currentMessage, prevMessage)
}

export const useChatTimelineMessageList = () => {
  // 処理対象のチャットルームをViewModelから取得
  const currentChatRoom = useAppSelector(
    (state) => state.message.chat.current.chatRoom
  )
  // この処理が流れる際、ViewModelには必ずチャットルームが設定されているはずなので、未設定の場合はエラーとする
  if (!currentChatRoom) {
    throw new Error('currentChatRoom is undefined')
  }

  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const params = qs.parse(searchParams.toString())

  // メッセージ表示領域のref
  const itemsScrollableDivRef = useRef<HTMLDivElement>(null)

  // 取得したチャットメッセージをSliceにキャッシュする関数を取得
  const chatMessageService = useChatMessageService(dispatch)

  // Sliceの操作を行うためのServiceを取得
  const chatTimelineService = useChatTimelineService(dispatch)

  // チャットメッセージ取得API
  const [lazyGetChatRoomMessagesQuery, lazyGetChatRoomMessagesQueryResult] =
    useLazyGetChatRoomMessagesQuery()

  // チャットメッセージ取得API
  const [lazyGetChatMessageQuery] = useLazyGetChatMessageQuery()

  // 画面遷移前のスクロール位置
  const scrollToMessageId = useAppSelector(
    (state) =>
      state.message.chat.timeline.entities[currentChatRoom.id]
        ?.scrollToMessageId
  )

  // 検索機能やアテンションなどで選択されたメッセージID
  const selectedMessageId = useAppSelector(
    (state) =>
      state.message.chat.timeline.entities[currentChatRoom.id]
        ?.selectedMessageId
  )

  const {
    lastReadMessageIdOnCurrentChatRoom,
    lastReadMessageIdsInChatRoomClan,
    forceUpdateLastReadMessageId,
    updateLastReadMessageId,
    isSuccessLastReadFetch,
    setDisplayingItems,
    updateNewestViewedMessageId,
    reset: resetLastReadMessageHook,
  } = useChatRoomLastReadMessage(currentChatRoom.id)

  // Reduxに格納されている対象チャットルームのメッセージのDictionaryを取得する
  const chatTimelineMessageDictionary = useAppSelector(
    (state) =>
      state.message.chat.timeline.entities[currentChatRoom.id]?.messages
        .entities
  )
  // Reduxに格納されている対象チャットルームのメッセージのIDリストを取得する
  // Reduxに格納されている順番で取得する必要があるので、entitiesだけでなくidsも取得しておく必要がある
  const chatTimelineMessageIds = useAppSelector(
    (state) =>
      state.message.chat.timeline.entities[currentChatRoom.id]?.messages.ids
  )
  // 表示対象のメッセージを返す
  const chatTimelineMessages = useMemo(() => {
    if (!chatTimelineMessageIds || !chatTimelineMessageDictionary) {
      return []
    }
    // id順にメッセージを設定する
    return chatTimelineMessageIds.reduce(
      (result: Chat.TimelineMessage[], id) => {
        const message = chatTimelineMessageDictionary[id]
        if (message) {
          result.push(message)
        }
        return result
      },
      []
    )
  }, [chatTimelineMessageDictionary, chatTimelineMessageIds])

  // Reduxに格納されている対象チャットタイムラインの取得範囲を取得する
  const chatTimelineSearchRange = useAppSelector(
    (state) =>
      state.message.chat.timeline.entities[currentChatRoom.id]?.searchRange
  )

  // チャットメッセージの表示範囲の個人設定値
  const defaultDisplayRange = useUserSetting(SettingKeyType.ChatDisplayRange)

  // ユーザ設定によるデフォルト検索範囲
  const defaultSearchRange =
    defaultDisplayRange === null ||
    defaultDisplayRange === UserChatSettingDisplayRange.DisplayAll.value
      ? SearchRange.AllMessage
      : SearchRange.OnlyThisRoom

  // 今現在表示に使われている取得範囲
  const [currentSearchRange, setCurrentPrevSearchRange] = useState(
    chatTimelineSearchRange ?? defaultSearchRange
  )

  /**
   * 複数アイテムをViewModelに追加する
   * @param params
   */
  const addItems = useCallback(
    (params: AddMessagesParams) => {
      // ViewModelへデータを追加
      const parameter = {
        chatRoomId: currentChatRoom.id,
        messages: params.chatMessages,
        criterionMessageId: params.criterionMessageId,
        direction: params.direction,
        isLoadedOfEndOfSourceItems: params.isLoadedOfEndOfMessages,
      }
      chatTimelineService.addChatTimelineMessages(parameter)
    },
    [chatTimelineService, currentChatRoom.id]
  )

  /**
   * API経由で取得したチャットメッセージをメッセージのキャッシュとViewModelに追加する
   */
  const addMessages = useCallback(
    (params: AddMessagesParams) => {
      // キャッシュへ追加
      chatMessageService.addChatMessagesToCache({
        chatMessages: params.chatMessages,
      })
      // ViewModelへ追加
      addItems({
        chatMessages: params.chatMessages,
        criterionMessageId: params.criterionMessageId,
        direction: params.direction,
        isLoadedOfEndOfMessages: params.isLoadedOfEndOfMessages,
      })
    },
    [addItems, chatMessageService]
  )

  // 一度にfetchするサイズ
  const fetchLimit = useFetchLimit(MESSAGE_AREA_MIN_HEIGHT, getWindowDimensions)

  /**
   * チャットメッセージをロードするメソッド
   * @param params 対象メッセージを絞り込むためのパラメータ
   * @returns
   */
  const loadChatMessages = useCallback(
    async (params: LoadChatMessagesParams) => {
      const request: GetChatRoomMessagesRequest = {
        filter: params.filter,
        keyword: '',
        chatRoomId: currentChatRoom.id,
        threadRootOnly: false, // タイムライン形式のためスレッドルートだけでなくすべて取得する
        threadRootMessageId: undefined, // タイムライン形式のため特定スレッド内だけでなくすべて取得する
        normalMessageOnly: undefined, // message_typeでの絞り込みは行わないのでundefinedを指定する
        criterionMessageId: params.criterionMessageId,
        direction: params.direction,
        limit: fetchLimit,
      }

      try {
        const data = await lazyGetChatRoomMessagesQuery(request).unwrap()

        const isLoadedOfEndOfMessages = request.limit > data.chatMessages.length

        const messagesAddedPayload: AddMessagesParams = {
          chatMessages: data.chatMessages,
          ...request, // 型が違うがduck typeによって代入可能
          isLoadedOfEndOfMessages,
        }

        if (request.chatRoomId === currentChatRoom.id) {
          addMessages(messagesAddedPayload)
        } else {
          console.info(
            `[useChatTimeline] Chat room id mismatch. current:${currentChatRoom.id} recv:${request.chatRoomId}`
          )
        }
      } catch (err) {
        // TODO: CREW-13720の対応で、プロジェクトから退出した際にエラートーストが出てしまう問題が発生し、トースト表示をコメントアウトする暫定対応を行った
        // ただ、これにより追加ロード時にエラーがあっても画面上表示されないという状態であるため、以下タスクで恒久対応を行う
        // 現時点ではコンソールにエラーを表示するにとどめる
        // https://break-tmc.atlassian.net/browse/CREW-13724
        // toast.error(t('message.general.failedToRetrieveData'))
        console.error(err)
      }
    },
    [currentChatRoom.id, fetchLimit, lazyGetChatRoomMessagesQuery, addMessages]
  )

  // 既読更新を遅延実行させるためcustom hookでwrapする
  const delayedUpdateLastReadMessageId = useProcessInLaterRenderingWithParams(
    (unreadLineMessageId: string | undefined) =>
      updateLastReadMessageId(
        unreadLineMessageId,
        currentSearchRange === SearchRange.OnlyThisRoom
      ),
    [currentSearchRange, updateLastReadMessageId],
    1
  )

  // ログイン中のユーザ情報
  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)

  // チャット表示用アイテムリスト
  const { items: displayItems } = useMemo(
    () =>
      convertMessagesToDisplayItems(
        chatTimelineMessages,
        isSuccessLastReadFetch,
        lastReadMessageIdsInChatRoomClan,
        loadChatMessages,
        delayedUpdateLastReadMessageId,
        currentSearchRange,
        loggedInUser?.id
      ),
    [
      chatTimelineMessages,
      currentSearchRange,
      delayedUpdateLastReadMessageId,
      isSuccessLastReadFetch,
      lastReadMessageIdsInChatRoomClan,
      loadChatMessages,
      loggedInUser?.id,
    ]
  )

  // すべてのアイテムが既読状態かどうか
  const isAllItemsRead = useMemo(() => {
    if (chatTimelineMessages.length === 0) {
      // 表示するアイテムがない=未読アイテムもない=すべて既読
      return true
    }

    if (lastReadMessageIdOnCurrentChatRoom === undefined) {
      // 既読アイテムIDが未設定=すべて既読
      return true
    }

    // chat系のメッセージは昇順なので、最新のアイテムは配列の最後にある
    const newestItem = chatTimelineMessages[chatTimelineMessages.length - 1]

    if (newestItem.hasMoreNext) {
      // 表示対象アイテムの最新のものに次がある=表示されていない未読アイテムがまだある=すべて既読ではない
      return false
    }

    if (compareUlid(lastReadMessageIdOnCurrentChatRoom, '<', newestItem.id)) {
      // 最終既読アイテムIDが最新のアイテムよりも前=少なくとも最新アイテムは未読=すべて既読ではない
      return false
    }

    // 表示するアイテムが存在し、既読アイテムがあり、最新のアイテムに次がなく、かつ最終既読アイテムIDが最新のアイテム以降=すべて既読
    return true
  }, [chatTimelineMessages, lastReadMessageIdOnCurrentChatRoom])

  // WebSocketでメッセージを受信したときに呼ばれるイベントハンドラ
  const handleRecvMessageByWebsocket = useCallback(
    (message: ChatMessage) => {
      console.info(`[Chat] Recv message via websocket. ${message.id}`)

      // すべてのメッセージが既読状態で、且つスクロール位置が最新側の端だった場合には、受信したメッセージを即座に既読とする。
      // このハンドラはレンダリング前のタイミングで呼ばれるため、未読ラインの表示前に既読扱いされるので未読ラインは表示されない。
      //
      // スクロール位置が最新側の端でない場合、古いメッセージを表示している状態であり、受信メッセージは画面上に表示されないため、既読にしない。
      // この場合は通常通り未読ラインが表示され、スクロールをトリガーとして既読更新される。

      // 表示欄のスクロール位置を取得するためrefを使うが、最初のDOMができるまでnullであるため最初にnullチェックを行う
      const container = itemsScrollableDivRef.current
      if (container === null) {
        return
      }

      if (isAllItemsRead && container.scrollTop === 0) {
        console.info(
          `[Chat] updateLastReadMessageId: ${message.id} trigger by websocket`
        )
        forceUpdateLastReadMessageId(
          message.id,
          currentSearchRange === SearchRange.OnlyThisRoom
        )
      }
    },
    [currentSearchRange, forceUpdateLastReadMessageId, isAllItemsRead]
  )

  // チャット関連のメッセージをwebsocket経由でsubscribeする
  useSubscribeChat(
    currentChatRoom.id,
    handleRecvMessageByWebsocket,
    dispatch,
    useAppSelector
  )

  // アイテムが領域内に表示された
  const messageInView = useCallback(
    (id: string, messageId: string) => {
      // 今現在画面に表示しているアイテムのリストに追加する。
      // 既読更新時に、未読ラインを越えてより古いメッセージを見ているか(≒既読更新すべきか)の判定対象になる
      setDisplayingItems((prev) => [
        ...prev.filter((m) => m.id !== id),
        { id, messageId },
      ])

      // 既読更新時の最終既読IDとして使うため、画面に表示した事のあるアイテムの中で一番大きいIDを記録しておく
      updateNewestViewedMessageId(messageId)

      // スクロール位置を戻すための対象messageIdを保持しておく
      chatTimelineService.setTimelineScrollToMessageId({
        chatRoomId: currentChatRoom.id,
        chatMessageId: messageId,
      })
    },
    [
      chatTimelineService,
      currentChatRoom.id,
      setDisplayingItems,
      updateNewestViewedMessageId,
    ]
  )

  // アイテムが領域外に移動して非表示になった
  const messageOutOfView = useCallback(
    (id: string) => {
      // 消えたアイテムをリストから除去する
      setDisplayingItems((prev) => prev.filter((elem) => elem.id !== id))
    },
    [setDisplayingItems]
  )

  // 指定したメッセージIDのメッセージを表示領域内にスクロールする
  const scrollToMessage = useCallback(
    (messageId: string | undefined) => {
      // messageIdがなければ処理しない
      if (messageId === undefined) {
        // スクロールする必要がないため、スクロール成功として扱う
        return true
      }

      // スクロール対象のDOM要素
      const displayTargetElement = itemsScrollableDivRef.current?.querySelector(
        `#${getChatMessageListItemDomId(messageId, ChatView.Timeline)}`
      )
      // スクロール対象のメッセージを表示中アイテムの中から取得する
      const item = displayItems.find((item) => item.messageId === messageId)
      // スクロール対象のメッセージが表示アイテムの中にあり、スクロール対象のDOM要素がある場合のみ処理を行う
      if (item && displayTargetElement) {
        // 表示対象のDOM要素を表示領域内にスクロールする
        displayTargetElement.scrollIntoView({
          behavior: 'auto',
          block: 'center',
        })
      } else {
        // スクロール失敗
        return false
      }

      return true
    },
    [displayItems]
  )

  useEffectOnce(() => {
    // 他画面への遷移やInThreadから戻った際に、スクロール位置を元の位置に戻すための処理
    scrollToMessage(scrollToMessageId)
    // 追加読み込みが走るように、最新のメッセージのhasMoreNextを強制的にtrueにする
    chatTimelineService.forceUpdateTimelineMessageHasMoreNext({
      chatRoomId: currentChatRoom.id,
    })
  })

  // URLパラメーターのMessageIdを適用済みかどうか
  const [alreadyLoadedParamMessageId, setAlreadyLoadedParamMessageId] =
    useState<boolean>(false)

  // URLパラメーターのMessageIdを変数に格納する
  // 依存に直接paramsを入れると無限ループが発生してしまうため
  const messageIdInUrlParameter = params.messageId

  // 「リンクをコピー」などでURLパラメータにmessageIdが含まれていた場合、選択されたメッセージIDとしてsliceに保存する
  useValueChangeEffect(
    () => {
      // FIXME: スレッドリスト表示とは異なり、ここでmessageIdに紐づくメッセージのフェッチは本来不要だが、初期ロードを含めた
      // 意図しないタイミングで初期ロード側のuseValueChangeEffectが実行されてスレッド表示から戻せない問題があるため、
      // スレッドリスト表示のロジックに寄せたワークアラウンドを行っている。CREW-17556でリファクタ予定
      // https://break-tmc.atlassian.net/browse/CREW-17556
      async function fetchData(currentChatRoomId: string) {
        if (
          // URLパラメータにmessageIdが含まれている
          messageIdInUrlParameter &&
          // URLパラメータのmessageIdがまだ適用されていない
          !alreadyLoadedParamMessageId &&
          // スクロール対象のメッセージIDが設定されていない
          //   設定されている場合は初期表示処理の方で前後ロードされるためこちらで処理する必要はなく、
          //   ここで処理してしまうとURLパラメータのmessageIdによる制御が優先されてしまい、
          //   スレッド表示から戻ったときに必ずURLパラメータのmessageIdの位置にスクロールされてしまう
          scrollToMessageId === undefined &&
          // 検索やアテンションから選択されたメッセージも同様
          //   これを排除しないと、コンパクト表示時で返信をスレッド表示後、戻る操作をしてもタイムライン表示に戻せなくなる
          selectedMessageId === undefined
        ) {
          const data = await lazyGetChatMessageQuery({
            messageId: String(messageIdInUrlParameter),
          }).unwrap()

          if (data?.chatMessage) {
            // 対象メッセージにスクロールする
            chatTimelineService.setTimelineSelectedMessageId({
              chatMessageId: String(messageIdInUrlParameter),
              chatRoomId: currentChatRoomId,
            })
          }
          setAlreadyLoadedParamMessageId(true)
        }
      }
      fetchData(currentChatRoom.id)
    },
    [
      alreadyLoadedParamMessageId,
      chatTimelineService,
      currentChatRoom.id,
      lazyGetChatMessageQuery,
      messageIdInUrlParameter,
      scrollToMessageId,
      selectedMessageId,
    ],
    messageIdInUrlParameter
  )

  // 追加読み込み後、特定メッセージへのスクロールが必要か
  const [
    requiredScrollAfterAdditionalLoad,
    setRequiredScrollAfterAdditionalLoad,
  ] = useState<boolean>(false)

  // 検索機能やアテンションなどでメッセージが選択されたら、選択されたメッセージにスクロール位置を移動する処理
  useValueChangeEffect(
    () => {
      // メッセージを表示領域内にスクロールする
      const scrollSucceeded = scrollToMessage(selectedMessageId)

      if (scrollSucceeded) {
        // スクロール成功時：メッセージIDをリセットする
        chatTimelineService.resetTimelineSelectedMessageId({
          chatRoomId: currentChatRoom.id,
        })
      } else {
        // スクロール失敗時：スクロール対象のメッセージが表示されていないため、スクロール失敗している。
        //                 そのため追加読み込みを行い、再度スクロールを行う。

        // メッセージ選択によるスクロールを有効にする
        setRequiredScrollAfterAdditionalLoad(true)

        // スクロール対象メッセージ付近を表示するために追加読み込みを行う
        loadChatMessages({
          filter: currentSearchRange,
          criterionMessageId: selectedMessageId,
          direction: TimelineDirection.BothNewerAndOlder,
        })
      }
    },
    [
      chatTimelineService,
      currentChatRoom.id,
      currentSearchRange,
      loadChatMessages,
      scrollToMessage,
      selectedMessageId,
    ],
    selectedMessageId
  )

  // スクロール対象メッセージが読み込まれたかどうか
  const isLoadedScrollTarget = useMemo(
    () =>
      selectedMessageId !== undefined
        ? displayItems.some((item) => item.messageId === selectedMessageId)
        : undefined,
    [displayItems, selectedMessageId]
  )

  // 検索機能やアテンションなどでメッセージが選択されたら、選択されたメッセージにスクロール位置を移動する処理
  //   選択したメッセージがdisplayItemsに含まれていない場合、追加読み込み後に再度スクロールを行う必要があるため、
  //   ここではその追加読み込み後の再スクロール処理を行っている
  useValueChangeEffect(
    () => {
      // 追加読み込み後にスクロール処理が必要 かつ スクロール対象メッセージが表示対象に含まれている場合、スクロールを行う
      if (requiredScrollAfterAdditionalLoad && isLoadedScrollTarget) {
        const scrollSucceeded = scrollToMessage(selectedMessageId)

        if (scrollSucceeded) {
          // メッセージ選択によるスクロールを無効にする
          setRequiredScrollAfterAdditionalLoad(false)

          // メッセージIDをリセットする
          chatTimelineService.resetTimelineSelectedMessageId({
            chatRoomId: currentChatRoom.id,
          })
        }
      }
    },
    [
      requiredScrollAfterAdditionalLoad,
      isLoadedScrollTarget,
      scrollToMessage,
      selectedMessageId,
      chatTimelineService,
      currentChatRoom.id,
    ],
    isLoadedScrollTarget
  )

  // 表示対象のチャットルームが変わったら初期ロードを行う
  useValueChangeEffect(
    () => {
      // 初期化処理が実行されていない場合のみ処理する
      resetLastReadMessageHook()
      loadChatMessages({
        filter: currentSearchRange,
        // スクロール対象メッセージIDを基準に新旧両方の方向にロードする
        criterionMessageId: selectedMessageId
          ? selectedMessageId
          : scrollToMessageId,
        direction: TimelineDirection.BothNewerAndOlder,
      })
    },
    [
      currentSearchRange,
      loadChatMessages,
      params.messageId,
      resetLastReadMessageHook,
      scrollToMessageId,
      selectedMessageId,
    ],
    currentChatRoom.id
  )

  // 検索範囲が変わった場合に再ロードする
  useValueChangeEffect(
    () => {
      if (
        !lazyGetChatRoomMessagesQueryResult.isFetching // チャットメッセージの取得中ではない
      ) {
        // 範囲が変更されたら再ロードする。範囲の保存と既存データの削除は関数内で行う
        if (
          chatTimelineSearchRange &&
          chatTimelineSearchRange !== currentSearchRange
        ) {
          setCurrentPrevSearchRange(chatTimelineSearchRange)
          // 検索範囲が変わった場合は、表示中のメッセージを全て削除してリロードする
          chatTimelineService.deleteAllChatTimelineMessages({
            chatRoomId: currentChatRoom.id,
          })
          loadChatMessages({
            filter: chatTimelineSearchRange,
            // スクロール対象メッセージIDを基準に新旧両方の方向にロードする
            criterionMessageId: scrollToMessageId,
            direction: TimelineDirection.BothNewerAndOlder,
          })
        }
      }
    },
    [
      chatTimelineSearchRange,
      chatTimelineService,
      currentChatRoom.id,
      currentSearchRange,
      lazyGetChatRoomMessagesQueryResult.isFetching,
      loadChatMessages,
      scrollToMessageId,
    ],
    chatTimelineSearchRange,
    true // 初回ロード時には実行しない（スキップする）
  )

  return {
    displayItems,
    itemsScrollableDivRef,
    messageInView,
    messageOutOfView,
  }
}
