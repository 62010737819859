import { apis } from '../apiBase/apiBase';
const chatApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        getChatMessage: builder.query({
            query: (params) => ({
                url: `chat/chat-messages/${params.messageId}`,
                method: 'GET',
            }),
        }),
        getChatRoomMessages: builder.query({
            query: (params) => {
                const fixedParams = Object.keys(params).reduce((prev, key) => {
                    if (params[key] !== undefined) {
                        prev = Object.assign(Object.assign({}, prev), { [key]: params[key] });
                    }
                    return prev;
                }, {});
                return {
                    url: `chat/chat-rooms/${params.chatRoomId}/chat-messages`,
                    method: 'GET',
                    params: fixedParams,
                };
            },
        }),
        insertChatMessage: builder.mutation({
            query: (params) => ({
                url: 'chat-messages/insert',
                method: 'POST',
                body: params,
            }),
        }),
        getChatRoomByEntity: builder.query({
            query: (params) => ({
                url: `chat/chat-rooms/entity`,
                method: 'GET',
                params: params,
            }),
        }),
        deleteChatMessage: builder.mutation({
            query: (params) => ({
                url: 'chat-messages/delete',
                method: 'POST',
                body: params,
            }),
        }),
        updateChatMessage: builder.mutation({
            query: (params) => ({
                url: 'chat-messages/update',
                method: 'POST',
                body: params,
            }),
        }),
        getChatRoom: builder.query({
            query: (params) => ({
                url: `chat/chat-rooms/${params.chatRoomId}`,
                method: 'GET',
            }),
        }),
        getActiveChatRooms: builder.query({
            query: (params) => ({
                url: `chat/active-chat-rooms`,
                method: 'GET',
            }),
        }),
        canAccessChatRoom: builder.query({
            query: (params) => ({
                url: `chat/chat-rooms/${params.chatRoomId}/can-access`,
                method: 'GET',
            }),
        }),
        markAllMessageAsRead: builder.mutation({
            query: (params) => ({
                url: 'chat-messages/mark-all-read',
                method: 'POST',
                body: params,
            }),
        }),
        getChatMessages: builder.query({
            query: (params) => ({
                // TODO: スプリント11.1リリース時の「チャットメッセージ横断検索」後方互換対応。
                // 後方互換を担保しなくて良くなった際には「cross-search/chat-messages」の方を除去して「chat/chat-messages」側を有効にする
                // https://break-tmc.atlassian.net/browse/CREW-12987
                url: `cross-search/chat-messages`,
                // url: `chat/chat-messages`,
                method: 'GET',
                params,
            }),
        }),
        getChatMessageReadStatus: builder.query({
            query: (params) => ({
                url: `chat/chat-messages/${params.messageId}/read-status`,
                method: 'GET',
            }),
        }),
    }),
    overrideExisting: false,
});
export const { useGetChatMessageQuery, useLazyGetChatMessageQuery, useLazyGetChatRoomMessagesQuery, useInsertChatMessageMutation, useLazyGetChatRoomByEntityQuery, useGetChatRoomByEntityQuery, useDeleteChatMessageMutation, useUpdateChatMessageMutation, useLazyGetChatRoomQuery, useGetChatRoomQuery, useGetActiveChatRoomsQuery, useLazyGetActiveChatRoomsQuery, useCanAccessChatRoomQuery, useLazyCanAccessChatRoomQuery, useMarkAllMessageAsReadMutation, useGetChatMessagesQuery, useLazyGetChatMessagesQuery, useGetChatMessageReadStatusQuery, useLazyGetChatMessageReadStatusQuery, } = chatApis;
