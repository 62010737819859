import { FC, Fragment, memo, useEffect, useRef, useState } from 'react'

import { ChatView } from '@crew/enums/app'
import { AiAssistantThreadMessageListItem } from './components/aiAssistantThreadMessageListItem/aiAssistantThreadMessageListItem'
import { useAiAssistantThreadMessageList } from './useAiAssistantThreadMessageList'
import { getChatMessageListItemDomId } from 'utils/chat'
import { useAppSelector } from 'states/hooks'
import { CrewDivider } from 'components/elements/crewDivider/crewDivider'

type AiAssistantThreadMessageListProps = {
  selectedItemId?: string | undefined // 選択中のメッセージ。ハイライト用に使用する
}

/**
 * AIアシスタント - スレッド形式のメッセージ一覧
 */
export const AiAssistantThreadMessageList: FC<AiAssistantThreadMessageListProps> =
  memo((props) => {
    const { displayItems, itemsScrollableDivRef } =
      useAiAssistantThreadMessageList()

    // AIアシスタントチャンネルで表示するトピックID
    const aiAssistantCurrentChatThread = useAppSelector(
      (state) => state.message.chat.aiAssistantChannel.currentChatThread
    )

    const [selectedItem, setSelectedItem] = useState<string | null>(null)

    // アイテムでない部分をクリックしたときに選択状態を解除するためのイベントハンドラの設定
    const itemContainerRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
      const handleGlobalClick = (ev: MouseEvent) => {
        const container = itemContainerRef.current
        const eventTarget = ev.target
        if (container === null || !(eventTarget instanceof Node)) {
          return
        }

        if (
          !container.contains(eventTarget) || // アイテムのコンテナ外をクリックした
          container === eventTarget // コンテナそのもの(アイテムがない部分)をクリックした
        ) {
          setSelectedItem(null)
        }
      }

      // コンテナ外のクリックを取得するためwindowグローバルなイベントハンドラを仕込む
      window.addEventListener('click', handleGlobalClick)
      return () => {
        // グローバルなイベントハンドラの後始末
        window.removeEventListener('click', handleGlobalClick)
      }
    }, [])

    // 表示に必要なデータが指定されていない場合、表示することができないので、何も表示しない
    if (!aiAssistantCurrentChatThread) {
      return null
    }

    return (
      <div
        className="grow flex overflow-y-scroll flex-col-reverse"
        ref={itemsScrollableDivRef}
      >
        <div className="grow flex flex-col" ref={itemContainerRef}>
          {/* スレッド表示を行う対象のトピック */}
          <AiAssistantThreadMessageListItem
            id="fixed_header" // 用途は特にないがListItem側でidを必須にする兼ね合いで設定
            itemId="fixed_header_item" // 同上
            chatMessageId={aiAssistantCurrentChatThread.topicId}
            selectedMessageId={props.selectedItemId}
            isSelected={selectedItem === 'fixed_header_item'}
            onClick={setSelectedItem}
          />

          {/* トピックと返信リストの間の区切り線 */}
          <CrewDivider />

          {/* 返信リスト */}
          {displayItems.map((item) => (
            <Fragment key={item.id}>
              <AiAssistantThreadMessageListItem
                key={item.id}
                // スクロール位置制御のためChatViewを含めたidとして指定する
                id={getChatMessageListItemDomId(
                  item.messageId,
                  ChatView.Thread
                )}
                itemId={item.id}
                chatMessageId={item.messageId}
                container={itemsScrollableDivRef}
                selectedMessageId={props.selectedItemId}
                isSelected={selectedItem === item.id}
                onAdditionalLoading={item.handleAdditionalLoading}
                onClick={setSelectedItem}
              />
            </Fragment>
          ))}
        </div>
      </div>
    )
  })
