import { FC, memo, useMemo } from 'react'
import { ChatMessageReference } from '@crew/models/domain'
import ChatOutline from '~icons/material-symbols/chat-outline'
import { CrewRelatedItemLink } from 'components/elements/crewRelatedItemLink/crewRelatedItemLink'
import { useGetChatMessageQuery } from '@crew/apis/chat/chatApis'
import { GetChatMessageRequest } from '@crew/apis/chat/models/getChatMessage/request'
import { skipToken } from '@reduxjs/toolkit/query'
import { useTranslation } from '@crew/modules/i18n'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'
import { CrewHtmlContent } from 'components/elements/crewHtmlContent/crewHtmlContent'
import { HTMLEDITOR_VIEW_STYLE } from 'configs/constants'
import classNames from 'classnames'
import { sanitizeHtml } from 'utils/html'

export type RagResponseReferenceChatMessageProps = {
  reference: ChatMessageReference // RAG応答表示に使用するチャットメッセージ参照
}

/**
 * RAG応答参照
 * チャットメッセージ
 */
export const RagResponseReferenceChatMessage: FC<RagResponseReferenceChatMessageProps> =
  memo((props) => {
    const { t } = useTranslation()

    // ユーザー設定からデフォルトのユーザープロファイル地域を取得
    const defaultUserProfileRegion = useUserSetting(
      SettingKeyType.UserProfileRegion,
      Region.Japan.value
    )

    // チャットメッセージを取得
    const getChatMessageParam: GetChatMessageRequest = {
      messageId: props.reference.entityRecordId,
    }
    const { data: getChatMessageResult } = useGetChatMessageQuery(
      getChatMessageParam ?? skipToken
    )

    const chatMessage = getChatMessageResult?.chatMessage

    // リンク下に表示するコンテンツ
    const displayHtmlContent = useMemo(() => {
      // 取得できていない場合は何も表示しない
      if (!chatMessage) {
        return ''
      }

      // 表示するコンテンツ
      let displayContent = ''
      // 本文があれば表示する内容に追加
      if (chatMessage.text !== null) {
        displayContent += chatMessage.text
      }

      // サニタイズして表示
      return sanitizeHtml(displayContent)
    }, [chatMessage])

    // ロードされるまでは何も表示しない
    if (!chatMessage) {
      return null
    }

    return (
      <div className="flex flex-row gap-x-1">
        {/* アイコン */}
        <div className="flex-shrink-0">
          <ChatOutline className="crew-text-gray-4" width={24} height={24} />
        </div>
        <div className="flex flex-col gap-y-1">
          <div className="flex flex-row gap-x-1 items-center">
            {/* 対象エンティティへのリンク */}
            <CrewRelatedItemLink
              className="text-md"
              entityType={chatMessage.chatRoom.entityType}
              id={chatMessage.chatRoom.entityRecordId}
              targetChatMessageId={chatMessage.id}
            />

            {/* チャットメッセージ更新日時 */}
            <span className="text-sm crew-text-gray-5">
              {t('format.timestamp', {
                value: chatMessage.updatedAt,
                timeZone: defaultUserProfileRegion,
              })}
            </span>
          </div>
          <div className={classNames(HTMLEDITOR_VIEW_STYLE, 'line-clamp-3')}>
            <CrewHtmlContent html={displayHtmlContent} />
          </div>
        </div>
      </div>
    )
  })
