import {
  useGetMeetingMinutesQuery,
  useUpdateMeetingMinutesMutation,
} from '@crew/apis/project/projectApis'
import { useTranslation } from '@crew/modules/i18n'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { memo, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { GetMeetingMinutesRequest } from '@crew/apis/project/models/getMeetingMinutes/request'
import { skipToken } from '@reduxjs/toolkit/query'
import { HTMLEDITOR_VIEW_STYLE } from 'configs/constants'
import { CrewHtmlContent } from 'components/elements/crewHtmlContent/crewHtmlContent'
import classNames from 'classnames'
import { CrewHtmlEditorField } from 'components/forms/crewHtmlEditorField'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import {
  ObjectEventMessage,
  notifyEventEvent,
} from 'features/app/states/appSlice'
import { NotifyEventType } from 'enums/app'
import { Event } from '@crew/apis/project/models/getMeetingMinutes/response'
import { useValueChangeEffect } from '@crew/hooks'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import { useEventDetailMeetingMinutesContext } from '../../../eventDetailMeetingMinutes'

import { useSubscribeMeetingMinutesCreated } from '@crew/providers/dist/websocket'
export type FormValues = {
  meetingMinutes: string | null
}

export const EventDetailMeetingMinutesPanelMeetingMinutes = memo(() => {
  const { eventId } = useParams()

  // この処理が流れる際、eventIdが存在しない場合は正しく描画できないはずなのでエラーを投げる
  // （以降の処理の型制約上もこちらの方が都合が良いはず）
  if (!eventId) {
    throw new Error('eventId is undefined')
  }

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const eventEventMessage = useAppSelector(
    (state) => state.app.eventEventMessage
  )
  const { isEditMinutes, setIsEditMinutes } =
    useEventDetailMeetingMinutesContext()

  const [
    updateMeetingMinutesMutation,
    { isLoading: isLoadingUpdateMeetingMinutes },
  ] = useUpdateMeetingMinutesMutation()

  const toast = useToast()
  const [showApiErrors] = useShowApiErrors()

  // react-hook-formの各種データを取得
  const { handleSubmit, control, reset } = useForm<FormValues>({
    criteriaMode: 'all',
  })

  // Get latest meeting minutes
  const useGetMeetingMinutesParam: GetMeetingMinutesRequest = {
    eventId: eventId,
  }

  const { data: getMeetingMinutesResult, refetch: getMeetingMinutesRefetch } =
    useGetMeetingMinutesQuery(useGetMeetingMinutesParam ?? skipToken)

  // The hook to refetch GetMeetingMinutes API when and event message is dispatched
  useValueChangeEffect(
    () => {
      if (eventId) {
        getMeetingMinutesRefetch()
      }
    },
    [eventId, getMeetingMinutesRefetch, eventEventMessage],
    eventEventMessage
  )

  // Initialize edit form data
  useEffect(() => {
    reset({
      meetingMinutes: getMeetingMinutesResult?.event?.meetingMinutes ?? '',
    })
  }, [getMeetingMinutesResult?.event?.meetingMinutes, reset])

  // Event handle when Save meeting minutes is clicked
  const handleSaveMeetingMinutesButtonClick = useCallback(() => {
    // react-hook-formのhandleSubmitに渡すコールバック関数を定義する
    const onSubmit = async (data: FormValues) => {
      if (
        !getMeetingMinutesResult?.event?.id ||
        !getMeetingMinutesResult?.event?.version
      ) {
        return
      }

      try {
        // Call API to update edited meeting minutes
        await updateMeetingMinutesMutation({
          event: {
            id: getMeetingMinutesResult?.event?.id,
            meetingMinutes: data.meetingMinutes ?? undefined,
            version: getMeetingMinutesResult?.event?.version,
          },
        }).unwrap()

        // Dispatch an event message to refetch GetMeetingMinutes API
        const objectEventMessage: ObjectEventMessage<Event> = {
          eventType: NotifyEventType.Updated,
          id: getMeetingMinutesResult?.event?.id,
          object: getMeetingMinutesResult?.event,
        }
        dispatch(notifyEventEvent(objectEventMessage))

        // Close edit form
        setIsEditMinutes(false)

        toast.success(t('message.meeting.meetingMinutesUpdated'))
      } catch (err) {
        console.error(err)
        showApiErrors(err)
      }
    }

    handleSubmit(onSubmit)()
  }, [
    dispatch,
    getMeetingMinutesResult?.event,
    handleSubmit,
    setIsEditMinutes,
    showApiErrors,
    t,
    toast,
    updateMeetingMinutesMutation,
  ])

  // Event handle when Cancel edit meeting minutes is clicked
  const handleCancelEditMeetingMinutesButtonClick = useCallback(() => {
    setIsEditMinutes(false)
  }, [setIsEditMinutes])

  // 議事録作成完了を受け取った際のコールバック関数
  const handleMeetingMinutesCreated = useCallback(() => {
    // 議事録作成完了を受け取ったら、GetMeetingMinutesを再実行して作成された議事録を取得する
    getMeetingMinutesRefetch()
  }, [getMeetingMinutesRefetch])

  // 議事録作成完了をsubscribeする
  useSubscribeMeetingMinutesCreated(eventId, handleMeetingMinutesCreated)

  return (
    <>
      {/* 議事録 */}
      <div className="pl-2 flex-1">
        {!isEditMinutes ? (
          // View mode
          <div className={classNames(HTMLEDITOR_VIEW_STYLE)}>
            <CrewHtmlContent
              html={getMeetingMinutesResult?.event?.meetingMinutes ?? ''}
            />
          </div>
        ) : (
          // Edit mode
          <form className="flex flex-col h-full">
            <div className="crew-slim-toolbar-item h-full">
              <CrewHtmlEditorField
                id="meetingMinutes"
                name="meetingMinutes"
                control={control}
                minHeight="10rem"
                fileUploaderDisabled={true}
                disabledMention={true}
                className="max-h-full"
              />
            </div>
            <div className="ml-auto flex gap-x-5">
              <CrewButton
                text={t('action.register')}
                type="primary"
                onClick={handleSaveMeetingMinutesButtonClick}
                disabled={isLoadingUpdateMeetingMinutes}
              />
              <CrewButton
                text={t('action.cancel')}
                type="normal"
                stylingMode="outlined"
                onClick={handleCancelEditMeetingMinutesButtonClick}
              />
            </div>
          </form>
        )}
      </div>
    </>
  )
})
