var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { apis } from '../apiBase/apiBase';
export const eventApis = apis.injectEndpoints({
    endpoints: (builder) => ({
        getEvents: builder.query({
            query: (params) => {
                return {
                    url: `events`,
                    method: 'GET',
                    params: params,
                };
            },
        }),
        downloadMeetingMinutesDocument: builder.query({
            query: (params) => {
                return {
                    url: `events/${params.eventId}/meeting-minutes/download`,
                    method: 'GET',
                    responseType: 'blob',
                    responseHandler: (response) => __awaiter(void 0, void 0, void 0, function* () { return yield response.blob(); }),
                };
            },
            transformResponse: (response, meta) => {
                /** レスポンスヘッダーからファイル名を取得する必要があるため、APIレスポンスをカスタマイズする */
                var _a;
                // レスポンスヘッダーからファイル名を取得
                const headers = (_a = meta === null || meta === void 0 ? void 0 : meta.response) === null || _a === void 0 ? void 0 : _a.headers;
                const contentDisposition = headers === null || headers === void 0 ? void 0 : headers.get('content-disposition');
                const fileNameMatch = contentDisposition === null || contentDisposition === void 0 ? void 0 : contentDisposition.match(/filename="?(.+?)"?$/);
                const filename = fileNameMatch
                    ? decodeURIComponent(fileNameMatch[1])
                    : 'download.docx';
                return {
                    blob: response,
                    fileName: filename,
                };
            },
        }),
        createMeetingMinutesAutomatic: builder.mutation({
            query: (params) => {
                return {
                    url: 'events/meeting-minutes/create-automatic',
                    method: 'POST',
                    body: params,
                };
            },
        }),
    }),
    overrideExisting: false,
});
export const { useGetEventsQuery, useLazyGetEventsQuery, useLazyDownloadMeetingMinutesDocumentQuery, useCreateMeetingMinutesAutomaticMutation, } = eventApis;
