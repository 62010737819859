import { memo, useCallback, useEffect, useMemo } from 'react'
import { useGetEventsQuery } from '@crew/apis/event/eventApis'
import { SettingKeyType } from '@crew/enums/app'
import { useTranslation } from '@crew/modules/i18n'
import { DEFAULT_PAGING_PARAMS } from 'configs/constants'
import { EventSearchOptions } from 'enums/app'
import qs from 'qs'
import { useSearchParams, useNavigate } from 'react-router-dom'
import {
  getParamAsString,
  getParamAsArray,
  getParamAsDate,
  createEventsGroupMap,
} from 'utils'
import { GetEventsRequest } from '@crew/apis/event/models/getEvents/request'
import { skipToken } from '@reduxjs/toolkit/query'
import { useUserSetting } from '@crew/states'
import { CrewPagination } from 'components/elements/crewPagination/crewPagination'
import _ from 'lodash'
import { useAppSelector } from 'states/hooks'
import { CrewEventItem } from 'components/elements/crewEventItem/crewEventItem'

export const EventListPanel = memo(() => {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  const params = qs.parse(searchParams.toString())
  const navigate = useNavigate()
  const eventEventMessage = useAppSelector(
    (state) => state.app.eventEventMessage
  )

  const defaultListDisplayNumber = useUserSetting(
    SettingKeyType.ListDisplayNumber,
    DEFAULT_PAGING_PARAMS.pageSize
  )

  const pagination = useMemo(
    () => ({
      pageIndex: Number(
        getParamAsString('pageIndex', params) ?? DEFAULT_PAGING_PARAMS.pageIndex
      ),
      pageSize: Number(
        getParamAsString('pageSize', params) ?? defaultListDisplayNumber
      ),
    }),
    [defaultListDisplayNumber, params]
  )

  // get param by url
  const getEventsParams: GetEventsRequest = {
    keyword: getParamAsString(EventSearchOptions.Keyword.id, params),
    projectId: undefined,
    keywordFilterCondition: undefined,
    attendeeId: getParamAsString(EventSearchOptions.AttendeeId.id, params),
    eventKindIds: getParamAsArray(EventSearchOptions.EventKindId.id, params),
    // TODO: Web: イベント分類の非表示対応
    // https://break-tmc.atlassian.net/browse/CREW-15049
    eventCategoryIds: undefined,
    startDate: getParamAsDate(EventSearchOptions.StartDate.id, params),
    endDate: getParamAsDate(EventSearchOptions.EndDate.id, params),
    createdAt: getParamAsDate(EventSearchOptions.CreatedAt.id, params),
    updatedAt: getParamAsDate(EventSearchOptions.UpdatedAt.id, params),
    createdById: getParamAsString(EventSearchOptions.CreatedById.id, params),
    updatedById: getParamAsString(EventSearchOptions.UpdatedById.id, params),
    limit: pagination.pageSize,
    offset: pagination.pageIndex * pagination.pageSize,
  }

  const { data: getEventsResult, refetch: getEventsRefetch } =
    useGetEventsQuery(getEventsParams ?? skipToken)

  useEffect(() => {
    getEventsRefetch()
  }, [getEventsRefetch, eventEventMessage])

  const eventsGroupMap = useMemo(() => {
    return createEventsGroupMap(getEventsResult?.events ?? [])
  }, [getEventsResult?.events])

  const totalCount = useMemo(
    () => getEventsResult?.totalCount ?? 0,
    [getEventsResult?.totalCount]
  )

  // handle change pagination grid mode
  const handlePaginationGridChange = useCallback(
    (pageIndex: number, pageSize: number) => {
      const newParams = {
        ...params,
        pageIndex,
        pageSize,
      }

      // paramsが変わっていない場合はnavigateしない
      if (_.isEqual(params, newParams)) return

      const newQueryString = qs.stringify(newParams, {
        arrayFormat: 'repeat',
        skipNulls: true,
      })

      navigate(`?${newQueryString}`)
    },
    [navigate, params]
  )

  return (
    <div className="flex flex-col overflow-hidden p-2">
      <CrewPagination
        pageSize={pagination.pageSize}
        pageIndex={pagination.pageIndex}
        pageCount={Math.ceil(totalCount / pagination.pageSize)}
        onPaginationChange={handlePaginationGridChange}
      />

      <div className="flex flex-col gap-2.5 overflow-auto">
        {Object.keys(eventsGroupMap).map((key) => {
          return (
            <div className="flex flex-col gap-2.5" key={key}>
              <div className="font-bold py-1 px-1.5 crew-bg-gray-1 rounded">
                {t('format.yearMonthWithText', {
                  value: key,
                })}
              </div>

              {eventsGroupMap[key].map((event) => (
                <CrewEventItem
                  event={event}
                  key={event.id}
                  displayRelatedName={true}
                />
              ))}
            </div>
          )
        })}
      </div>

      <CrewPagination
        pageSize={pagination.pageSize}
        pageIndex={pagination.pageIndex}
        pageCount={Math.ceil(totalCount / pagination.pageSize)}
        onPaginationChange={handlePaginationGridChange}
      />
    </div>
  )
})
