import { useInsertProjectForDirectChannelMutation } from '@crew/apis/project/projectApis'
import { Project } from '@crew/models/domain'
import { NotifyEventType } from 'enums/app'
import {
  notifyDirectChannelEvent,
  ObjectEventMessage,
} from 'features/app/states/appSlice'
import { useCallback } from 'react'
import { useAppDispatch } from 'states/hooks'

export const useUserDetailPanel = () => {
  const dispatch = useAppDispatch()

  const [
    insertProjectForDirectChannelMutation,
    { isLoading: isLoadingInsertProjectForDirectChannelMutation },
  ] = useInsertProjectForDirectChannelMutation()

  // create direct channel
  const createDirectChannel = useCallback(
    async (targetUserId: string) => {
      // Insert direct channel
      const response = await insertProjectForDirectChannelMutation({
        targetUserId,
      }).unwrap()

      if (response.directChannel) {
        const objectEventMessage: ObjectEventMessage<Project> = {
          eventType: NotifyEventType.Inserted,
          id: response.directChannel.id,
          object: response.directChannel,
        }
        dispatch(notifyDirectChannelEvent(objectEventMessage))
      }

      return response
    },
    [dispatch, insertProjectForDirectChannelMutation]
  )

  return {
    createDirectChannel,
    isLoadingInsertProjectForDirectChannelMutation,
  }
}
