import { SVGProps } from 'react'

/**
 * AIアシスタントアイコン
 * @param props SVGのプロパティ
 * @returns
 */
export const CrewAiAssistantIcon: (
  props: SVGProps<SVGSVGElement>
) => React.ReactElement = (props) => {
  // TODO: 現在は直接SVGを返すコンポーネントとするが、以下タスクでvite移行後にvite-plugin-svgrを使用するよう変更予定
  // https://break-tmc.atlassian.net/browse/CREW-18948

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width={props.width}
      height={props.height}
      viewBox={`0 0 150.34375 150.34375`}
    >
      <defs id="defs4184">
        <clipPath id="clipPath4194">
          <path
            d="m 0,120.275 120.275,0 L 120.275,0 0,0 0,120.275 Z"
            id="path4196"
          />
        </clipPath>
      </defs>
      <g id="g4188" transform="matrix(1.25,0,0,-1.25,0,150.34375)">
        <g id="g4192" clip-path="url(#clipPath4194)">
          <g id="g4198" transform="translate(102.4304,70.8515)">
            <path
              d="m 0,0 0,-17.526 0,-20.536 -78.396,0 -6.071,-5.939 0,5.939 0,63.35 36.205,0 17.115,0 0.637,0 0,10.558 -0.637,0 -17.115,0 -36.205,0 c -2.9,-0.003 -5.385,-1.038 -7.454,-3.104 -2.07,-2.066 -3.104,-4.551 -3.104,-7.454 l 0,-95.026 21.116,21.117 73.909,0 c 2.907,-0.003 5.394,1.03 7.46,3.099 2.065,2.07 3.098,4.556 3.098,7.46 l 0,20.536 0,20.855 L 0,3.329 0,0 Z"
              id="path4200"
            />
          </g>
          <path
            d="m 71.811,41.236 10.558,0 0,42.233 -10.558,0 0,-42.233 z"
            id="path4202"
          />
          <g id="g4204" transform="translate(41.7201,58.1291)">
            <path
              d="m 0,0 3.167,8.447 0.528,0 L 6.863,0 0,0 Z m -1.056,25.34 -16.893,-42.233 11.086,0 3.168,7.918 15.309,0 3.168,-7.918 11.614,0 -16.894,42.233 -10.558,0 z"
              id="path4206"
            />
          </g>
          <g id="g4208" transform="translate(115.1266,97.1649)">
            <path
              d="m 0,0 -3.791,1.689 c -5.385,2.424 -9.637,6.821 -11.878,12.285 l -1.335,3.22 c -0.272,0.692 -0.819,1.239 -1.511,1.511 -1.373,0.539 -2.923,-0.138 -3.462,-1.511 l -1.336,-3.225 C -25.556,8.505 -29.809,4.111 -35.197,1.689 l -3.785,-1.684 c -0.606,-0.279 -1.093,-0.766 -1.372,-1.372 -0.646,-1.404 -0.032,-3.066 1.372,-3.712 l 4.007,-1.789 c 5.255,-2.354 9.437,-6.593 11.72,-11.879 l 1.299,-2.987 c 0.271,-0.65 0.787,-1.167 1.437,-1.438 1.361,-0.568 2.925,0.076 3.493,1.438 l 1.299,2.987 c 2.281,5.287 6.461,9.527 11.715,11.884 L 0,-5.079 C 0.602,-4.8 1.086,-4.316 1.364,-3.715 2.013,-2.312 1.402,-0.649 0,0"
              id="path4210"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
