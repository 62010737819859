import { FC, memo, useMemo } from 'react'
import { ChatMessageReference } from '@crew/models/domain'
import BaseOutlineVideocam from '~icons/ic/outline-videocam'
import { CrewLink } from 'components/elements/crewLink/crewLink'
import { getDefaultTabValue } from '@crew/utils/enum'
import { EntityType } from '@crew/enums/domain'
import { EventDetailTabs } from 'enums/app'
import { useGetEventQuery } from '@crew/apis/project/projectApis'
import { GetEventRequest } from '@crew/apis/project/models/getEvent/request'
import { skipToken } from '@reduxjs/toolkit/query'
import { useTranslation } from '@crew/modules/i18n'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'
import { CrewHtmlContent } from 'components/elements/crewHtmlContent/crewHtmlContent'
import { HTMLEDITOR_VIEW_STYLE } from 'configs/constants'
import classNames from 'classnames'
import { sanitizeHtml } from 'utils/html'

export type RagResponseReferenceEventProps = {
  reference: ChatMessageReference // RAG応答表示に使用するチャットメッセージ参照
}

/**
 * RAG応答参照
 * イベント
 */
export const RagResponseReferenceEvent: FC<RagResponseReferenceEventProps> =
  memo((props) => {
    const { t } = useTranslation()

    // ユーザー設定からデフォルトのユーザープロファイル地域を取得
    const defaultUserProfileRegion = useUserSetting(
      SettingKeyType.UserProfileRegion,
      Region.Japan.value
    )

    // イベントを取得
    const getEventParam: GetEventRequest = {
      eventId: props.reference.entityRecordId,
    }
    const { data: getEventResult } = useGetEventQuery(
      getEventParam ?? skipToken
    )

    const event = getEventResult?.event

    // リンク下に表示するコンテンツ
    const displayHtmlContent = useMemo(() => {
      // 取得できていない場合は何も表示しない
      if (!event) {
        return ''
      }

      // 表示するコンテンツ
      let displayContent = ''
      // 議事録があれば表示する内容に追加
      if (event.meetingMinutes !== null) {
        displayContent += event.meetingMinutes
      }
      // 説明があれば表示する内容に追加
      if (event.description !== null) {
        displayContent += event.description
      }

      // サニタイズして表示
      return sanitizeHtml(displayContent)
    }, [event])

    // ロードされるまでは何も表示しない
    if (!event) {
      return null
    }

    return (
      <div className="flex flex-row gap-x-1">
        {/* アイコン */}
        <div className="flex-shrink-0">
          <BaseOutlineVideocam
            className="crew-text-gray-4"
            width={24}
            height={24}
          />
        </div>
        <div className="flex flex-col gap-y-1">
          <div className="flex flex-row gap-x-1 items-center">
            {/* 対象エンティティへのリンク */}
            <CrewLink
              className="text-md"
              to={`/${EntityType.Event}/${
                props.reference.entityRecordId
              }/${getDefaultTabValue(EventDetailTabs)}`}
            >
              {event.subject}
            </CrewLink>

            {/* イベント更新日時 */}
            <span className="text-sm crew-text-gray-5">
              {t('format.timestamp', {
                value: event.updatedAt,
                timeZone: defaultUserProfileRegion,
              })}
            </span>
          </div>
          <div className={classNames(HTMLEDITOR_VIEW_STYLE, 'line-clamp-3')}>
            <CrewHtmlContent html={displayHtmlContent} />
          </div>
        </div>
      </div>
    )
  })
