import { memo, FC } from 'react'
import { useAppSelector } from 'states/hooks'
import { MinutesDisplayMode } from 'enums/app'
import { EventDetailMeetingMinutesPanelMeetingMinutes } from './components/eventDetailMeetingMinutesPanelMeetingMinutes/eventDetailMeetingMinutesPanelMeetingMinutes'
import { EventDetailMeetingMinutesPanelTranscriptionList } from './components/eventDetailMeetingMinutesPanelTranscriptionList/eventDetailMeetingMinutesPanelTranscriptionList'

export type FormValues = {
  meetingMinutes: string | null
}

export const EventDetailMeetingMinutesPanel: FC = memo(() => {
  // 議事録の表示形式
  const selectedDisplayType = useAppSelector(
    (state) => state.eventDetailPage.selectedMinutesDisplayMode
  )

  return (
    <div className="h-full flex flex-col gap-3 py-2.5">
      {/* 議事録 */}
      {selectedDisplayType === MinutesDisplayMode.Minutes.value && (
        <EventDetailMeetingMinutesPanelMeetingMinutes />
      )}

      {/* 文字起こし一覧 */}
      {selectedDisplayType === MinutesDisplayMode.Transcription.value && (
        <EventDetailMeetingMinutesPanelTranscriptionList />
      )}
    </div>
  )
})
