import { memo, useCallback, useMemo } from 'react'
import {
  CrewAvatarSize,
  CrewAvatar,
} from 'components/elements/crewAvatar/crewAvatar'
import { generateImageAvatarUrl } from '@crew/utils/avatar'
import { EntityType } from '@crew/enums/domain'
import { useAppSelector } from 'states/hooks'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { GetUsersRequest } from '@crew/apis/lookup/models/getUsers/request'
import { skipToken } from '@reduxjs/toolkit/query'
import { useGetLookupUsersQuery } from '@crew/apis/lookup/lookupApis'
import classNames from 'classnames'

export type DirectChannelUserListPanelProps = {
  onUserSelected: (userId: string) => void
  alreadyRegisteredUserIds: string[] // 登録済みユーザを省くためのdirectChannels登録済データ
  isLoading: boolean
}

export const DirectChannelUserListPanel = memo(
  (props: DirectChannelUserListPanelProps) => {
    const keyword = useAppSelector(
      (state) => state.directChannelEntryDialog.userSearchKeyword
    )

    const [showApiErrors] = useShowApiErrors()

    const loggedInUser = useAppSelector((state) => state.app.loggedInUser)

    // キーワードに該当するユーザ一覧取得
    // 三項演算子になっていて少し見づらいが、内部のパラメータがundefinedを受け付けないため三項演算子を使用している
    // undefined時はskipTokenでAPIを実行しない（キーワードテキストが空のとき）
    const getUsersParams: GetUsersRequest | undefined = keyword
      ? {
          keyword: keyword,
          userId: undefined,
        }
      : undefined

    const { data: getLookupUsersQueryResult, isError: isLookupUsersError } =
      useGetLookupUsersQuery(getUsersParams ?? skipToken)

    // APIエラーが発生したらエラートースト表示
    if (isLookupUsersError) {
      showApiErrors(showApiErrors)
    }

    // ログインユーザとダイレクトチャンネル登録ユーザ配列（キーワード検索結果から省くために用意）
    const loginAndRegisteredUsers = useMemo(() => {
      return [...props.alreadyRegisteredUserIds, loggedInUser?.id]
    }, [loggedInUser?.id, props.alreadyRegisteredUserIds])

    const searchedUsers = getLookupUsersQueryResult?.users ?? []

    // 検索したユーザからログイン＆チャンネル登録済ユーザを省いてリスト出力
    const users = Object.values(searchedUsers).filter(
      (user) => loginAndRegisteredUsers.indexOf(user.id) === -1
    )

    // ユーザ一覧からダイレクトメッセージを送りたいユーザを選択
    const handleUserClick = useCallback(
      (userId: string) => {
        props.onUserSelected(userId)
      },
      [props]
    )

    return (
      <div className="h-full flex flex-col py-2">
        {users.length > 0 &&
          Object.values(users).map((user) => (
            <div
              key={user.id} // Warning表示回避のkey指定
              className={classNames(
                'flex gap-2 p-2 border-b-crew-slate-1-light dark:border-b-crew-slate-1-dark border-b-2',
                props.isLoading && 'pointer-events-none opacity-[0.5]' // API実行中はクリックイベントを無効化
              )}
              onClick={() => handleUserClick(user.id)}
            >
              <CrewAvatar
                displayName={user.displayName}
                imageURL={generateImageAvatarUrl(EntityType.User, user.id)}
                size={CrewAvatarSize.xs}
                cacheValue={user.id + user.version}
              />
              {/* NOTE: Linkコンポーネントだと遷移先（to）の設定が必要となるためここは文字色のみ他のLinkに合わせる */}
              <span className="text-crew-blue-3-light dark:text-crew-blue-2-dark max-w-xl truncate whitespace-nowrap overflow-hidden cursor-pointer">
                {user.displayName}
              </span>
            </div>
          ))}
      </div>
    )
  }
)
