import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeError } from '@stripe/stripe-js'
import { FC, memo, useCallback, useEffect, useState } from 'react'

import { useTranslation } from '@crew/modules/i18n'

import { CrewButton } from 'components/elements/crewButton/crewButton'
import { useCrewNavigate } from 'hooks/useCrewNavigate'

import { StripeErrorFormat } from './components/stripeErrorFormat/stripeErrorFormat'
import { BillingCycle, ContractPlan } from '@crew/enums/app'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import {
  FormValues,
  useContractRegisterCreditCardForm,
} from './useContractRegisterCreditCardForm'
import { ValueChangedEvent } from 'devextreme/ui/text_box'
import { useGetContactQuery } from '@crew/apis/contract/contractApis'

export type ContractRegisterCreditCardFormProps = {
  billingCycle: BillingCycle | undefined
  plan: ContractPlan | undefined
  showContactInformation: boolean
  purchaseExternalUsersCount: number | undefined // フリープランやトライアル中の場合のみ指定される
}

export const ContractRegisterCreditCardForm: FC<ContractRegisterCreditCardFormProps> =
  memo((props) => {
    const { t } = useTranslation()
    const { historyBack } = useCrewNavigate()

    const stripe = useStripe()
    const elements = useElements()

    const [stripeError, setStripeError] = useState<StripeError>()

    const { control, clearErrors, validateRules, reset, handleSubmit } =
      useContractRegisterCreditCardForm(props.showContactInformation)

    const { data: getContactResult, isFetching } = useGetContactQuery()

    // フォーム初期化処理関数
    const initializeForm = useCallback(async () => {
      if (getContactResult) {
        reset({
          companyName: getContactResult.companyName,
          contactName: getContactResult.contactName ?? '',
          departmentName: getContactResult.departmentName ?? '',
          officialPosition: getContactResult.officialPosition ?? '',
          tel: getContactResult.tel ?? '',
        })
      }
    }, [getContactResult, reset])

    // フォーム初期化処理
    useEffect(() => {
      initializeForm()
    }, [initializeForm])

    const handleContactNameChange = useCallback(
      (e: ValueChangedEvent) => {
        const value = e.value
        // キーワード未入力時は何もしない
        if (value.trim().length === 0) {
          return
        }

        clearErrors('contactName')
      },
      [clearErrors]
    )

    const handleTelChange = useCallback(
      (e: ValueChangedEvent) => {
        const value = e.value
        // キーワード未入力時は何もしない
        if (value.trim().length === 0) {
          return
        }

        clearErrors('tel')
      },
      [clearErrors]
    )

    // 登録ボタン押下時
    const handleSubmitButtonClick = useCallback(() => {
      const onSubmit = async (data: FormValues) => {
        // stripeの初期化が完了していない場合は何もしない
        if (!stripe || !elements) {
          return
        }

        // 現在時刻をreturn_urlのパラメータに付与する。
        // retur_url側で、付与された時刻を基に経過時間を計算し、一定の値より大きい場合はエラーとする。
        const now = Date.now()

        // カード情報登録完了後、支払方法をカードに設定するページに遷移するためのURL
        let returnUrl = `${window.location.protocol}//${window.location.host}/contract/update-payment-to-card?t=${now}`
        if (props.billingCycle) {
          // 請求サイクルがpropsに指定されていた場合は、URLに付与する
          returnUrl += `&billingCycle=${props.billingCycle}`
        }
        if (props.plan) {
          // 契約プランがpropsに指定されていた場合は、URLに付与する
          returnUrl += `&plan=${props.plan}`
        }
        // 外部ユーザー購入数がpropsに指定されていた場合は、URLに付与する
        // このパラメータの数値をもとにStripeの購入処理を行う
        if (props.purchaseExternalUsersCount !== undefined) {
          returnUrl += `&purchaseExternalUsersCount=${props.purchaseExternalUsersCount}`
        }

        // 連絡先情報が表示されている場合は、それぞれの値をURLに付与する
        if (props.showContactInformation) {
          if (data.companyName) {
            // 会社名
            returnUrl += `&companyName=${encodeURI(data.companyName)}`
          }

          if (data.contactName) {
            // 氏名
            returnUrl += `&contactName=${encodeURI(data.contactName)}`
          }

          if (data.departmentName) {
            // 部署名
            returnUrl += `&departmentName=${encodeURI(data.departmentName)}`
          }

          if (data.officialPosition) {
            // 役職
            returnUrl += `&officialPosition=${encodeURI(data.officialPosition)}`
          }

          if (data.tel) {
            // Tel
            returnUrl += `&tel=${encodeURI(data.tel)}`
          }
        }

        // カード情報の登録
        const result = await stripe.confirmSetup({
          elements,
          confirmParams: {
            return_url: returnUrl,
          },
        })
        // Stripeが正常に処理された場合は画面遷移するため、これ以降は実行されない
        setStripeError(result.error)
      }
      handleSubmit(onSubmit)()
    }, [
      elements,
      handleSubmit,
      props.billingCycle,
      props.plan,
      props.purchaseExternalUsersCount,
      props.showContactInformation,
      stripe,
    ])

    // キャンセルボタン押下時
    const handleCancelButtonClick = useCallback(() => {
      historyBack()
    }, [historyBack])

    return (
      <form>
        {/* Stripeのフォーム */}
        <PaymentElement />

        <StripeErrorFormat stripeError={stripeError} className="my-2" />

        {/* サブスクリプションステータスがトライアル中またはフリープランを利用中の場合は連絡先情報を入力する */}
        {props.showContactInformation && (
          <div className="py-2.5 flex flex-col gap-2.5 w-full max-w-2xl">
            {/* 見出し（連絡先情報） */}
            <div className="py-2.5">
              <span className="text-md font-bold">
                {t('label.contactInformation')}
              </span>
            </div>

            {/* 会社名 */}
            <div className="flex flex-col gap-1">
              <CrewTextBoxField
                id="companyName"
                name="companyName"
                className="h-11"
                control={control}
                placeholder={t('label.companyNameSample')}
                label={t('label.companyName')}
                showLabel
                rules={validateRules.companyName}
                required
              />
              <span className="text-sm text-red-500">
                {t('label.companyNameNotice')}
              </span>
            </div>

            {/* 氏名 */}
            <div className="flex flex-col gap-1">
              <CrewTextBoxField
                id="contactName"
                name="contactName"
                className="h-11"
                control={control}
                placeholder={t('label.contactNameSample')}
                label={t('label.contactName')}
                showLabel
                rules={validateRules.contactName}
                required
                onValueChanged={handleContactNameChange}
              />
              <span className="text-sm text-red-500">
                {t('label.contactNameNotice')}
              </span>
            </div>

            {/* 部門名 */}
            <CrewTextBoxField
              id="departmentName"
              name="departmentName"
              className="h-11"
              control={control}
              placeholder={t('label.departmentNameSample')}
              label={t('label.departmentName')}
              showLabel
              rules={validateRules.departmentName}
            />

            {/* 役職 */}
            <CrewTextBoxField
              id="officialPosition"
              name="officialPosition"
              className="h-11"
              control={control}
              placeholder={t('label.officialPositionSample')}
              label={t('label.officialPosition')}
              showLabel
              rules={validateRules.officialPosition}
            />

            {/* 電話番号 */}
            <CrewTextBoxField
              id="tel"
              name="tel"
              className="h-11"
              control={control}
              placeholder={t('label.telSample')}
              label={t('label.tel')}
              showLabel
              rules={validateRules.tel}
              required
              onValueChanged={handleTelChange}
            />
          </div>
        )}

        <div className="flex gap-2.5">
          {/* 登録ボタン */}
          <CrewButton
            type="primary"
            text={t('action.register2')}
            disabled={!stripe || isFetching}
            onClick={handleSubmitButtonClick}
          />
          {/* キャンセルボタン */}
          <CrewButton
            type="normal"
            stylingMode="outlined"
            text={t('action.cancel')}
            onClick={handleCancelButtonClick}
          />
        </div>
      </form>
    )
  })
