import { FC, memo } from 'react'
import { ChatMessage } from '@crew/models/domain'
import {
  AvatarPosition,
  CrewChatMessageItemAvatar,
} from 'components/elements/crewChatMessageItem/components/crewChatMessageItemAvatar/crewChatMessageItemAvatar'
import { CrewChatMessageItemHeader } from 'components/elements/crewChatMessageItem/components/crewChatMessageItemHeader/crewChatMessageItemHeader'
import { CrewNormalChatMessage } from 'components/elements/crewChatMessageItem/components/crewNormalChatMessage/crewNormalChatMessage'
import { RagResponseReference } from './components/ragResponseReference/ragResponseReference'

export type CrewAiAssistantResponseMessageItemProps = {
  message: ChatMessage // メッセージデータ
}

/**
 * AIアシスタント応答メッセージ
 */
export const CrewAiAssistantResponseMessageItem: FC<CrewAiAssistantResponseMessageItemProps> =
  memo((props) => {
    return (
      <div className="flex flex-row gap-2 p-2">
        {/* アバター */}
        <CrewChatMessageItemAvatar
          isLargeAvatar={true}
          user={props.message.createdBy}
          position={AvatarPosition.Center} // 自動投稿メッセージのため中央に表示する
          omitUserAvatar={false}
        />

        <div className="flex flex-grow flex-col gap-1 min-w-0">
          {/* ユーザー名、投稿日、関連先リンク */}
          <CrewChatMessageItemHeader
            message={props.message}
            isShowChatRoomName={false} // RAG応答メッセージはスレッド表示にしか存在しないため、チャットルーム名は表示しない
          />

          {/* メッセージ本文 */}
          <CrewNormalChatMessage
            messageText={props.message.text}
            messagePlainText={props.message.plainText}
            isTopicSummary={false}
          />

          {/* 参照情報 */}
          <RagResponseReference message={props.message} />
        </div>
      </div>
    )
  })
