import { CrewSelectBoxField } from 'components/forms/crewSelectBoxField'
import { CrewTagBoxField } from 'components/forms/crewTagBoxField'
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewScrollView } from 'components/devextreme/crewScrollView'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { SEARCH_TIMEOUT_MSEC } from '@crew/configs/constants'
import { useProjectDetailMemberEntryForm } from './useProjectDetailMemberEntryForm'
import { useTranslation } from '@crew/modules/i18n'
import { useToast } from 'hooks/useToast'
import { useParams } from 'react-router-dom'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { FormValues } from './useProjectDetailMemberEntryForm'
import {
  useDeleteProjectMemberCostMutation,
  useGetProjectMemberQuery,
} from '@crew/apis/project/projectApis'
import { GetProjectMemberRequest } from '@crew/apis/project/models/getProjectMember/request'
import { skipToken } from '@reduxjs/toolkit/query'
import { ColumnDef, TableOptions, getCoreRowModel } from '@tanstack/react-table'
import { ProjectMemberCost } from '@crew/apis/project/models/getProjectMember/response'
import { CrewTable } from 'components/elements/crewTable/crewTable'
import { useProjectAdvancedSettings, useProjectPermissions } from '@crew/hooks'
import { EntityType } from '@crew/enums/domain'
import BaselineDelete from '~icons/ic/baseline-delete'
import BaselineCreate from '~icons/ic/baseline-create'
import { ProjectDetailMemberUnitPriceEntryDialog } from '../../../projectDetailMemberUnitPriceEntryDialog/projectDetailMemberUnitPriceEntryDialog'
import { useModal } from 'components/layouts/modal/useModal'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import {
  ObjectEventMessage,
  notifyProjectDetailSettingMemberUnitPriceEvent,
} from 'features/app/states/appSlice'
import { NotifyEventType } from 'enums/app'

type RenderProjectMemberCostActionProps = {
  onEditProjectMemberCost: (projectMemberCostId: string) => void
  onDeleteProjectMemberCost: (
    projectMemberCostId: string,
    projectMemberCostVersion: number
  ) => void
  projectMemberCostId: string
  projectMemberCostVersion: number
  hasPrjMemberEditPermission: boolean
}

const RenderProjectMemberCostAction: FC<RenderProjectMemberCostActionProps> = (
  props
) => {
  // Event handle when the Delete project cost is clicked
  const handleDeleteProjectMemberCostClick = useCallback(() => {
    props.onDeleteProjectMemberCost(
      props.projectMemberCostId,
      props.projectMemberCostVersion
    )
  }, [props])

  // Event handle when the Edit project cost is clicked
  const handleEditProjectMemberCostClick = useCallback(() => {
    props.onEditProjectMemberCost(props.projectMemberCostId)
  }, [props])
  return (
    <div className="flex gap-2 pr-2 justify-end items-center">
      {props.hasPrjMemberEditPermission && (
        <span
          className="cursor-pointer"
          onClick={handleEditProjectMemberCostClick}
        >
          <BaselineCreate width={24} height={24} />
        </span>
      )}

      {props.hasPrjMemberEditPermission && (
        <span
          className="cursor-pointer"
          onClick={handleDeleteProjectMemberCostClick}
        >
          <BaselineDelete width={24} height={24} />
        </span>
      )}
    </div>
  )
}

export type ProjectDetailMemberFormProps = {
  isEditMode: boolean
  userId: string | null
  onSubmit: () => void
  onCancel: () => void
}

export const ProjectDetailMemberEntryForm: FC<ProjectDetailMemberFormProps> =
  memo((props) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const toast = useToast()
    const { projectId } = useParams()
    const projectDetailSettingMemberUnitPriceEventMessage = useAppSelector(
      (state) => state.app.projectDetailSettingMemberUnitPriceEventMessage
    )

    const { doProjectManagement } = useProjectAdvancedSettings(projectId)

    const [
      isMemberUnitPriceEntryDialogOpen,
      openMemberUnitPriceEntryDialog,
      closeMemberUnitPriceEntryDialog,
    ] = useModal()

    const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
      useModal()

    const [
      deleteProjectMemberCostMutation,
      { isLoading: isLoadingDeleteProjectMemberCost },
    ] = useDeleteProjectMemberCostMutation()

    const [
      isProjectMemberUnitPriceEditDialogEditMode,
      setIsProjectMemberUnitPriceEditDialogEditMode,
    ] = useState(false)

    const [columnVisibility, setColumnVisibility] = useState({})
    const [columnPinning] = useState({
      right: ['action'],
    })

    const [selectedProjectMemberCostId, setSelectedProjectMemberCostId] =
      useState<string | null>(null)
    const [
      selectedProjectMemberCostVersion,
      setSelectedProjectMemberCostVersion,
    ] = useState(0)

    // get project member params
    const getProjectMemberParams: GetProjectMemberRequest | undefined =
      props.userId && projectId
        ? {
            projectId,
            userId: props.userId,
          }
        : undefined

    const { data: projectMemberResult, refetch: refetchProjectMemberResult } =
      useGetProjectMemberQuery(getProjectMemberParams ?? skipToken)

    // refetch project member result when projectDetailSettingMemberUnitPriceEventMessage is changed
    useEffect(() => {
      refetchProjectMemberResult()
    }, [
      projectDetailSettingMemberUnitPriceEventMessage,
      refetchProjectMemberResult,
    ])

    const {
      control,
      reset,
      clearErrors,
      setError,
      formState,
      handleSubmit,

      userDataSource,
      roleDataSource,

      validateRules,

      updateProjectMember,
      insertProjectMember,
      isLoadingInsertProjectMember,
      isLoadingUpdateProjectMember,
    } = useProjectDetailMemberEntryForm(
      projectMemberResult?.projectMember?.user.userType
    )

    const [displayName, setDisplayName] = useState('')

    const [showApiErrors] = useShowApiErrorsWithForm(setError)

    const canSend = useMemo(
      () =>
        Object.keys(formState.errors).length === 0 &&
        !formState.isSubmitting &&
        !isLoadingInsertProjectMember &&
        !isLoadingUpdateProjectMember,
      [formState, isLoadingInsertProjectMember, isLoadingUpdateProjectMember]
    )

    // Show data
    useEffect(() => {
      if (projectMemberResult?.projectMember && props.userId) {
        reset({
          roleId: projectMemberResult.projectMember.role.id,
          userId: props.userId,
        })

        setDisplayName(projectMemberResult.projectMember.user.displayName)
      }
    }, [projectMemberResult?.projectMember, props.userId, reset])

    // Event handle when the Submit button to Add/Update project member is clicked
    const handleSubmitButtonClick = useCallback(() => {
      // react-hook-formのhandleSubmitに渡すコールバック関数を定義する
      const onSubmit = async (data: FormValues) => {
        // If projectId are not existed then end of process
        if (!projectId) return

        try {
          if (props.isEditMode) {
            if (props.userId) {
              // Execute update project member process
              await updateProjectMember(
                projectId,
                data.roleId as string,
                props.userId
              )
              // Display a toast indicating that the project member update was successful
              toast.success(t('message.project.projectMemberUpdated'))
            }
          } else {
            if (data.userIds) {
              // Execute add project member process
              await insertProjectMember(
                projectId,
                data.userIds,
                data.roleId as string
              )
              // Display a toast indicating that the project member registration was successful
              toast.success(t('message.project.projectMemberRegistered'))
            }
          }

          reset()
          clearErrors()
          props.onSubmit && props.onSubmit()
        } catch (err) {
          showApiErrors(err)
        }
      }
      handleSubmit(onSubmit)()
    }, [
      handleSubmit,
      projectId,
      props,
      reset,
      clearErrors,
      updateProjectMember,
      toast,
      t,
      insertProjectMember,
      showApiErrors,
    ])

    // handle close project setting member entry dialog
    const handleCancelButtonClick = useCallback(() => {
      props.onCancel()
    }, [props])

    const { hasPrjMemberEditPermission, hasPrjMemberRoleEditPermission } =
      useProjectPermissions(EntityType.Project, projectId)

    // Event handle when the Add project cost is clicked
    const handleAddProjectCostClick = useCallback(() => {
      setIsProjectMemberUnitPriceEditDialogEditMode(false)

      // reset selected project member cost
      setSelectedProjectMemberCostId(null)

      // Open project member unit price entry dialog
      openMemberUnitPriceEntryDialog()
    }, [openMemberUnitPriceEntryDialog])

    // Event handle when the Delete project cost is clicked
    const handleDeleteProjectCostClick = useCallback(
      (projectMemberCostId: string, version: number) => {
        setSelectedProjectMemberCostId(projectMemberCostId)
        setSelectedProjectMemberCostVersion(version)

        openConfirmDialog()
      },
      [openConfirmDialog]
    )

    // Event handle when the Permit button in the delete confirmation dialog is clicked
    const handleDeletePermitButtonClick = useCallback(async () => {
      if (selectedProjectMemberCostId) {
        try {
          await deleteProjectMemberCostMutation({
            projectMemberCostId: selectedProjectMemberCostId,
            version: selectedProjectMemberCostVersion,
          }).unwrap()

          // send event to update project member list
          const objectEventMessage: ObjectEventMessage<ProjectMemberCost> = {
            eventType: NotifyEventType.Deleted,
            id: selectedProjectMemberCostId,
            object: undefined,
          }
          dispatch(
            notifyProjectDetailSettingMemberUnitPriceEvent(objectEventMessage)
          )

          toast.success(
            t('message.project.projectMemberUnitPriceDeletedSuccessfully')
          )
        } catch (err) {
          showApiErrors(err)
        }
      }
      closeConfirmDialog()
    }, [
      closeConfirmDialog,
      deleteProjectMemberCostMutation,
      dispatch,
      selectedProjectMemberCostId,
      selectedProjectMemberCostVersion,
      showApiErrors,
      t,
      toast,
    ])

    // Event handle when the Edit project cost is clicked
    const handleEditProjectCostClick = useCallback(
      (projectMemberCostId: string) => {
        setIsProjectMemberUnitPriceEditDialogEditMode(true)

        setSelectedProjectMemberCostId(projectMemberCostId)

        // Open project member unit price entry dialog
        openMemberUnitPriceEntryDialog()
      },
      [openMemberUnitPriceEntryDialog]
    )

    const columns = useMemo<ColumnDef<ProjectMemberCost>[]>(
      () => [
        {
          id: 'user',
          header: () => t('label.effectiveStartDate'),
          cell: ({ row }) => (
            <div>
              {t('format.date', {
                value: row.original.effectiveStartDate,
              })}
            </div>
          ),
          size: 120,
          minSize: 50,
        },
        {
          id: 'role',
          header: () => t('label.pricePerHour'),
          cell: ({ row }) => (
            <div className="truncate">
              {t('format.currency', {
                value: row.original.unitPrice,
              })}
              {t('label.yen')}
            </div>
          ),
          size: 300,
          minSize: 50,
        },
        {
          id: 'action',
          accessorKey: 'action',
          header: '',
          cell: ({ row }) => (
            <RenderProjectMemberCostAction
              hasPrjMemberEditPermission={hasPrjMemberEditPermission}
              onDeleteProjectMemberCost={handleDeleteProjectCostClick}
              onEditProjectMemberCost={handleEditProjectCostClick}
              projectMemberCostId={row.original.id}
              projectMemberCostVersion={row.original.version}
            />
          ),
          size: 80,
          minSize: 50,
          enableSorting: false,
        },
      ],
      [
        handleDeleteProjectCostClick,
        handleEditProjectCostClick,
        hasPrjMemberEditPermission,
        t,
      ]
    )

    const tableOptions: TableOptions<ProjectMemberCost> = {
      data: projectMemberResult?.projectMember?.projectMemberCosts ?? [],
      columns,
      columnResizeMode: 'onChange',
      getCoreRowModel: getCoreRowModel(),
      state: {
        columnVisibility,
        columnPinning,
      },
      onColumnVisibilityChange: setColumnVisibility,
      meta: {
        headerRowHeight: 40,
        dataRowHeight: 50,
      },
    }

    return (
      <>
        <form className="flex flex-col gap-y-5 h-full">
          <CrewScrollView>
            <div className="flex flex-col gap-y-2.5">
              {props.isEditMode ? (
                <>
                  <CrewTextBoxField
                    id="userId"
                    name="userId"
                    control={control}
                    labelMode="hidden"
                    label={t('label.user')}
                    visible={false}
                  />
                  <p>{displayName}</p>
                </>
              ) : (
                <CrewTagBoxField
                  id="userIds"
                  control={control}
                  name="userIds"
                  dataSource={userDataSource}
                  displayExpr="displayName"
                  valueExpr="id"
                  searchEnabled={true}
                  searchMode="contains"
                  searchExpr="displayName"
                  searchTimeout={SEARCH_TIMEOUT_MSEC}
                  minSearchLength={0}
                  labelMode="hidden"
                  label={t('label.user')}
                  required={true}
                  rules={validateRules.userIds}
                />
              )}

              <CrewSelectBoxField
                id="roleId"
                control={control}
                name="roleId"
                dataSource={roleDataSource}
                valueExpr="id"
                displayExpr="name"
                searchEnabled={false}
                labelMode="hidden"
                label={t('label.role')}
                minSearchLength={0}
                required={true}
                rules={validateRules.roleId}
                showClearButton={false}
                width={200}
                disabled={!hasPrjMemberRoleEditPermission}
              />

              {doProjectManagement && (
                <div className="flex justify-between items-center">
                  <p className="crew-text-gray-4">{t('label.projectCost')}</p>
                  <CrewButton
                    type="primary"
                    text={t('label.add')}
                    onClick={handleAddProjectCostClick}
                  />
                </div>
              )}

              {props.isEditMode && doProjectManagement && (
                <CrewTable
                  tableOptions={tableOptions}
                  showPager={false}
                  showColumnSelector={false}
                />
              )}
            </div>
          </CrewScrollView>
          <div className="flex justify-end gap-x-2.5">
            <CrewButton
              text={t('action.register')}
              type="primary"
              onClick={handleSubmitButtonClick}
              disabled={!canSend}
            />

            <CrewButton
              text={t('action.cancel')}
              type="normal"
              stylingMode="outlined"
              onClick={handleCancelButtonClick}
            />
          </div>
        </form>

        {/* プロジェクト原価の追加/編集 */}
        <ProjectDetailMemberUnitPriceEntryDialog
          title={t('label.addEditProjectCost')}
          isEditMode={isProjectMemberUnitPriceEditDialogEditMode}
          isOpen={isMemberUnitPriceEntryDialogOpen}
          onClose={closeMemberUnitPriceEntryDialog}
          userId={props.userId}
          projectMemberCostId={selectedProjectMemberCostId}
        />

        {/* 削除確認ダイアログ */}
        <CrewConfirmDialog
          isOpen={isConfirmDialogOpen}
          message={t('message.general.confirmMessage.delete')}
          onPermitButtonClick={handleDeletePermitButtonClick}
          onCancelButtonClick={closeConfirmDialog}
          permitButtonDisabled={isLoadingDeleteProjectMemberCost}
        />
      </>
    )
  })
