import { memo, FC } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { CrewDivider } from 'components/elements/crewDivider/crewDivider'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { AiAssistantPanel } from 'features/aiAssistant/components/aiAssistantPanel/aiAssistantPanel'
import { CrewAiAssistantIcon } from 'components/elements/crewAiAssistantIcon/crewAiAssistantIcon'

/**
 * AIアシスタント画面
 */
export const AiAssistantPage: FC = memo(() => {
  const { t } = useTranslation()

  return (
    <div className="min-h-0 flex-1 flex flex-col grow w-full">
      {/* ページタイトル */}
      <div className="flex p-2 gap-2 items-center crew-text-gray-4">
        <CrewAiAssistantIcon width={24} height={24} />
        <p className="font-bold">{t('label.aiAssistant')}</p>
      </div>

      {/* 区切り線 */}
      <CrewDivider />

      {/* コンテンツ表示部 */}
      <CrewErrorBoundary>
        <AiAssistantPanel />
      </CrewErrorBoundary>
    </div>
  )
})
